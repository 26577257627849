/* LOGISTICS COST */
.logisticCosts {
	padding: 						135px 0 230px 0;
	position: 						absolute;
	top: 							0;
	right: 							0;
	background: 					var(--grey);
	width: 							100vw;
	opacity: 						0;
	visibility: 					hidden;
	height: 						100%;
	transition: 					all 0.3s ease-in-out;
	overflow-y: 					hidden;
}

.logisticCosts.show {
	opacity: 						1;
	visibility: 					visible;
	height: 						auto;
	min-height: 					100%;
}

.logisticCosts .titleAndSave{
	display: 						flex;
	align-items: 					center;
	margin-bottom: 					10px;
}

.logisticCosts .titleAndSave > img{
	width: 							20px;
	height: 						20px;
	object-fit: 					contain;
	object-position: 				center center;
	transform: 						rotate(90deg);
	cursor: 						pointer;
}

.logisticCosts .titleAndSave > h4{
	margin-left: 					10px;
	font-weight: 					700;
}

.logisticCosts .titleAndSave > button{
	border: 						unset;
	display: 						flex;
	align-items: 					center;
	gap: 							5px;
	padding: 						5px 10px;
	background: 					var(--gradient), var(--blue);
	background-clip: 				text, padding-box;
	-webkit-background-clip: 		text, padding-box;
	-webkit-text-fill-color: 		transparent;
	margin-left: 					auto;
	transition: 					all 0.3s ease-in-out;
}

.logisticCosts .titleAndSave > button {
	margin-left: 					20px;
}

.logisticCosts .titleAndSave > button:first-of-type {
	margin-left: 					auto;
}

.logisticCosts .titleAndSave > button:last-of-type:before{
	content: 						"";
	width: 							15px;
	height: 						15px;
	mask: 							url("../../images/download.svg") center center no-repeat;
	background: 					var(--gradient);
	rotate: 						180deg;
}

.logisticCosts .titleAndSave > #download::before {
	content: 						"";
	width: 							15px;
	height: 						15px;
	background: 					url("../../images/folder.svg") center center no-repeat;
	background-size: 				15px 15px;
}

.logisticCosts .titleAndSave > button::before{
	content: 						"";
	width: 							15px;
	height: 						15px;
	background: 					url("../../images/save.svg") center center no-repeat;
	background-size: 				15px 15px;
}

.logisticCosts .titleAndSave > button:hover{
	background-color: 				var(--darkBlue);
}

.logisticCosts .variableSubTitle {
	font-weight: 					600;
	background: 					var(--gradient);
	background-clip: 				text;
	-webkit-background-clip: 		text;
	-webkit-text-fill-color: 		transparent;
	margin: 						0 0 30px 30px;
}

.logisticCosts .headerTitle {
	display: 						flex;
	align-items: 					center;
	justify-content: 				space-between;
	background:						#FFFFFF;
	box-shadow: 					0px 4px 30px 0px rgba(35, 107, 216, 0.05);
	padding: 						25px 60px;
}

.logisticCosts .headerTitle > h5{
	font-weight: 					700;
}

.logisticCosts .headerTitle span{
	font-size: 						14px;
	opacity: 						0.5;
}

.logisticCosts .headerTitle .currencyButtonContainer {
	display: 							flex;
	gap: 								5px;
}

.logisticCosts .headerTitle .currencyButtonContainer > button {
	font-size: 							14px;
	padding-inline: 					10px;
	border: 							none;
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
}

.logisticCosts .headerTitle .currencyButtonContainer > button.selected {
	border: 							none;
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}


.logisticCosts .rowInformation {
	margin: 					 	0 0 100px;
}

.logisticCosts .rowInformation:last-of-type {
	margin: 						0;
}

.logisticCosts .rowInformation .informationHeader {
	display: 						flex;
	align-items: 					center;
	background: 					rgba(7, 27, 57, 0.03);
	padding: 						25px 5px 25px 0;
}

.logisticCosts .rowInformation .informationHeader .port{
	flex: 							0 0 55%;
	font-weight: 					700;
	padding-left: 					60px;
}

.logisticCosts .rowInformation .informationHeader .origin{
	flex: 							1 0 0;
	font-weight: 					700;
}

.logisticCosts .rowInformation .informationValue {
	display: 						flex;
	align-items: 					center;
	gap: 							10px;
	padding: 						25px 5px 25px 0;
	border-bottom: 					1px solid rgba(0, 0, 0, 0.05);
}

.logisticCosts .rowInformation .informationValue .firstElement{
	flex: 							0 0 50%;
	border-radius: 					10px;
	margin: 						0;
	position: 						relative;
	font-size: 						16px;
}

.logisticCosts .rowInformation .informationValue .firstElement > input{
	border-radius: 					10px;
}

.logisticCosts .rowInformation .informationValue .secondElement{
	flex: 							1 0 0;
	border-radius: 					10px;
	text-overflow: 					ellipsis;
	white-space: 					nowrap;
	font-weight: 					500;
	font-size:    					16px;
}



.logisticCosts .rowInformation .informationValue .disabled{
	opacity: 						0.5;
	cursor: 						not-allowed;
}

.logisticCosts .rowInformation .informationValue .placesFoundContainer{
	background: 					rgba(255, 255, 255, 0.90);
    box-shadow: 					0 15px 50px 0 rgba(4, 26, 57, 0.15);
    position: 						absolute;
	top: 							105%;
    width: 							100%;
    z-index: 						9;
    transition: 					all 0.3s ease-in-out;
	visibility: 					hidden;
	opacity: 						0;
}

.logisticCosts .rowInformation .informationValue .placesFoundContainer.show{
	visibility: 					visible;
	opacity: 						1;
}

.logisticCosts .rowInformation .informationValue .placesFoundContainer .placeFound{
	padding: 						10px 35px;
	cursor: 						pointer;
	transition: 					all 0.3s ease-in-out;
}

.logisticCosts .rowInformation .informationValue .placesFoundContainer .placeFound:hover{
	background: 					var(--gradient), rgba(4, 26, 57, 0.10);
    background-clip:				text, padding-box;
    -webkit-background-clip: 		text, padding-box;
    -webkit-text-fill-color: 		transparent;
}

.logisticCosts .rowInformation .citiesScroll {
	padding: 						0;
	overflow-x: 					auto;
	background-image: 				linear-gradient(to right, var(--grey), var(--grey)),
									linear-gradient(to right, var(--grey), var(--grey)),
									linear-gradient(to right, rgba(208, 210, 212, 0.5),rgba(245, 246, 247, 0.5)),
									linear-gradient(to left, rgba(208, 210, 212, 0.5), rgba(245, 246, 247, 0.5));
    background-position: 			left center, right center, left center, right center;
    background-repeat: 				no-repeat;
    background-size: 				40px 100%, 40px 100%, 40px 100%, 40px 100%;
    background-attachment: 			local, local, scroll, scroll;
}

.logisticCosts .rowInformation .informationHeaderCities {
	display: 						flex;
	align-items: 					center;
}

.logisticCosts .rowInformation .informationHeaderCities .cityName{
	flex: 							0 0 calc(100% / 2.5);
	font-weight: 					700;
	background: 					rgba(7, 27, 57, 0.03);
	padding: 						25px 5px;
	text-align: 					right;
}

.logisticCosts .rowInformation .informationValueCities {
	display: 						flex;
	align-items: 					center;
	padding: 						25px 0;
	border-bottom: 					1px solid rgba(0, 0, 0, 0.05);
}

.logisticCosts .rowInformation .informationValueCities .inputContainer{
	flex: 							0 0 calc(100% / 2.5);
	position: 						relative;
	margin: 						0;
	padding: 						0 5px;
}

.logisticCosts .rowInformation .informationValueCities .inputContainer::before{
	content: 						"$";
	font-size: 						14px;
	height: 						fit-content;
	position: 						absolute;
	top: 							0;
	bottom: 						0;
	margin: 						auto 0;
	left: 							15px;
	opacity: 						0.6;
	z-index: 						9;
}

.logisticCosts .rowInformation .informationValueCities .inputContainer > input{
	border-radius: 					10px;
	padding: 						5px 25px;
}

.logisticCosts .no-gutters > [class*='col-'] {
	padding-right:                  0;
	padding-left:                   0;
}

.logisticCosts .mobile-actions {
    display:                        flex;
    flex-direction:                 column;
    gap:                            20px;
    width:                          100%;
}

.logisticCosts .mobile-actions > button {
    border:                         unset;
    display:                        flex;
    align-items:                    center;
    justify-content:                center;
    gap:                            10px;
    padding:                        10px 20px;
    background: 					var(--gradient), var(--blue);
    background-clip: 				text, padding-box;
	-webkit-background-clip: 		text, padding-box;
	-webkit-text-fill-color: 		transparent;
    transition: 					all 0.3s ease-in-out;
}

.logisticCosts .mobile-actions > #download::before {
	content: 						"";
	width: 							15px;
	height: 						15px;
	background: 					url("../../images/folder.svg") center center no-repeat;
	background-size: 				15px 15px;
}

.logisticCosts .mobile-actions > button:last-of-type:before{
	content: 						"";
	width: 							15px;
	height: 						15px;
	mask: 							url("../../images/download.svg") center center no-repeat;
	background: 					var(--gradient);
	rotate: 						180deg;
}

@media screen and (width <= 991px){
    .logisticCosts .titleAndSave,
    .logisticCosts .variableSubTitle {
        padding: 					0 20px;
    }

    .logisticCosts .titleAndSave > button:first-of-type {
        padding:                    15px;
    }

    .logisticCosts .titleAndSave > button::before {
        width:                      20px;
        height:                     20px;
        background-size:            25px 25px;
    }
}