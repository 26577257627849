.mustsSection .filtersColContainer {
    display:                            flex;
}

.mustsSection .filtersContainer {
    display:                            flex;
    gap:                                10px;
    overflow:                           auto;
    margin-bottom:                      27px;
}

.mustsSection .resetAllFilters {
    margin:                             auto 0 auto 10px;
    cursor:                             pointer;
    visibility:                         hidden;
    opacity:                            0;
    color:                             #009AFF;
}

.mustsSection .resetAllFilters:hover {
    transition: color ease-in-out 0.3s;
    color:                             var(--darkBlue);
}

.mustsSection .resetAllFilters.show {
    visibility:                         visible;
    opacity:                            1;
}

.mustsSection .dropdown-toggle {
    display:                            flex;
    gap:                                8px;
    color:                              #000000;
    font-weight:                        400;
    padding:                            10px 10px 10px 20px;
    border-bottom:                      unset;
    align-items:                        center;
}

.mustsSection .filtersContainer .dropdown, .mustsSection .filtersContainer .dropdown-toggle::after {
    position:                           unset;
}

.mustsSection .dropdown-toggle.filterActive {
    background-color:                   var(--darkBlue);
    color:                              #FFFFFF;
    border-bottom:                      unset;
}

.mustsSection .dropdown-toggle.show:not(.filterActive) {
    background:                         var(--blue);
    border-bottom:                      unset;
}

.mustsSection .dropdown-toggle:active {
    color:                              unset !important;
    background-color:                   unset !important;
}

.mustsSection .dropdown-toggle:active, .mustsSection .dropdown-toggle:focus {
    border-bottom:                       unset !important;
}

.mustsSection .dropdown-toggle:hover {
    border-bottom:                      unset;
}

.mustsSection .dropdown-menu {
    width:                              max-content;
    padding:                            20px;
    background-color:                   rgba(255, 255, 255, 0.95);
    overflow:                           unset;
    filter:                             drop-shadow(0px 15px 50px rgba(7, 27, 57, 0.15));
    box-shadow:                         unset;
    inset:                              11px auto auto 0 !important;
}

.mustsSection .dropdown-menu.borderCitiesDropdow {
    padding:                            20px 0px;
    min-width:                          300px;
}

.mustsSection .dropdown-menu:before {
    content: 							"";
    width: 								0;
    height: 							0;
    border-left: 						8px solid transparent;
    border-right: 						8px solid transparent;
    border-bottom: 						8px solid #FFFFFF;
    position: 							absolute;
    left: 								60px;
    bottom: 							100%;
    margin: 							0 auto;
}

.mustsSection .dropdown-menu .borderFilterName {
    padding:                            10px 20px;
}

.mustsSection .filtersContainer .filterContentContainer > span {
    font-weight:                        700;
}

.mustsSection .filtersContainer .filterContentContainer .filterHeader {
    display:                            flex;
    gap:                                10px;
}

.mustsSection .filtersContainer .filterContentContainer .filterHeader .miles-selector{
    background:                         unset;
    border:                             unset;
    color :                           #009AFF;
    display:                            flex;
    gap:                                5px;
    align-items:                        center;
}

.mustsSection .filtersContainer .filterContentContainer .filterHeader .miles-selector::after{
    content:                            "";
    width:                              15px;
    height:                             15px;
    mask:                               url("../../images/expand_less.svg") center center no-repeat;
    background:                         var(--gradient);
    background-size:                    15px 15px;
    transform:                          rotate(180deg);
    transition:                         transform 0.1s ease-in-out;
    margin-right:                       5px;
}

.mustsSection .filtersContainer .filterContentContainer .filterHeader .miles-selector.active::after{
    transform:                          rotate(0deg);
}

.mustsSection .filtersContainer .filterContentContainer .filterHeader .miles-selector:hover::after,
.mustsSection .filtersContainer .filterContentContainer .filterHeader .miles-selector.active::after{
    background:                     var(--darkBlue);
}


.mustsSection .filtersContainer .filterContentContainer .filterHeader .miles-selector.active,
.mustsSection .filtersContainer .filterContentContainer .filterHeader .miles-selector:hover{
    color:                        var(--darkBlue);
}


.mustsSection .filtersContainer .filterContentContainer .filterHeader .distance-options{
    display:                    flex;
    position:                   absolute;
    top:                        45px;
    left:                       39%;
    flex-direction:             column;
    z-index:                    99;
    box-shadow:                 0px 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow */
    border:                     1px solid #ccc; /* Added border */
    visibility:                 hidden;
    opacity:                    0;
}

.mustsSection .filtersContainer .filterContentContainer .filterHeader .distance-options.show{
    visibility:                         visible;
    opacity:                            1;
}

.mustsSection .filtersContainer .filterContentContainer .filterHeader .distance-options > button{
    border:                             unset;
    background-color:                   white;
    padding:                            10px;
}

.mustsSection .filtersContainer .filterContentContainer .filterHeader .distance-options .selected{
    border:                              unset;
    color:                              #009AFF;
    background-color:                   var(--grey);
    padding:                            10px;
    cursor:                              auto;
}


.mustsSection .filtersContainer .filterContentContainer .customRangeContainer{
    display:                            flex;
    padding:                            0px 10px 20px 10px;
    gap:                                10px;
}

.mustsSection .filtersContainer .filterContentContainer .error-message{
    color:                              red;
    font-size:                            12px;
    margin-left:                          10px;
    visibility:                           hidden;
    opacity:                              0;
    transition:                           opacity 0.3s ease-in-out;
}

.mustsSection .filtersContainer .filterContentContainer .error-message.error{
    opacity:                             1;
    visibility:                          visible;
    transition:                          opacity 0.3s ease-in-out;

}

.mustsSection .filtersContainer .filterContentContainer .customRangeContainer > input {
    display:                            flex;
    background:                         var(--grey);
}

.mustsSection .filtersContainer .filterContentContainer .customRangeContainer.error > input {
    display:                            flex;
	border-bottom: 						1px solid #e33e28;
    color:                              red;
}

.mustsSection .filtersContainer .filterContentContainer > .rangeInputContainer {
    width:                              400px;
    padding:                            40px 20px 20px 20px;
}

.mustsSection .filtersContainer .filterContentContainer .borderCitiesContainer {
    min-height:                         200px;
    max-height:                         300px;
    overflow:                           auto;
    margin-bottom:                      20px;
}

.mustsSection .filtersContainer .filterContentContainer .borderCitiesContainer > .borderCity {
    position:                           relative;
    display:                            flex;
    flex-flow:                          row nowrap;
    padding:                            20px 35px 20px 20px;
    border-bottom:                      1px solid rgba(0, 0, 0, 0.05);
    cursor:                             pointer;
    transition:                         all 0.3s ease-in-out;
    gap:                                10px;
    align-items:                        center;
}

.mustsSection .filtersContainer .filterContentContainer .borderCitiesContainer > .borderCity::before {
    content:                            "";
    display:                            block;
    width:                              1px;
    height:                             0;
    background:                         var(--gradient) center center no-repeat;
    position:                           absolute;
    top:                                0;
    left:                               0;
    transition:                         all 0.3s ease-in-out;
}

.mustsSection .filtersContainer .filterContentContainer .borderCitiesContainer > .borderCity:hover {
    background-color:                   rgba(4, 26, 57, 0.03);
}

.mustsSection .filtersContainer .filterContentContainer .borderCitiesContainer > .borderCity:hover::before {
    height:                             100%;
}

.mustsSection .filtersContainer .filterContentContainer .borderCitiesContainer .borderCity > label {
    cursor:                             pointer;
}

.mustsSection .filterActionsContainer {
    display:                            flex;
    gap:                                10px;
}

.mustsSection .filterActionsContainer .search .dropdown-toggle {
    background:                         var(--blue);
    padding:                            10px 8px;
}

.mustsSection .filterActionsContainer .filter .dropdown-toggle {
    display:                            flex;
    gap:                                5px;
    padding:                            11px 12px;
    background-color:                   var(--darkBlue);
    color:                              #FFFFFF;
}

.mustsSection .filterActionsContainer .filter .dropdown-menu {
    inset:                              0 auto auto -75px !important
}

.mustsSection .filterActionsContainer .filter .dropdown-menu:before {
    left:                               0;
    right:                               0;
}

.mustsSection .filterActionsContainer .dropdown-toggle:after {
    all:                                unset;
}

.mustsSection .tableCities {
    width: 						        100%;
    margin-bottom:                      50px;
}

.mustsSection .tableCities thead {
    background: 						#FFFFFF;
    box-shadow: 						0 5px 30px 0 rgba(35, 107, 216, 0.08);
}

.mustsSection .tableCities .sort {
    display: 							inline;
    width: 								25px;
    height: 							25px;
    object-fit: 						contain;
    object-position: 					center center;
    cursor: 							pointer;
    margin-left: 						5px;
    transition: 						all 0.3s ease-in-out;
}

.mustsSection .tableCities .sort.DSC {
    transform: 							rotate(180deg);
}

.mustsSection  .tableCities tbody tr {
    border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
}

.mustsSection  .tableCities thead th:first-of-type {
    width:                              350px;
}

.mustsSection  .tableCities thead th,
.mustsSection  .tableCities tbody td {
    position: 							relative;
    padding: 							20px;
}

.mustsSection .MuiSlider-rail {
    background:                         rgba(0, 0, 0, 0.15) !important;
}

.mustsSection .MuiSlider-mark {
    width:                              6px !important;
    height:                             6px !important;
    border-radius:                      10px !important;
    background-color:                   #000000 !important;
    opacity:                            0.5;
}

.mustsSection .MuiSlider-track {
    border:                             unset !important;
    background:                         var(--gradient, linear-gradient(180deg, #009AFF 19.79%, #8CA5FF 100%));
}

.mustsSection .MuiSlider-thumb {
    width:                              unset !important;
    height:                             unset !important;
    top:                                0 !important;
    margin-left:                        2px;
}

.mustsSection .MuiSlider-thumb:after {
    width:                              14px !important;
    height:                             14px !important;
    color:                              unset !important;
    background:                         var(--gradient, linear-gradient(180deg, #009AFF 19.79%, #8CA5FF 100%));
}

.mustsSection .MuiSlider-thumb.Mui-active, .mustsSection .MuiSlider-thumb:hover {
    box-shadow:                         unset !important;
}

.mustsSection .MuiSlider-valueLabel {
    background-color:                   unset !important;
}

.mustsSection .MuiSlider-valueLabelOpen {
    color:                              #000000 !important;
    font-family:                        inherit !important;
    font-size:                          16px !important;
    font-weight:                        700 !important;
    line-height:                        normal !important;
    transform:                          translateY(-36px) scale(1);
}

.mustsSection .MuiSlider-markLabel {
    color:                              #000000 !important;
    text-align:                         center;
    font-family:                        inherit !important;
    font-size:                          14px !important;
    font-style:                         normal;
    font-weight:                        400 !important;
    line-height:                        normal !important;
    opacity:                            0.4;
}

.mustsSection .filterApplyContainer {
    display:                            flex;
    gap:                                10px;
    justify-content:                    right;
}

.mustsSection .filterApplyContainer .cancelButton, .mustsSection .filterApplyContainer .secondaryButton {
    padding:                            5px 15px;
}

.mustsSection .filterApplyContainer .cancelButton:first-of-type{
    margin-right:                       auto;
}

.mustsSection .filterApplyContainer .cancelButton.active{
    background-color:                   var(--darkBlue);
    color:                             #FFFFFF;
    transition:                         all 0.3s ease-in-out;
}

.mustsSection .filterCityContentContainer {
    display:                            flex;
    gap:                                10px;
    align-items:                        center;
}

.mustsSection .filterActionsContainer .search .dropdown-menu {
    padding:                            0;
    inset:                              0 auto auto -126px !important
}

.mustsSection .filterActionsContainer .search .dropdown-menu:before {
    left:                               unset;
    right:                              135px;
}

.mustsSection .filterActionsContainer .search .dropdown-menu .searchCitiesContainer {
    width:                              390px;
    max-height: 						340px;
    overflow-y: 						auto;
    padding:                            0 30px;
}

.mustsSection .filterActionsContainer .search .dropdown-menu .searchCitiesContainer::-webkit-scrollbar {
    width:                              3px;
}

.mustsSection .filterActionsContainer .search .dropdown-menu .searchCitiesContainer::-webkit-scrollbar-track {
    background:                         rgba(0, 0, 0, 0.05);
}

.mustsSection .filterActionsContainer .search .dropdown-menu .searchCitiesContainer::-webkit-scrollbar-thumb {
    background:                         var(--darkBlue);
}

.mustsSection .searchCityContainer {
    display:                            flex;
    gap:                                10px;
    padding:                            25px 0;
    align-items:                        center;
}

.mustsSection .filterCityContentContainer > label, .mustsSection .searchCityContainer > label {
    max-width:                          280px;
    cursor:                             pointer;
}

.mustsSection .tableCities .sort.DESC{
    transform: 							rotate(180deg);
}

.mustsSection .dropdown-menu:has(.searchContainer) {
background-color:                       rgba(255, 255, 255, 1);
}

.mustsSection .filterActionsContainer .searchContainer {
    position: 							relative;
    width: 								fit-content;
    margin:                             20px;

}

.mustsSection .filterActionsContainer .searchContainer::after{
    content: 							"";
    display: 							block;
    width: 								25px;
    height: 							25px;
    background: 						url("../../images/search.svg") center center no-repeat;
    background-size: 					25px;
    position: 							absolute;
    top: 								0;
    bottom: 							0;
    right: 								12px;
    margin:  							auto 0;
    cursor: 							pointer;
}

.mustsSection .filterActionsContainer .searchContainer .search {
    color:                              rgba(0, 0, 0, 0.4);
    width: 								250px;
    font-size:                          14px;
    font-weight:                        400;
    background-color:                   rgba(4, 26, 57, 0.03);
}

.mustsSection .filterActionsContainer .searchContainer .search::placeholder {
    opacity:                            unset;
}

/*-- MEDIA QUERIES IPAD --*/
@media screen and (max-width: 1200px) and (min-width: 991px) {
	.mustsSection .filterActionsContainer .search .dropdown-menu:before {
        right:                          85px;
    }
    .mustsSection .filterActionsContainer .search .dropdown-menu {
        margin-left:                    -52px;
    }
}

@media screen and (width <= 991px) {
    
    .mustsSection .filtersContainer .dropdown-toggle {
        height:                         100%;
        opacity:                        0.75;
    }

    .mustsSection .dropdown-menu {
        width:                          100%;
    }

    .mustsSection .filterActionsContainer .search .dropdown-menu {
        width:                          250px;
        transform:                      translate3d(10px, 53px, 0px) !important;
    }

    .mustsSection .filterActionsContainer .search .searchContainer .search{
        width:                          100%;
    }


    .mustsSection .filterActionsContainer .search .dropdown-menu::before {
        right:                          105px;
    }

    .mustsSection .filtersContainer .filterContentContainer > .rangeInputContainer {
        width:                          100%;
    }
    
    .mustsSection .filtersContainer {
        padding-inline:                 8%;
        margin:                         20px 0;
        scrollbar-width:                none;
        background-image:               linear-gradient(to right, var(--grey), var(--grey)),
                                        linear-gradient(to right, var(--grey), var(--grey)),
                                        linear-gradient(to right, rgba(111, 113, 116, 0.5),rgba(245, 246, 247, 0.5)),
                                        linear-gradient(to left, rgba(111, 113, 116, 0.5), rgba(245, 246, 247, 0.5));
        background-position:            left center, right center, left center, right center;
        background-repeat:              no-repeat;
        background-size:                40px 100%, 40px 100%, 40px 100%, 40px 100%;
        background-attachment:          local, local, scroll, scroll;
    }

    .mustsSection .filterActionsContainer {
        margin-bottom:                  20px;
        padding-right:                  30px;
        justify-content:                flex-end;
    }

    .mustsSection .tableCities {
        display:                        block;
        overflow-x:                     auto;
        white-space:                    nowrap;
    }
}