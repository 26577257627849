.street-config-wrapper {
    position:                       	    absolute;
	top: 									100vh;
	bottom:                          		0;
	left:                            		0;
	right:                            	 	0;
	z-index:                          	 	999;
	border-radius:                    	 	35px 35px 0 0;
	background:                       	 	var(--grey);
	box-shadow:                       	 	0 -50px 60px 0 rgba(7, 27, 57, 0.20);
	overflow-y:                       	 	auto;
	visibility:                       	 	hidden;
	opacity:                          	 	0;
	transition: 							all 0.3s ease-in-out;
}

.street-config-wrapper.opened {
    visibility:                       	 	visible;
	opacity:                          	  	1;
	top: 									100px;
	padding-top:                           	110px;
    bottom:                                 unset;
    min-height:                             100%;
}

.street-config-wrapper .containerMinimizeClose{
    display:                          	  	flex;
	position:                         		absolute;
	top:                              		30px;
	right:                           	 	30px;
	gap:                             		30px;
}

.street-config-wrapper .containerMinimizeClose > img {
	width:                              	14px;
	height:                             	auto;
	object-fit:                         	contain;
	object-position:                    	center center;
	cursor:                             	pointer;
}

.street-config-wrapper .head-wrapper{
    display:                                flex;
    justify-content:                        space-between;
}

.street-config-wrapper .head-wrapper .head-text > h5{
    opacity:                                0.5;
    font-weight:                            400;
    width:                                  60%;
}

.street-config-wrapper .head-wrapper .save-changes-container{
    display:                                flex;
    gap:                                    10px;
    flex-direction:                         column;
}

.street-config-wrapper .head-wrapper .save-changes-container > p{
    font-size:                              10px;
    font-weight:                            400;
    text-align:                             right;
    white-space:                            nowrap;
}

.street-config-wrapper .save-changes-container .blue-bg-button > span {
    white-space:                           nowrap;
}

.street-config-wrapper .save-changes-container .blue-bg-button::before{
    display:                                   inline-block;
    content:                                    "";
    width:                                      24px;
    height:                                     24px;
    background:                                 url("../../images/save.svg") center center no-repeat;
    background-size:                            24px 24px;
}

.street-config-wrapper .choose-city{
    display:                                    flex;
    justify-content:                            right;
    margin:                                     10px 0;
    position:                                   relative;
}

.street-config-wrapper .choose-city > .choose{
    border:                                     none;
    background:                                 none;
    display:                                    flex;
    align-items:                                center;
    gap:                                        10px;
    color:                                      #009AFF;
    padding:                                    5px 10px;
    height:                                     auto;
}

.street-config-wrapper .choose-city > .choose::before{
    display:                                    inline-block;
    content:                                    "";
    width:                                      24px;
    height:                                     24px;
    mask:                                       url("../../images/location_on.svg") center center no-repeat;
    background:                                 #009AFF;
    background-size:                            24px 24px;
}

.street-config-wrapper .choose-city > .choose:hover{
    color:                                      var(--darkblue);
    transition:                                 color 0.2s ease-in-out;

}

.street-config-wrapper .choose-city .choose:hover::after,
.street-config-wrapper .choose-city .choose:hover::before{
    background:                                 #041A39;
    transition:                                 background 0.2s ease-in-out;
}

.street-config-wrapper .choose-city > .choose::after{
    display:                                    inline-block;
    content:                                    "";
    width:                                      10px;
    height:                                     10px;
    mask:                                       url("../../images/expand_less.svg") center center no-repeat;
    background:                                 #009AFF;
    background-size:                            20px 20px;
    transform:                                  rotate(180deg);
}

.street-config-wrapper .body-wrapper .group-wrapper .group-head{
    display:                                    flex;
    background:                                 #FFF;
    box-shadow:                                 0px 4px 30px 0px rgba(35, 107, 216, 0.05);
    position:                                   relative;
    justify-content:                            space-between;
}

/*Accordion styling*/

.street-config-wrapper .body-wrapper .group-wrapper .accordion-button{
    padding:                                    15px 20px;
    background:                                 #FFF;
}

.street-config-wrapper .body-wrapper .group-wrapper .accordion-button > img {
    margin-left:                                15px;
    padding:                                    0 5px;
    order:                                      2;
}

.street-config-wrapper .body-wrapper .group-wrapper .accordion-button:focus{
    box-shadow:                                  none;
}

.street-config-wrapper .body-wrapper .group-wrapper .accordion-button::after{
    background-image:                           url(../../images/expand_less.svg);
    transform:                                  rotate(0deg);
    order:                                      1;
    padding:                                    0 5px;
    margin-left:                                25px;
}

.street-config-wrapper .body-wrapper .group-wrapper .accordion-button.collapsed::after{
    transform:                                   rotate(180deg);
}

.street-config-wrapper .accordion-item {
    border:                                      none;
}

.street-config-wrapper .accordion {
    flex-direction:                             column;
    display:                                    flex;
    gap:                                        50px;
}

.street-config-wrapper .accordion-collapse{
    background: rgba(7, 27, 57, 0.03);
}


.street-config-wrapper .body-wrapper .group-wrapper .group-body{
    background:                                 rgba(7, 27, 57, 0.03);
}

.street-config-wrapper .body-wrapper .group-wrapper .group-body .group-place{
    display:                                        flex;
    padding:                                        20px 5px 30px 30px;
}

.street-config-wrapper .body-wrapper .group-wrapper .group-body .group-place > h5{
    margin-right:                                   15px;
    padding-top:                                    9.5px;
}

.street-config-wrapper .body-wrapper .group-wrapper .group-body .group-place > img{
    display:                                        flex;
    align-self:                                     flex-start;
    padding-top:                                    10px;
    margin-left:                                    15px;
    cursor:                                         pointer;
}

.street-config-wrapper .body-wrapper .group-wrapper .group-body .group-place .place-data-row{
    display:                                        flex;
    flex-direction:                                 column;
    flex:                                           1 0 0;
    gap:                                            10px;
}

.street-config-wrapper .body-wrapper .group-wrapper .group-body .group-place .place-data-row .first-row{
    display:                                        flex;
    gap:                                            5px;
    align-items:                                    center;
}
/* 
.street-config-wrapper .body-wrapper .group-head .ghost-wrapper{
    width: 100%;
}


.street-config-wrapper .body-wrapper .group-head .ghost-wrapper::after{
    content:                                    "";
    position:                                   absolute;
    top:                                        25px;
    right:                                      75px;
    width:                                      20px;
    height:                                     20px;
    mask:                                  url("../../images/edit.svg") center center no-repeat;
    background:                                #009AFF;
    transition:                                 background 0.2s ease-in-out;
    background-size:                            20px 20px;
} */

.street-config-wrapper .body-wrapper .group-head .ghost-wrapper:hover::after{
    background:                             #041A39;
}

.street-config-wrapper .blue-bg-button{
    border:                                     none;
    background:                                rgba(0, 163, 255, 0.10);
    transition:                                  background 0.2s ease-in-out;
    padding:                                      6px 10px;
    display:                                     flex;
    align-items:                                 center;
    gap:                                         5px;
}

.street-config-wrapper  .blue-bg-button {
    background:                               var(--gradient), var(--blue);
    background-clip:                          text, padding-box;
    -webkit-background-clip:                  text, padding-box;
    -webkit-text-fill-color:                  transparent;
}

.street-config-wrapper  .blue-bg-button:hover{
    background-color:                         #041A39;
}

.street-config-wrapper .body-wrapper .blue-bg-button.place::before{
    display:                                   inline-block;
    content:                                    "";
    width:                                      15px;
    height:                                     15px;
    background:                                 url("../../images/location_on.svg") center center no-repeat;
    background-size:                            15px 15px;
}


.street-config-wrapper  .blue-bg-button.group:disabled {
    background: 							rgba(0, 0, 0, 0.05);
	-webkit-text-fill-color: 				rgba(0, 0, 0, .3);
    cursor:                                 not-allowed;
}

.street-config-wrapper  .blue-bg-button.group::before{
    display:                                    inline-block;
    content:                                    "";
    width:                                      24px;
    height:                                     24px;
    mask:                                       url("../../images/atr.svg");
    background:                                 var(--gradient) center center no-repeat;
    background-size:                            20px 20px;
}

.street-config-wrapper  .blue-bg-button.group:disabled::before{
    background: 							    rgba(0, 0, 0, 0.3);
}

.street-config-wrapper .body-wrapper .group-wrapper .group-body .group-place .info{
    mask:                                       url("../../images/info-blue.svg") center center no-repeat;
    background:                          #009AFF;
    transition:                                    background 0.2s ease-in-out;
    width:                                         30px;
    height:                                        24px;
    opacity:                                        0.7;
    border:                                        none;
    position:                                       absolute;
    top:                                            7px;
    right:                                          5px;
}

.street-config-wrapper .body-wrapper .group-wrapper .group-body .group-place .info:hover{
    background:                                   #041A39;
}
.street-config-wrapper .body-wrapper .group-head .group-title-input{
    border-radius:                                  10px;
    border:                                         1px solid rgba(0, 0, 0, 0.05);
    font-size:                                      16px;
    font-weight:                                    700;
    opacity:                                        1;
    color:                                         black;
    background:                                     white;
    width:                                          95%;
}

.street-config-wrapper .body-wrapper .group-body .group-place .input-container {
    position:                                       relative;
    width:                                          50%;
}


.street-config-wrapper .body-wrapper .group-body .group-place .place-input{
    border-radius:                                  10px;
    padding-right:                                  40px;
    text-overflow:                                  ellipsis;
    overflow:                                       hidden;
    white-space:                                    nowrap;
    border:                                         1px solid rgba(0, 0, 0, 0.05);
    background:                                    rgba(7, 27, 57, 0.03);
    font-size:                                      16px;
    font-weight:                                    700;
    opacity:                                        1;
    color:                                         black;
}

.street-config-wrapper .body-wrapper .group-body .group-place .place-input::placeholder,
.street-config-wrapper .body-wrapper .group-head .group-title-input::placeholder{
    font-size:                                      16px;
    font-weight:                                    700;
    opacity:                                        0.5;
    color:                                        black;
}

.street-config-wrapper .body-wrapper .group-body .group-place .place-input.highlight::placeholder,
.street-config-wrapper .body-wrapper .group-head .group-title-input.highlight::placeholder{
    opacity:                                           1;
}

.street-config-wrapper .body-wrapper .group-body .group-place .description,
.street-config-wrapper .body-wrapper .group-body .group-place .description::placeholder{
    font-size:                                           16px;
    font-weight:                                         400;
    color:                                              rgba(0, 0, 0, 0.40);
}

.street-config-wrapper .head-wrapper .save-changes-container .saved{
    color:                                              green;
}


.tooltip-text-street-link{
    width:                                                 100px;
}

.street-config-wrapper .choose-city .city-lookup-wrapper{
    position:                                               absolute;
    background:                                          #fff;
    box-shadow:                                             0px 4px 30px 0px rgba(35, 107, 216, 0.05);
    top:                                                    100%;
    right:                                                  80px;
    z-index:                                                1;
    visibility:                                             hidden;
    opacity:                                                0;
    transition:                                             opacity 0.2s ease-in-out;
    padding:                                                20px;
}

.street-config-wrapper .choose-city .city-lookup-wrapper.active{
    visibility:                                             visible;
    opacity:                                                0.95;
    z-index:                                                 999;
}

.street-config-wrapper .choose-city .city-lookup-wrapper .city-search-input{
    background:                                             rgba(4, 26, 57, 0.03);
}

.street-config-wrapper .choose-city .city-lookup-wrapper .city-search-input::placeholder{
    font-size:                                                  14px;
    font-weight:                                                 400;
    color:                                                      rgba(0, 0, 0, 0.70);
}

.street-config-wrapper .choose-city .lookup-ghost-wrapper::after{
    content:                                                     "";
    position:                                                    absolute;
    top:                                                         30px;
    right:                                                       25px;
    width:                                                       20px;
    height:                                                      20px;
    mask:                                                        url("../../images/search.svg") center center no-repeat;
    background:                                                 #009AFF;
    transition:                                                 background 0.2s ease-in-out;
    background-size:                                            20px 20px;
}

.street-config-wrapper .choose-city .city-result{
    padding:                                                    10px 35px 10px 5px;
    transition:                                                 background 0.2s ease-in-out;
    cursor:                                                     pointer;
}

.street-config-wrapper .choose-city .city-result:hover{
    background:                                                rgba(4, 26, 57, 0.10);
}

.street-config-wrapper .choose-city .city-result > h5{
    font-weight:                                                 400;
    padding:                                                     10px 35px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.street-config-wrapper .choose-city .city-result:hover > h5{
    background:                                                   var(--gradient);
    background-clip:                                              text;
    -webkit-background-clip:                                      text;
    -webkit-text-fill-color:                                      transparent;       
}

.street-config-wrapper .choose-city .city-result-empty{
    padding:                                                        10px 35px 10px 5px;
    transition:                                                    background 0.2s ease-in-out;
    cursor:                                                         auto;
}


.street-config-wrapper .choose-city .city-result-empty > h5{
    font-weight:                                                    400;
    padding:                                                        10px 35px;
}

.street-config-wrapper .choose-city .city-result-wrapper{
    position:                                                       absolute;
    background:                                                   #FFF;
    top:                                                            320%;
    right:                                                          80px;
    /* min-width:                                                      300px; */
    z-index:                                                         1;
    visibility:                                                      hidden;
    opacity:                                                         0;
    transition:                                                     opacity 0.2s ease-in-out;
    box-shadow:                                                     0px 4px 30px 0px rgba(35, 107, 216, 0.05);
    max-height:                                                         300px;
    overflow-y:                                                      auto ; 

}
.street-config-wrapper .choose-city .city-result-wrapper.active{
    visibility:                                     visible;
    opacity:                                        0.98;
    z-index:                                        999;
    max-width:                                      40%;
}

@media screen and (width <= 991px) {
    .street-config-wrapper .blue-bg-button {
        justify-content:                            center;
    }

    .street-config-wrapper .head-wrapper .head-text > h1{
        font-size:                                  32px;
    }

    .street-config-wrapper .choose-city .city-result-wrapper.active {
        max-width:                                      60%;
    }

    .street-config-wrapper .choose-city .city-result > h5 {
        padding:                                   10px 5px 10px 10px;
    }
}