@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 						"DM sans", sans-serif;
	background-color: 					var(--grey);
	color: 								#000000;
}

::-webkit-scrollbar {
	width: 								8px;
	height: 8px;
}

  /* Track */
::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
	border-radius: 						10px;

}

  /* Handle */
::-webkit-scrollbar-thumb {
	background: 						#072550;
	transition: 						background 0.1s ease-in-out;
	border-radius: 						10px;

  }
  
  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: 						var(--darkBlue);

  }
  
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

:root{
	--gradient: 						linear-gradient(180deg, #009AFF 19.79%, #8CA5FF 100%);
	--darkBlue: 						#041A39;
	--blue: 							rgba(0, 162, 255, 0.10);
	--green:							#08D1A1;
	--red: 								#FF316F;
	--grey: 							#F5F6F7;
	--orange: 							#FF7B31;
	/* color-scheme: 						light dark; */
}

h1 {
	font-size: 							64px;
	font-weight: 						700;
}

h2 {
	font-size: 							45px;
	font-weight: 						700;
}

h3 {
	font-size: 							32px;
	font-weight: 						700;
}

h4 {
	font-size: 							23px;
	font-weight: 						500;
}

h5 {
	font-size: 							16px;
}

p{
	font-size: 							16px;
	color: 								#7B7B7C;
}

span{
	font-size: 							16px
}

h1, h2, h3, h4, h5, p, span, img, button{
	margin: 							0;
	display: 							block;
}

.hide {
	opacity: 							0;
	visibility: 						hidden;
}

#tooltip-img-container {
    opacity:                        1;
}

#tooltip-img-container .tooltip-inner {
    color:                          black;
    background-color:               rgba(255, 255, 255, 1);
    box-shadow:                     10px 10px 15px 0px rgba(4, 26, 57, 0.2);
}

#tooltip-img-container .tooltip-arrow::before {
    border-top: 			        6px solid #FFFFFF;
}

.inputContainer{
	margin-bottom: 						25px;
}
.inputContainerFifty{
	display:   	  						inline-block;
	width:   							50%;
	padding-left: 						5px;
	padding-right:   					5px;
	margin-bottom: 						25px;
}

.inputContainer > .numberSelector,
.mobile-inputContainer > .numberSelector{
	position: 							relative;
	width: 								15px;
	height: 							50%;
	position: 							absolute;
	right: 								0;
	top: 								0;
	background-color: 					var(--grey);
	cursor: 							pointer;
}

.inputContainer > .numberSelector:last-of-type,
.mobile-inputContainer > .numberSelector:last-of-type{
	top: 								50%;
}

.inputContainer > .numberSelector::after,
.mobile-inputContainer > .numberSelector::after{
	content: 							"";
	width: 								0;
	height: 							0;
	border-left: 						4px solid transparent;
	border-right: 						4px solid transparent;
	border-bottom: 						4px solid var(--darkBlue);
	position: 							absolute;
	top: 								50%;
	left: 								50%;
	transform: 							translate(-50%, -50%);
}

.inputContainer > .numberSelector:last-of-type:after,
.mobile-inputContainer > .numberSelector:last-of-type:after{
	border-top: 						4px solid var(--darkBlue);
	border-bottom: 						4px solid transparent;
}

.inputContainer .noteUser{
	font-size: 							10px;
	opacity: 							0.5;
	margin-top: 						0;
	max-height: 						0;
	overflow: 							hidden;
	transition: 						all 0.3s ease-in-out;
	display: 							flex;
	align-items: 						center;
}

.inputContainer .noteUser.show{
	max-height: 						1000px;
	margin-top: 						10px;
}

.inputContainer .noteUser > img{
	width: 								10px;
	height: 							10px;
	margin-right: 						5px;
}

input:not(input[type="checkbox"], input[type="radio"]), textarea{
	width: 								100%;
	height: 							40px;
	border: 							unset;
	background-color: 					#FFFFFF;
	padding: 							10px 20px;
	font-size: 							14px;
	position: 							relative;
	border-bottom: 						1px solid transparent;
	transition: 						border-bottom 0.3s ease-in-out;
}

input:not(input[type="checkbox"], input[type="radio"])::placeholder, textarea::placeholder{
	opacity: 							0.4;
}

input:not(input[type="checkbox"], input[type="radio"]):focus, textarea:focus{
	outline: 							unset;
	border-bottom: 						1px solid #009AFF;
}

textarea{
	height: 							auto;
	resize: 							none;
}

.passwordContainer{
	position: 							relative;
}

.passwordContainer .passwordIcon{
	width: 								25px;
	height: 							25px;
	background: 						url("./assets/images/visibilityOff.svg");
	background-size: 					25px;
	background-position: 				center center;
	cursor: 							pointer;
	position: 							absolute;
	right: 								20px;
	bottom: 							8px;
}

.passwordContainer .passwordIcon.show{
	background: 						url("./assets/images/visibility.svg");
	background-size: 					25px;
	background-position: 				center center;
}

.passwordContainer .passwordTooltip{
	width: 								fit-content;
	padding: 							10px 25px;
	color: 								rgba(0, 0, 0, 0.5);
	background-color: 					#FFFFFF;
	position: 							absolute;
	top: 								120%;
	right: 								-12%;
	visibility: 						hidden;
	opacity:							0;
	transition: 						all 0.3s ease-in-out;
}

.passwordContainer .passwordTooltip.show{
	visibility: 						visible;
	opacity: 							1;
}

.passwordContainer .passwordTooltip::before{
	content: 							"";
	width: 								0;
	height: 							0;
	border-left: 						10px solid transparent;
	border-right: 						10px solid transparent;
	border-bottom: 						10px solid #FFFFFF;
	position: 							absolute;
	left: 								0;
	right: 								0;
	bottom: 							100%;
	margin: 							0 auto;
}

input[type="checkbox"], input[type="radio"]{
	/* removing default appearance */
	-webkit-appearance: 				none;
	appearance: 						none;
	/* creating a custom design */
	width: 								20px;
	height: 							20px;
	border-radius: 						10px;
	border: 							1px solid rgba(0, 0, 0, 0.2);
	outline: 							none;
	cursor: 							pointer;
	transition: 						all 0.3s ease-in-out;
}

input[type="checkbox"]:checked, input[type="radio"]:checked{
	background-color: 					var(--green);
	border: 							1px solid transparent;
	position: 							relative;
}

input[type="checkbox"]:checked::after, input[type="radio"]:checked::after{
	content: 							"";
	width: 								13px;
	height: 							13px;
	background: 						url("./assets/images/checked.svg") no-repeat center center;
	background-size: 					13px;
	position: 							absolute;
	top: 								0;
	left: 								0;
	right: 								0;
	bottom: 							0;
	margin: 							auto;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: 				none;
	appearance: 						none;
}

.dropdown-toggle{
	width:                              100%;
	font-size:                          14px;
	color:                              rgba(0, 0, 0, 0.4);
	border:                             unset;
	border-radius:                      unset;
	padding:                            15px 20px;
	text-align:                         start;
	background-color:                   #FFFFFF;
	border-bottom:                      1px solid transparent;
	transition:                         all 0.3s ease-in-out;
}

.dropdown-toggle.show, .dropdown-toggle:hover, .dropdown-toggle:focus, .dropdown-toggle:active{
	outline:                            unset;
	box-shadow:                         unset;
	background-color:                   #FFFFFF;
	color:                              #000000;
	border-bottom:                      1px solid #009AFF;
}

.dropdown-toggle::after{
	border:                             unset;
	width:                              20px;
	height:                             20px;
	background:                         url("./assets/images/arrowDown.svg") center center no-repeat;
	background-size:                    20px;
	position:                           absolute;
	top: 								0;
	bottom:                             0;
	right:                              10px;
	margin: 							auto 0;
	cursor:                             pointer;
	transition:                         all 0.3s ease-in-out;
}

.dropdown-toggle.show::after{
	transform:                          rotate(180deg);
}

.dropdown-menu{
	width:                              100%;
	padding:                            0;
	background:                         #FFFFFF;
	box-shadow:                         0px 5px 30px 0px rgba(35, 107, 216, 0.15);
	border:                             unset;
	border-radius:                      unset;
	overflow-y:                         auto;
}

.dropdown-menu::-webkit-scrollbar {
	width:                              3px;
}

.dropdown-menu::-webkit-scrollbar-track {
	background:                         rgba(0, 0, 0, 0.05);
}

.dropdown-menu::-webkit-scrollbar-thumb {
	background:                         var(--darkBlue);
}

.dropdown-item{
	font-size:                          12px;
	padding:                            15px 20px;
	transition:                         all 0.3s ease-in-out;
}

.dropdown-item.radio{
	display:                            flex;
	align-items:                        center;
	pointer-events:                     none;
}

a {
	text-decoration: 					none;
	font-size: 							16px;
	display: 							block;
	color: 								#000000;
}

.danger{
	color: 								var(--red);
}

.swal2-actions {
	gap: 								10px;
}

.swal2-button--confirm {
	border-radius: 						0 !important;
	font-weight:                        400 !important;
	background: 						var(--gradient), var(--blue) !important;
	background-clip:                    text, padding-box !important;
	-webkit-background-clip:            text, padding-box !important;
	-webkit-text-fill-color:            transparent !important;
	padding:                            10px 20px !important;
	border-color: 						transparent !important;
	box-shadow: 						unset !important;
}

.swal2-button--confirm:hover {
	font-weight:                        400 !important;
	background: 						var(--gradient), var(--darkBlue) !important;
	background-clip:                    text, padding-box !important;
	-webkit-background-clip:            text, padding-box !important;
	-webkit-text-fill-color:            transparent !important;
}

.swal2-button--confirm:focus-visible {
	outline: 							unset !important;
}

.swal2-button--cancel {
	border-radius: 						0 !important;
	font-weight:                        400 !important;
	color: 								black !important;
	background: 						white !important;
	padding:                            10px 20px !important;
	border-color: 						transparent !important;
	box-shadow: 						unset !important;
}

.swal2-button--cancel:focus-visible {
	outline: 							unset !important;
}

.no-record-wrapper{
    display: 							flex;
    width: 								100%;
    flex-direction: 					column;
    align-items: 						center;
    margin-bottom: 						30px;
    gap: 								20px;
}

.no-record-title{
	font-weight: 						700;
}

/*-- MEDIA QUERIES IPAD --*/
@media screen and (max-width: 1200px) and (min-width: 991px) {
	h1 {
		font-size: 						48px;
	}
	h3 {
		font-size:  					28px;
	}
}

@media screen and (width <= 991px) {
	.inputContainerFifty {
		width: 							100%;
	}
}


/* @media screen and (width <= 374px) {
	.container {
		max-width: 						320px;
	}
} */