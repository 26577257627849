.assumptionsSection .assumptionTitle {
    font-size:                          23px;
    font-weight:                        700;
    margin-top:                         50px;
    margin-bottom:                      25px;
}

.assumptionsSection .assumptionSubtitle {
    font-size:                          16px;
    font-weight:                        800;
    margin-top:                         35px;
    margin-bottom:                      25px;
    border-top:                         1px solid #EAEAEA;
    padding-top:                        15px;
}

.assumptionsSection .assumptionSubtitle2 {
    font-size:                          16px;
    font-weight:                        700;
    margin-top:                         30px;
    margin-bottom:                      10px;
}

.assumptionsSection .inputTitle {
    margin-bottom:                      10px;
}

.assumptionsSection .inputTitle > label {
    font-size:                          12px;
    opacity:                            0.5;
    font-style:                         italic;
}

.assumptionsSection .assumptionRadioSizeTypeContainer {
    display:                            flex;
    align-items:                        center;
    margin-bottom:                      8px;
}

.assumptionsSection .secondaryButton {
    padding:                            5px 5px;
}

.assumptionsSection .secondaryButton:first-of-type:not(.addRole) {
    margin-left:                        20px;
}

.assumptionsSection .secondaryButton:last-of-type:not(.addRole) {
    margin-left:                        10px;
}

.assumptionsSection .secondaryButton.radioDisabled {
    background:                         rgba(0, 0, 0, 0.05);
    -webkit-text-fill-color:            rgba(0, 0, 0, 0.3);
}

.assumptionsSection .secondaryButton.radioDisabled:hover > span {
    -webkit-text-fill-color:            #FFFFFF;
}

.assumptionsSection .assumptionRequirementsCommentsContainer {
    display:                            flex;
    gap:                                30px;
    flex-wrap:                          wrap;
}

.assumptionsSection .assumptionCheckboxContainer {
    display:                            flex;
    gap:                                10px;
    width:                              33%;
    flex-grow:                          1;
    align-items:                        center;
}

.assumptionsSection .assumptionCheckboxContainer > input[type="checkbox"],
.assumptionsSection .assumptionCheckboxContainer > input[type="radio"] {
    flex-shrink:                        0;
}

.assumptionsSection .assumptionCheckboxContainer > input:not([type="checkbox"], [type="radio"]) {
    visibility:                         hidden;
    opacity:                            0;
    transition:                         all 0.3s ease-in-out;
}

.assumptionsSection .assumptionCheckboxContainer > input:not([type="checkbox"], [type="radio"]).active {
    visibility:                         visible;
    opacity:                            1;
}

.assumptionsSection .headcountOptionsTitleContainer {
    display:                            flex;
    gap:                                72px;
    align-items:                        center;
    margin-bottom:                      10px;
}

/* .assumptionsSection .headcountOptionsContainer .quantity {
    flex:                               0 0 90px;
}

.assumptionsSection .headcountOptionsContainer .inputContainer:nth-of-type(2) {
    flex-grow:                          1;
}

.assumptionsSection .headcountOptionsContainer {
    display:                            flex;
    gap:                                10px;
    align-items:                        center;
    margin-bottom:                      10px;
} */

.assumptionsSection .file-input{
    display:                            none;
}


.assumptionsSection .custom-file-button{
    display:                            inline-block;
    padding:                            8px 20px;
    cursor:                             pointer;
    background:                         var(--Blue_10, rgba(0, 163, 255, 0.10));
    transition:                         background 0.1s ease-in-out;
}


.assumptionsSection .custom-file-button:hover{
    background-color:                   var(--darkBlue);
}

.assumptionsSection .custom-file-button >span{
    background:                         var(--gradient, linear-gradient(180deg, #009AFF 19.79%, #8CA5FF 100%));
    background-clip:                    text;
    -webkit-background-clip:            text;
    -webkit-text-fill-color:            transparent;
}

/* .assumptionsSection .headcountOptionsContainer .inputContainer {
    margin-bottom:                      0;
    position:                           relative;
} */

/* .assumptionsSection .addRole {
    padding:                            5px 10px;
    margin-bottom:                      35px;
}

.assumptionsSection .addRole:last-of-type {
    margin-bottom:                      55px;
} */

.assumptionsSection .assumptionCheckboxContainer > label,
.assumptionsSection .assumptionNavigationContainer .menuContainer {
    cursor:                             pointer;
}

.assumptionsSection .assumptionNavigationContainer {
    width:                              25%;
    position:                           fixed;
    top:                                400px;
    margin-top:                         100px;
}

.assumptionsSection .assumptionNavigationContainer .miniDot {
    width:                              3px;
    height:                             3px;
    background-color:                   rgba(0, 0, 0, 0.4);
    border-radius:                      50%;
    margin-bottom:                      10px;
    margin-left:                        6px;
}

.assumptionsSection .assumptionNavigationContainer .menuContainer {
    display:                            flex;
    gap:                                25px;
    margin-bottom:                      7px;
}

.assumptionsSection .assumptionNavigationContainer .menuContainer.active {
    align-items:                        unset;
}

.assumptionsSection .mobile-navigation,
.assumptionsSection .mobile-navigation .optionContainer {
    display:                            flex;
    align-content:                      center;
}

.assumptionsSection .mobile-navigation {
    margin-top:                         20px;
    gap:                                30px;
    overflow-x:                         auto;
    width:                              100%;
    padding-inline:                     9%;
    flex-wrap:                          nowrap;
    scrollbar-width:                    none;
    background-image:                   linear-gradient(to right, var(--grey), var(--grey)),
                                        linear-gradient(to right, var(--grey), var(--grey)),
                                        linear-gradient(to right, rgba(208, 210, 212, 0.5),rgba(245, 246, 247, 0.5)),
                                        linear-gradient(to left, rgba(208, 210, 212, 0.5), rgba(245, 246, 247, 0.5));
    background-position:                left center, right center, left center, right center;
    background-repeat:                  no-repeat;
    background-size:                    40px 100%, 40px 100%, 40px 100%, 40px 100%;
    background-attachment:              local, local, scroll, scroll;
}

.assumptionsSection .mobile-navigation .optionContainer {
    gap:                                10px;
}

.assumptionsSection .assumptionNavigationContainer .menuContainer .dot,
.assumptionsSection .mobile-navigation .optionContainer .dot  {
    width:                              10px;
    height:                             10px;
    flex-shrink:                        0;
    background-color:                   rgba(0, 0, 0, 0.4);
    border-radius:                      50%;
    margin-left:                        3px;
    margin-top:                         6px;
}

.assumptionsSection .assumptionNavigationContainer .menuContainer.active .dot {
    width:                              15px;
    height:                             15px;
    background:                         var(--gradient, linear-gradient(180deg, #009AFF 19.79%, #8CA5FF 100%));
    border-radius:                      50%;
    margin-left:                        0;
    margin-top:                         10px;
}

.assumptionsSection .mobile-navigation .optionContainer.active .dot {
    width:                              15px;
    height:                             15px;
    background:                         var(--gradient, linear-gradient(180deg, #009AFF 19.79%, #8CA5FF 100%));
    border-radius:                      50%;
}

.assumptionsSection .assumptionNavigationContainer .menuContainer .menuTitle {
    font-size:                          16px;
    font-weight:                        400;
    color:                              rgba(0, 0, 0, 0.4);
}

.assumptionsSection .assumptionNavigationContainer .menuContainer.active > .menuTitle {
    font-size:                          23px;
    font-weight:                        700;
    color:                              #000000;
}

.assumptionsSection .dropdown-toggle {
    min-width:                          100px;
    padding:                            5px 20px;
    height:                             40px;
}

.assumptionsSection .dropdown.active .dropdown-toggle {
    color:                              #000000;
}

.assumptionsSection .file-msg{
    text-align:                         center;
}

.assumptionsSection .file-msg.error{
    color:                              red;

}

.assumptionsSection .file-msg.success{
    color:                              green;

}

.assumptionsSection .inputContainer .file-button-container{
    display:                            flex;
    align-items:                        center;
    gap:                                20px;
}

.assumptionsSection .containerSettings {
	width: 								auto;
    min-width:                          70%;
	background-color: 					#FFFFFF;
	box-shadow: 						0px 15px 50px 0px rgba(7, 27, 57, 0.15);
	padding: 							50px;
	position: 							absolute;
    bottom: 							85%;
    margin: 							auto 0;
    right: 								-26px;
	visibility: 						hidden;
	opacity: 							0;
	transition: 						all 0.3s ease-in-out;
}

.assumptionsSection .containerSettings.show{
	visibility: 						visible;
	opacity: 							1;
}

.assumptionsSection .containerSettings::after{
	content: 							"";
	width: 								0;
	height: 							0;
	border-top: 						15px solid #FFFFFF;
	border-left: 						20px solid transparent;
	border-right: 						20px solid transparent;
	position: 							absolute;
	top: 								100%;
	right: 								15px;
}

.assumptionsSection .containerSettings .titleAndType{
	display: 							flex;
	align-items: 						center;
	justify-content: 					space-between;
	margin-bottom: 						15px;
}

.assumptionsSection .containerSettings .titleAndType h5{
	font-weight: 						700;
}

.assumptionsSection .containerSettings .dropdown-menu {
    max-height:                         600%;
}

.assumptionsSection .containerSettings .informativeInputs {
	display:                            flex;
    flex-direction:                     row;
    flex-wrap:                          wrap;
    margin-top:                         30px;
}

.assumptionsSection .containerSettings .informativeInputs .metrics {
	display:                            inline-block;
    width:                              50%;
    margin-bottom:                      20px;
    padding:                            0 5px;
}

.assumptionsSection .containerSettings .informativeInputs .metrics > label {
	white-space:                        nowrap;
}

.assumptionsSection .containerSettings .informativeInputs .metrics input:not([type="checkbox"]) {
	background-color:                  rgba(0, 0, 0, 0.05);
    padding:                            10px 10px;
}

.assumptionsSection .containerSettings .informativeInputs .metrics input:disabled {
	opacity: 						    0.5;
	cursor: 						    not-allowed;
}

.assumptionsSection .containerSettings .informativeInputs .metrics input[type="checkbox"] {
    display:                            flex;
    margin-top:                         5px;
}

.assumptionsSection .originsPoints {
    display:                            flex;
    align-items:                        center;
    gap:                                10px;
    position:                           relative;
}

.assumptionsSection .originsPoints .deleteOrigin{
    width:                              16px;
    height:                             16px;
    object-fit:                         contain;
    object-position:                    center center;
    margin-bottom:                      5px;
    cursor:                             pointer;
}

.assumptionsSection .addOrigin{
    padding:                            5px 10px;
    margin:                             0 !important;
}

.assumptionsSection .originsPoints .placesFoundContainer {
    top:                                85%;
}

.assumptionsSection .originsPoints > input{
    margin-bottom:                      10px;
}

.assumptionsSection .groupContainer {
    width:                              100%;
}

.assumptionsSection .groupNameContainer {
    display:                            flex;
    background:                         #EAEAEA;
    padding:                            10px;
    margin-bottom:                      10px;
    justify-content:                    space-between;
    align-items:                        center;
}

.assumptionsSection .groupNameContainer .groupName {
    width:                              50%;
}

.assumptionsSection .groupNameContainer .deleteInboundGroup {
    width:                              16px;
    height:                             16px;
    object-fit:                         contain;
    object-position:                    center center;
    margin-top:                         5px;
    cursor:                             pointer;
}

.assumptionsSection .originsGroupContainer {
    padding-left:                       20px;
    margin-bottom:                      20px;
}

.assumptionsSection .originGroupSeparator {
    background:                         #EAEAEA;
    height:                             1px;
    margin-bottom:                      20px;
}

.assumptionsSection .destinationContainer {
    display:                            flex;
    align-items:                        center;
    gap:                                10px;
    position:                           relative;
}

.assumptionsSection .destinationPoints {
    width:                              47%;
}

.assumptionsSection .qty {
    width:                              11%;
}

.assumptionsSection .unit {
    flex:                               1 0 0;
}

.assumptionsSection .destinationContainer .deleteOrigin {
    width:                              16px;
    height:                             16px;
    object-fit:                         contain;
    object-position:                    center center;
    margin-top:                         5px;
    cursor:                             pointer;
}

.assumptionsSection .inputContainer.file input[type="file"]{
	background-color:                   transparent !important;
	padding:                            0 !important;
}

.assumptionsSection .inputContainer.file input[type="file"]::file-selector-button{
	padding:                            10px 15px;
	border:                             unset;
	background:                         var(--gradient), var(--blue);
	background-clip:                    text, padding-box;
	-webkit-background-clip:            text, padding-box;
	-webkit-text-fill-color:            transparent;
	cursor:                             pointer;
	transition:                         all 0.3s ease-in-out;
}

.assumptionsSection .inputContainer.file input[type="file"]::file-selector-button:hover{
	background:                         var(--gradient), var(--darkBlue);
	background-clip:                    text, padding-box;
	-webkit-background-clip:            text, padding-box;
}

.assumptionsSection .inputContainer.file .btnBgBlueTextGradient{
    font-size:                          14px;
	border: 							unset;
	padding: 							5px 15px;
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
	transition: 						all 0.3s ease-in-out;
}


.assumptionsSection .inputContainer.file .btnBgBlueTextGradient:hover{
	background-color: 					var(--darkBlue);
}

.assumptionsSection .inputContainer.file .btnBgBlueTextGradient:disabled{
	background: 						transparent;
	-webkit-text-fill-color: 			unset;
}

.assumptionsSection .inputContainer.file .inputFileContainer{
    display:                            flex;
    gap:                                10px;
}

.assumptionsSection .inputContainer.file .inputFileContainer > img{
    cursor:                              pointer;
}

.assumptionsSection .inputContainer.file .inputFileContainer .selectFileContainer{
    display:                            flex;
    align-items:                        center;
    gap:                                10px;
    position:                           relative;
}

.assumptionsSection .representationFile{
    margin:                             15px 0 15px 0;
}

.assumptionsSection .actions {
    display:                            flex;
    align-items:                        center;
    justify-content:                    space-between;
}

.assumptionsSection .selectFileContainer .loading {
    width:                              30px;
    height:                             30px;
    visibility:                         hidden;
    opacity:                            0;
    transition:                         all 0.3s ease-in-out;
    position:                           absolute;
    top:                                0;
    left:                               -10px;
}

.assumptionsSection .representationFile .loading.show {
    visibility:                         visible;
    opacity:                            1;
}

.assumptionsSection .inputContainer.industrySearch {
    position:                           relative;

}


.assumptionsSection .inputContainer.industrySearch .industriesFoundContainer {
    box-shadow:                         0 5px 10px rgba(0, 0, 0, 0.1);
    position:                           absolute;
    opacity:                            0;
    visibility:                         hidden;
    transition:                         all 0.2s ease-in-out;
    z-index:                            999;
    max-height:                         220px;
    width:                              100%;
    overflow-y:                         auto;
    left:                               0;
    display:                            flex;
    flex-direction:                     column;
}

.assumptionsSection .inputContainer.industrySearch .dropdown-menu{
    max-height:                         3000px;
    overflow-y:                         visible;
}

.assumptionsSection .inputContainer.industrySearch .dropdown .textContainer{
    white-space:                        nowrap;
    overflow:                           hidden;
    text-overflow:                      ellipsis;
    width:                              100%;

}

.assumptionsSection .inputContainer.industrySearch .industriesFoundContainer .option-row{
    display:                            flex;
    align-items:                        center;
    gap:                                10px;
    padding:                            10px;
    background-color:                  #FFFFFF;
    cursor:                             pointer;
}

.assumptionsSection .inputContainer.industrySearch .industriesFoundContainer .option-row .option{
    background:                        white;
    padding:                            25px;
    transition:                         all 0.2s ease-in-out;
    
}

.assumptionsSection .inputContainer.industrySearch .industriesFoundContainer .option-row:hover {
    background-color:                   var(--grey);
}

.assumptionsSection .inputContainer.industrySearch .industriesFoundContainer .option-row.selected > label {
    color:                             #009AFF;
}


.assumptionsSection .inputContainer.industrySearch .industriesFoundContainer.show {
    opacity:                            1;
    visibility:                         visible;
}

.assumptionsSection .inputContainer.industrySearch .lookupWrapper{
    background-color:                  white;
    padding:                            10px;

}

.assumptionsSection .inputContainer.industrySearch .lookupWrapper.active{
    box-shadow:                         0 5px 10px rgba(0, 0, 0, 0.1);
}

@media screen and (width <= 991px) {
    .assumptionsSection .assumptionRequirementsCommentsContainer {
        display:                        inline-flex;
        flex-direction:                 column;
        gap:                            20px;
    }

    .assumptionsSection .assumptionCheckboxContainer {
        width:                          100%;
    }

    .assumptionsSection .destinationContainer {
        flex-wrap:                      wrap;
    }

    .assumptionsSection .groupNameContainer .groupName {
        width:                          80%;
    }

    .assumptionsSection .groupNameContainer .deleteInboundGroup {
        margin-top:                     unset;
    }

    .assumptionsSection .destinationPoints {
        width:                          100%;
    }

    .assumptionsSection .qty {
        width:                          30%;
    }

    .assumptionsSection .containerSettings .informativeInputs {
        flex-direction:                 column;
    }

    .assumptionsSection .containerSettings .informativeInputs .metrics {
        width:                          100%;
    }

    .assumptionsSection .containerSettings {
        bottom:                         40%;
    }

    .assumptionsSection .inputContainer.file .inputFileContainer .selectFileContainer {
        flex-wrap:                      wrap;
    }
}