.wantsMobileContainer {
    width:                                  100%;
    height:                                 100%;
    overflow:                               hidden;
}

.wantsMobileContainer .accordion-item {
    border:                                 unset;
    border-top-left-radius:                 unset !important;
    border-top-right-radius:                unset !important;
    margin-bottom:                          15px;
}

.wantsMobileContainer .accordion-button {
    align-items:                            center;
    border-top-left-radius:                 unset !important;
    border-top-right-radius:                unset !important;
    transition:                             all 0.3s ease-in-out;
}

.wantsMobileContainer .accordion-button:focus {
    box-shadow:                             unset;
}

.wantsMobileContainer .accordion-button::after {
    margin-left:                            5px;
    background-image:                       url(../../images/arrowDown.svg);
}

.wantsMobileContainer .accordion-button:not(.collapsed) {
    background-color:                       var(--darkBlue);
}

.wantsMobileContainer .accordion-button:not(.collapsed) h5,
.wantsMobileContainer .accordion-button:not(.collapsed) input {
    color:                                  #fff;
}

.wantsMobileContainer .accordion-button:not(.collapsed) .mobile-inputContainer .numberSelector:first-of-type:after {
    border-bottom:                          4px solid #fff;
}

.wantsMobileContainer .accordion-button:not(.collapsed) .mobile-inputContainer .numberSelector:last-of-type::after {
    border-top:                             4px solid #fff;
}

.wantsMobileContainer .accordion-button:not(.collapsed) .mobile-inputContainer::after {
    font-weight:                            600;
    background:                             #fff;
    background-clip:                        text;
    -webkit-background-clip:                text;
    -webkit-text-fill-color:                transparent; 
}

.wantsMobileContainer .accordion-button:not(.collapsed) .mobile-inputContainer > input {
    border:                                 1px solid rgba(0, 162, 255, 0.10);
}

.wantsMobileContainer .accordion-button:not(.collapsed)::after {
    background-image:                       url(../../images/arrowDown.svg);
}

.wantsMobileContainer .mobile-catTitleSection {
    display:                                flex;
    flex-direction:                         row;
    gap:                                    15px;
    align-items:                            center;
    flex:                                   0 0 70%;
}

.wantsMobileContainer .mobile-inputContainer {
    position:                               relative;
    margin-left:                            auto;
    flex:                                   0 0 70px;
}

.wantsMobileContainer .mobile-inputContainer .numberSelector {
    right: 5px;
    background-color:                       unset;
}

.wantsMobileContainer .mobile-inputContainer::after {
    content:                                "%";
    position:                               absolute;
    font-size:                              16px;
    width:                                  fit-content;
    height:                                 fit-content;
    top:                                    0;
    bottom:                                 0;
    right:                                  25px;
    margin:                                 auto 0;
    background:                             #000;
    background-clip:                        text;
    -webkit-background-clip:                text;
    -webkit-text-fill-color:                transparent;
}

.wantsMobileContainer .mobile-inputContainer > input {
    padding:                                10px;
    border-radius:                          10px;
    border:                                 1px solid rgba(0, 0, 0, 0.05);
    background:                             transparent;
    border-bottom:                          unset;
    transition:                             unset;
}



.wantsMobileContainer .mobile-inputContainer > input:focus {
    border-bottom:                          unset;
}

.wantsMobileContainer .accordion-collapse {
    transition:                             all 0.5s ease-in-out;
}

.wantsMobileContainer .accordion-body {
    padding:                                unset;
}

.wantsMobileContainer .mobile-cat-header {
    display:                                flex;
    width:                                  100%;
    justify-content:                        space-between;
    align-items:                            center;
    background:                             var(--grey);
    padding:                                20px;
}

.wantsMobileContainer .mobile-cat-header h5,
.wantsMobileContainer .mobile-var-section .mobile-var-title-info .mobile-var-title h5 {
    font-size:                              12px;
    font-weight:                            700;
}

.wantsMobileContainer .mobile-cat-header .mobile-weight-header {
    display:                                flex;
    align-items:                            center;
    gap:                                    20px;
    padding-right:                          20px;
}

.wantsMobileContainer .mobile-totalWeight {
    display:                                flex;
    align-items:                            center;
    justify-content:                        flex-end;
    gap:                                    25px;
    margin-right:                           90px;
    position:                               relative;
}

.wantsMobileContainer .mobile-totalWeight > h5 {
    font-weight:                            700;
}

.wantsMobileContainer .mobile-var-section {
    display:                                flex;
    align-items:                            flex-start;
    justify-content:                        space-between;
    padding:                                20px;
    border-bottom:                          1px solid rgba(0, 0, 0, 0.05);
}

.wantsMobileContainer .mobile-var-section .mobile-var-title-info {
    display:                                flex;
    position:                               relative;
    align-items:                            flex-start;
    gap:                                    15px;
    flex:                                   0 0 60%;
    padding-right:                          40px;
}

.wantsMobileContainer .mobile-var-section .mobile-var-title-info .informationContainer{
	width: 								    200%;
	box-shadow: 						    0px 15px 50px rgba(7, 27, 57, 0.15);
	background-color: 					    #FFFFFF;
	padding: 							    40px 35px;
	position: 							    absolute;
	top: 								    0;
	left: 								    -25px;
	z-index: 							    999;
	opacity: 							    0;
	visibility: 						    hidden;
	transition: 						    all 0.3s ease-in-out;
}

.wantsMobileContainer .mobile-var-section .mobile-var-title-info .informationContainer.show{
	visibility: 						    visible;
	opacity: 							    1;
}

.wantsMobileContainer .mobile-var-section .mobile-var-title-info .informationContainer > h5{
	font-weight: 						    700;
	margin-bottom: 						    25px;
}

.wantsMobileContainer .mobile-var-section .mobile-var-title-info .informationContainer > .rowInfo{
	display: 							    flex;
	align-items: 						    flex-start;
	gap: 								    10px;
	margin-bottom: 						    25px;
}

.wantsMobileContainer .mobile-var-section .mobile-var-title-info .informationContainer > .rowInfo:last-of-type{
	margin-bottom: 						    0;
}

.wantsMobileContainer .mobile-var-section .mobile-var-title-info .informationContainer > .rowInfo > img{
	width: 								    20px;
	height: 							    20px;
	object-fit: 						    contain;
	object-position: 					    center center;
}

.wantsMobileContainer .mobile-var-section .mobile-var-title-info .informationContainer > .rowInfo .title{
	font-weight: 						    500;
}

.wantsMobileContainer .mobile-var-section .mobile-var-title-info .informationContainer > .rowInfo .description{
	background-color: 					    var(--grey);
	font-size: 							    14px;
	color: 								    var(--darkBlue);
	opacity: 							    0.5;
	padding: 							    10px;
}

.wantsMobileContainer .mobile-var-section .mobile-var-weight {
    display:                                flex;
    align-items:                            center;
    flex:                                   0 0 100px;
    gap:                                    20px;
    padding-right:                          65px;
    position:                               relative;
}

.wantsMobileContainer .mobile-var-section .mobile-var-weight > span {
    font-size:                              14px;
    font-weight:                            400;
    width:                                  10px;
    margin-right:                           15px;
}

.wantsMobileContainer .mobile-var-section .mobile-var-title-info .mobile-var-title {
    display:                                flex;
    flex-direction:                         column;
    align-items:                            flex-start;
    gap:                                    10px;
}

.wantsMobileContainer .mobile-var-section .mobile-var-title-info .mobile-var-title > button {
    background:                             rgba(0, 0, 0, 0.05);
    border:                                 none;
    padding:                                5px 10px;
    font-size:                              12px;
}

.wantsMobileContainer .mobile-var-section .mobile-var-weight .mobile-inputContainer > input {
    border:                                 unset;
}

.wantsMobileContainer .mobile-var-section .mobile-var-weight .settingsVariable{
	width: 								    16px;
	height: 							    16px;
	object-fit: 						    contain;
	object-position: 					    center center;
	position: 							    absolute;
	top: 								    0;
	bottom: 							    0;
	margin:  							    auto 0;
	right: 								    -3px;
	cursor: 							    pointer;
}

.wantsMobileContainer .mobile-var-section .mobile-var-weight .mobile-deleteRow{
	width: 								    16px;
	height: 							    16px;
	object-fit: 						    contain;
	object-position: 					    center center;
	position: 							    absolute;
	top: 								    120%;
	margin:  							    auto 0;
	right: 								    -3px;
	cursor: 							    pointer;
}

.wantsMobileContainer .mobile-addAndTotal {
    padding: 							    20px 20px 40px 20px;
	display: 							    flex;
	align-items: 						    center;
    background-color:                       var(--grey);
}

.wantsMobileContainer .mobile-addAndTotal .mobile-addContainer {
    display:                                flex;
    justify-content:                        space-between;
    flex: 								    0 0 60%;
}

.wantsMobileContainer .mobile-addAndTotal .mobile-totalVarWeight {
    display:                                flex;
    flex:                                   0 0 130px;
    margin-left:                            auto;
    gap:                                    40px;
    position:                               relative;
}

.wantsMobileContainer .mobile-addAndTotal .mobile-totalVarWeight > span {
    font-weight: 						    700;
}

.wantsMobileContainer .mobile-addAndTotal .mobile-addContainer > button {
    font-size: 							    15px;
	padding: 							    5px 10px;
	border: 							    unset;
	background: 						    var(--gradient), var(--blue);
	background-clip: 					    text, padding-box;
	-webkit-background-clip: 			    text, padding-box;
	-webkit-text-fill-color: 			    transparent;
	display: 							    flex;
	align-items: 						    center;
}

.wantsMobileContainer .mobile-addAndTotal .mobile-addContainer > button::before {
    content: 							    "";
	width: 								    10px;
	height: 							    10px;
	background: 						    url("../../images/addAlone.svg") center center no-repeat;
	background-size: 					    10px 10px;
	margin-right: 						    5px;
}

.wantsMobileContainer .mobile-addAndTotal .mobile-totalVarWeight .mobile-dangerMessage,
.wantsMobileContainer .mobile-totalWeight .mobile-dangerMessage {
    width: 								    270px;
	height: 							    max-content;
	padding: 							    25px 30px;
	background-color: 					    #FFFFFF;
	box-shadow: 						    0px 15px 50px rgba(7, 27, 57, 0.15);
	display: 							    flex;
	gap: 								    10px;
	align-items: 						    flex-start;
	position: 							    absolute;
	top: 								    150%;
	margin: 							    auto 0;
	right: 								    35px;
	transition: 						    all 0.3s ease-in-out;
	z-index: 							    9;
	opacity: 							    0;
	visibility: 						    hidden;
}

.wantsMobileContainer .mobile-totalWeight .mobile-dangerMessage {
    bottom:                                 300%;
    top:                                    unset;
    right:                                  35px;
    width:                                  220px;
}

.wantsMobileContainer .mobile-addAndTotal .mobile-totalVarWeight .mobile-dangerMessage.show,
.wantsMobileContainer .mobile-totalWeight .mobile-dangerMessage.show {
    opacity: 							    1;
    visibility: 						    visible;
}

.wantsMobileContainer .mobile-addAndTotal .mobile-totalVarWeight .mobile-dangerMessage > img,
.wantsMobileContainer .mobile-totalWeight .mobile-dangerMessage > img {
    width: 								    20px;
	height: 							    20px;
	object-fit: 						    contain;
	object-position: 					    center center;
}

.wantsMobileContainer .mobile-addAndTotal .mobile-totalVarWeight .mobile-dangerMessage > p,
.wantsMobileContainer .mobile-totalWeight .mobile-dangerMessage > p {
    font-size: 							    14px;
	opacity: 							    0.6;
	font-weight: 						    400;
}
