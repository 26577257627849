.payroll-wrapper{
    width: 						100%;
    height:                     100%;
    overflow-y:                 auto;
    overflow-x:                 hidden;
   	position:                   absolute;
   	top:                        0;
   	left:                     	0;
    transform:                  translateX(100%);
   	background-color:           var(--grey);
   	z-index: 					4;
   	transition: 				all 1s ease;
    padding-top:                130px;
}

.payroll-wrapper.show{
    transform:                  translateX(0);	
    box-shadow:                 -10px 0px 30px 0px rgba(35, 107, 216, 0.5);
}

.payroll-wrapper .head-section{
    padding-top:                         55px;
    display:                             flex;
    justify-content:                     space-between;
    align-items:                         center;
    margin-bottom:                       50px;
    position:                            relative;
}

.payroll-wrapper .head-section .title{
    display:                             flex;
    gap:                                 10px;
    align-items:                         center;
    position:                            relative;
}

.payroll-wrapper .head-section .title .back{
    width:                                 25px;
    height:                                25px;
    mask:                                  url("../../images/expand_less.svg") center center no-repeat;
    background:                            var(--gradient);
    transform:                             rotate(270deg);
    border:                                none;
}

.payroll-wrapper .head-section .title .back:hover{
    background:                          #041A39;
}

.payroll-wrapper .head-section  .change-sub{
    border:                                 none;
    background:                             none;
    display:                                flex;
}

.payroll-wrapper .head-section  .change-sub::after{
    content:                                  "";
    display:                                block;
    width:                                  25px;
    height:                                 25px;
    mask:                                   url("../../images/expand_less.svg") center center no-repeat;
    transform:                               rotate(180deg);
    transition:                              transform 0.2s ease;
    background:                             var(--gradient);
}

.payroll-wrapper .head-section  .change-sub >span{
    background:                              var(--gradient);
    background-clip:                         text;
    -webkit-background-clip:                 text;
    -webkit-text-fill-color:                 transparent;
}

.payroll-wrapper .head-section  .change-sub.active::after
{
    background:                             #041A39;
    transform:                               rotate(0deg);
}


.payroll-wrapper .head-section  .change-sub.active > span{
    background:                             #041A39;
    background-clip:                         text;
    -webkit-background-clip:                 text;
    -webkit-text-fill-color:                 transparent;
}


.payroll-wrapper .nav.nav-tabs{
    gap:                                     10px;
}

.payroll-wrapper .nav-tabs .nav-link{
    background:                             #FFF;
    padding:                                  15px 40px;
    color:                                  black;
    border-radius:                              0;   
    display:                                     flex;
    gap:                                        8px; 
    align-items:                                center;

}

.payroll-wrapper .nav-tabs .nav-link.active{
    background-color:                       #041A39;
    color:                                   #FFF;
}


.payroll-wrapper .head-section .title .option-selector{
    position:                                        absolute;
    flex-direction:                                  column;
    display:                                         flex;
    left:                                            52%;
    top:                                             100%;
    box-shadow:                                      0px 15px 50px 0px rgba(7, 27, 57, 0.15);
    width:                                           max-content;
    opacity:                                         0;
    visibility:                                      hidden;
    transition:                                      opacity 0.2s ease;
}


.payroll-wrapper .head-section .title .option-selector.show{
    opacity:                                          0.98;
    visibility:                                       visible;
}

.payroll-wrapper .head-section .title .option-selector .option {
    border:                                            none;
    flex:                                               1 0 0;
    padding:                                            15px 20px;
    text-align:                                         left;
    background:                                       #FFF;
}

.payroll-wrapper .head-section .title .option-selector .option:hover,
.payroll-wrapper .head-section .title .option-selector .option.selected
{
    color:                                           #009AFF;
    background-color:                                var(--grey);
}
.payroll-wrapper .head-section .title .option-selector .option.selected {
    cursor:                                             auto;
}

.payroll-wrapper .head-section .city-menu{
    right:                                               0;
    top:                                                 80px;
    background-color:                                   #FFF;
    position:                                            absolute;
    flex-direction:                                      column;
    display:                                            flex;
    box-shadow:                                         0px 15px 50px 0px rgba(7, 27, 57, 0.15);
    width:                                              max-content;
    opacity:                                            0;
    visibility:                                         hidden;
    transition:                                         opacity 0.2s ease;
}

.payroll-wrapper .head-section .city-menu.show{
    visibility:                                         visible;
    opacity:                                             1;
    z-index:                                             999;
}

.payroll-wrapper .head-section .city-menu .option{
    border:                                              none;
    text-align:                                          center;
    padding:                                             15px 30px 15px 20px;
    background:                                        #FFF;
    text-align:                                          left;
}

.payroll-wrapper .head-section .city-menu .option.selected,
.payroll-wrapper .head-section .city-menu .option:hover{
    background:                                         var(--grey);
    color:                                             #009AFF;
}



.payroll-wrapper .position-col{
    display:                                            flex;
    padding:                                            25px;
    gap:                                                15px;
	border-bottom:                                      1px solid rgba(0, 0, 0, 0.05);
    align-items:                                        center;
    height: 90px;
}


.payroll-wrapper .no-gutters > [class*='col-'] {
	padding-right:                                      0;
	padding-left:                                       0;
    margin-bottom:                                      30px;
  }

.payroll-wrapper .table-heading{
    display:                                            flex;
    height:                                             70px;
}

.payroll-wrapper .gray-cont{
    display:                                             flex;
    padding:                                             25px;
    min-width:                                           33%;
    background:                                         #FFF;
    gap:                                                  5px;

}

.payroll-wrapper .gray-cont:first-child{
  justify-content: center;

}

.payroll-wrapper .gray-cont .gray-txt{
    opacity:                                               0.6;
}

.payroll-wrapper .table-title{
    padding:                                               25px;
    min-width:                                             33%;
    background:                                          #FFF;
}
.payroll-wrapper .table-title.blue{
    color:                                              #009AFF;
}

.payroll-wrapper .table-container{
    overflow-x:                                             auto;

}
.payroll-wrapper .position-text{
    max-width:                                              100%;
}

.payroll-wrapper .first-spacer{
    height:                                                 70px;
    padding:                                                25px;
    background-color:                                       #FFF;
}

.payroll-wrapper .table-row{
    display:                                                 flex;
}

.payroll-wrapper .data{
    padding:                                                  25px;
    min-width:                                                33%;
    height:                                                   90px;
	border-bottom:                                      1px solid rgba(0, 0, 0, 0.05);
    line-height: 2;

}

.payroll-wrapper .data:first-child{
    text-align:                                                center;

}

