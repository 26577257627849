
.teaserInputsSection .infoRequestedSection {
    display:                            flex;
    flex-direction:                     column;
}

.teaserInputsSection .infoRequestedSection .spanSection {
    display:                            flex;
    align-items:                        flex-end;
    flex-direction:                     row;
    margin-top:                         35px;
    margin-bottom:                      10px;
}

.teaserInputsSection .infoRequestedSection .spanSection .inputsSubTitle {
    font-size:                          16px;
    font-weight:                        500;
}

.teaserInputsSection .infoRequestedSection .spanSection > button {
    margin-left:                        10px;
    padding:                            15px 20px;
    height:                             24px;
    width:                              32px;
}

.teaserInputsSection .fieldDescriptionSection .fieldImage {
    width:                              32px;
    height:                             32px;
    object-fit:                         contain;
    object-position:                    center center;
    margin-bottom:                      10px;
}

.teaserInputsSection .fieldDescriptionSection .inputsTitle {
    font-size:                          23px;
    font-weight:                        700;
    margin-bottom:                      10px;
}

.teaserInputsSection .fieldDescriptionSection .fieldDescription {
    font-size:                          16px;
    font-weight:                        400;
    opacity:                            0.5;
    margin-bottom:                      25px;
}