
.wants-view{
	width: 						100%;
    height:                     100%;
    overflow-y:                 auto;
    overflow-x:                 hidden;
    padding-bottom: 100px;
    position:                   absolute;
    top:                        0;
    left:                     	0;
	transform: 					translateX(100%);
    background-color:           var(--grey);
    z-index: 					3;
    transition: 				all 1s ease;
}

.wants-view.show{
	transform: 					translateX(0);
	box-shadow:                 -30px 0px 30px 0px rgba(35, 107, 216, 0.5);
}

.wants-view .header-section{
    display:                    flex;
    align-items:                center;
    padding:                    0 15px 0 40px;
    background-color: 			#F5F6F7;
    height: 					60px;
    transition: 				background-color 0.3s ease;
}

.wants-view .header-sticky{
    position:                   sticky;
    top:                        0;
    padding-top:                160px;
    z-index:                    4;
    background-color:           var(--grey);
}

.wants-view .header-sticky .openClose-comparebox-container {
    display:                    flex;
    justify-content:            flex-end;
    padding-inline:             10px;
}

.wants-view .header-sticky .openClose-mobile {
    display:                    flex;
    gap:                        10px;
}

.wants-view .header-section .criterion-wrapper{
    display:                    flex;
    gap:                        20px;
    flex:                       0 0 40%;
}

.wants-view .header-section .criterion-wrapper > span {
    font-weight:                700;
}

.wants-view .header-section .criterion-wrapper > button,
.wants-view .header-sticky .openClose-mobile > button {
    background:                 unset;
    border:                     unset;
    font-size:                  14px;
    padding:                    0;
    display:                    flex;
    align-items:                center;
}

.wants-view .header-section .criterion-wrapper > button:first-of-type::before,
.wants-view .header-sticky .openClose-mobile > button:first-of-type::before{
    content:                     "";
    width:                       25px;
    height:                      25px;
    background:                  url("../../../images/expand_all.svg") center center no-repeat;
    background-size:             25px 25px;
    margin-right:                5px;
}

.wants-view .header-section .criterion-wrapper > button:last-of-type::before,
.wants-view .header-sticky .openClose-mobile > button:last-of-type::before{
    content:                     "";
    width:                       25px;
    height:                      25px;
    background:                  url("../../../images/collapse_all.svg") center center no-repeat;
    background-size:             25px 25px;
    margin-right:                5px;
}

.wants-view .header-sticky .openClose-mobile > button {
    background:                 var(--blue);
    padding:                    2px;
}

.wants-view .header-sticky .openClose-mobile > button:first-of-type::before,
.wants-view .header-sticky .openClose-mobile > button:last-of-type::before {
    margin-right:               unset;
}

.wants-view .header-section .btn-header{
    background:                 unset;
    border:                     unset;
    font-weight:                700;
    text-align:                 center;
    flex:                       1 0 0;
    cursor:                     auto;
}
.wants-view .header-section .btn-header:last-of-type{
    text-align:                 center;
}
.wants-view .accordion-item{
    border:                     unset;
    margin-bottom: 				15px;
}


.wants-view .accordion-item .totals-row{
    display:                    flex;
    justify-content:            space-evenly;
    max-height:                 0;
    visibility:                 hidden;
    background-color:           #F5F6F7;
    padding-left:               37%;
    height:                     70px;
    align-items:                center;
    border:                     1px solid rgba(7, 27, 57, 0.05);
}

.wants-view .accordion-item .totals-row.edit-mode{
    padding-left:               37.5%;
}

.wants-view .accordion-item .totals-row.show{
    font-weight:                900;
    max-height:                 1000px;
    visibility:                 visible;
    
}

.wants-view .accordion-item .totals-row.show >h5:first-child{
    font-weight:                900;
    padding-left:               43px;
}
.wants-view .accordion-header{
    border-radius:              0;
    background:                 #FFF;
    box-shadow:                 0px 4px 30px 0px rgba(35, 107, 216, 0.05);
}

.wants-view .accordion-header .accordion-button{
    padding:                    30px 50px;
    display:                    flex;
    align-items:                center;
    position:                   relative;
}

.wants-view .accordion-header .accordion-button::after{
    background-image:           url("../../../images/arrowDown.svg");
    position:                   absolute;
    right:                      30px;
}

.wants-view .accordion-header .accordion-button:focus{
    box-shadow:                 unset;
}

.wants-view .accordion-header .accordion-button:not(.collapsed){
    background-color:           #FFF;
}

.wants-view .accordion-header .accordion-button .button-title{
    color:                     var(--darkBlue);
    font-weight:               700;
    opacity:                   0.6;
    flex:                      0 0 40%;
}

.wants-view .accordion-header .accordion-button .button-title.edit-mode{
    flex:                      0 0 40.5%;
}

.wants-view .accordion-header .accordion-button .points-weight{
    color:                     var(--darkBlue);
    text-align:                center;
    font-weight:               700;
    opacity:                   0.6;
    flex:                      1 0 20px;
}

.wants-view .accordion-header .accordion-button .city-score-highlight{
    color:                     var(--darkBlue);
    text-align:                center;
    font-weight:               900;
    flex:                      1 0 0;
}

.wants-view .inputContainer {
    flex:                       1;
    margin-bottom:              unset;
    position:                   relative
}

.wants-view .edit-mode-input {
    background:                 rgba(0, 0, 0, 0.03);
    text-align:                 center;
}

.wants-view .numberSelector {
    right:                      5px;
    background-color:           unset;
}


.wants-view .accordion-body{
    padding: 					0;
    background: 				var(--bg_grey, #F5F6F7);
}

.wants-view .accordion-body .subcriteria-row{
    display:                    flex;
    padding: 					25px 0px 20px 50px ;
    align-items:                center;
    flex:                       1 0 0;
    border:                    	1px solid rgba(7, 27, 57, 0.05);
}



.wants-view .accordion-body .subcriteria-row .criteria-description{
    display:                   flex;
    gap:                       10px;
    flex:                      0 0 38.5%;
    position:                  relative;
    align-items:               center;
}

.wants-view .accordion-body .subcriteria-row .criteria-description.edit-mode{
    flex:                      0 0 39%;
}

.wants-view .accordion-body .subcriteria-row .criteria-description > button{
    background:                 unset;
    border:                     unset;
    font-size:                  14px;
    padding:                    0;
    display:                    flex;
    align-items:                center;
}


.wants-view .accordion-body .subcriteria-row .criteria-description .info-icon{
    width:                       25px;
    height:                      25px;
    opacity:                     0.6;
    cursor:                      pointer;
}

.wants-view .accordion-body .subcriteria-row .criteria-description > h5 {
    font-weight:                 700;
    line-height:                 normal;
}


.wants-view .accordion-body .subcriteria-row .criteria-description > button:last-of-type{
    padding:                    3px 15px;
    justify-content:            center;
    align-items:                center;
    gap:                        10px;
    background:         	#0000000D 5%;
    width:                      85px;
    height:                     30px;
}

.wants-view .accordion-body .subcriteria-row .criteria-description > button:last-of-type:hover{
    background:                 var(--darkBlue);
    color:                     #FFF;
}

.wants-view .accordion-body .subcriteria-row .criteria-row-data{
    display:                    flex;
    align-items:                center;
    flex:                       1 0 0;
    justify-content:            center;
    
}

.wants-view .accordion-body .subcriteria-row .criteria-row-data{
    text-align:                 center;
    font-weight:                400;
    opacity:                    0.6;
}


.wants-view .accordion-body .details-button{
    border:                       unset;
    display:                      flex;
    padding:                      4px 6px;
    align-items:                  center;
    height:                       auto;
    width:                        55px;
    background:                 rgba(0, 163, 255, 0.10);
    margin-right:                 10px;
    transition: 				background ease 0.3s;
}

.wants-view .accordion-body .details-button:hover{
    background:                 var(--darkBlue);
    cursor:                     pointer;            
}


.wants-view .accordion-body .details-button > img{
    width:                       15px;
    height:                      15px;
    background-size:             15px 15px;
    margin-left:                 2px;
}

.wants-view .accordion-body .details-button > h5{
    font-size:                  10px;
    font-weight:                400;
    background: 				var(--gradient, linear-gradient(180deg, #009AFF 19.79%, #8CA5FF 100%));
    background-clip: 			text;
    -webkit-background-clip: 	text;
    -webkit-text-fill-color: 	transparent;
}

.mobile-results-table {
    display:                    block;
    white-space:                nowrap;
    overflow-x:                 auto;
    scrollbar-width:            none;
    background-image:           linear-gradient(to right, var(--grey), var(--grey)),
                                linear-gradient(to right, var(--grey), var(--grey)),
                                linear-gradient(to right, rgba(188, 190, 200, 0.5),rgba(245, 246, 247, 0.5)),
                                linear-gradient(to left, rgba(188, 190, 200, 0.5), rgba(245, 246, 247, 0.5));
    background-position:        left center, right center, left center, right center;
    background-repeat:          no-repeat;
    background-size:            40px 100%, 40px 100%, 40px 100%, 40px 100%;
    background-attachment:      local, local, scroll, scroll;
}

.mobile-results-table thead,
.total-table-container thead{
    background:                 rgba(255, 255, 255, 0.7);
    box-shadow:                 0px 5px 30px 0px rgba(35, 107, 216, 0.08);
}

.mobile-results-table thead th,
.mobile-results-table tbody tr td,
.total-table-container thead th,
.total-table-container tbody tr td {
    padding:                    20px;
    font-size:                  12px;
}

.mobile-results-table thead th .var-title {
    font-size:                  12px;
    display:                    inline;
    margin-left:                5px;
}

.mobile-results-table thead th .info-icon {
    width:                      20px;
    height:                     20px;
    opacity:                    0.6;
    display:                    inline;
}

.mobile-results-table thead th .title-criteria-container {
    display:                    flex;
    gap:                        5px;
    margin-top:                 5px;
    align-items:                flex-start;
}

.wants-view .mobile-results-table thead th .mobile-details-button {
    width:                      auto;
    margin-right:               unset;
    padding:                    4px 5px;
}

.wants-view .mobile-results-table thead th .mobile-details-button > img {
    width:                      16px;
    height:                     16px;
}

.mobile-results-table thead th .criteria-button {
    padding:                    3px 12px;
    background:         	    #0000000D;
    border:                     none;
    font-size:                  12px;
}

.mobile-results-table tbody tr,
.total-table-container tbody tr {
    border-bottom:              1px solid rgba(7, 27, 57, 0.05);
}

.mobile-results-table thead tr th:first-child,
.mobile-results-table tbody tr td:first-child,
.total-table-container tbody tr td:first-child {
    white-space:                wrap;
    font-weight:                700;
}

.mobile-results-table tbody tr td:not(:first-child) {
    text-align:                 center;
    opacity:                    .6;
}

.mobile-results-table thead tr th:last-child,
.mobile-results-table tbody tr td:last-child,
.total-table-container tbody tr td:last-child {
    font-weight:                700;
    opacity:                    1;
}

.wants-view .total-title {
    padding-right:              20px;
    font-size:                  14px;
    font-weight:                700;
    flex:                       0 0 40%;
}

.wants-view .total-points {
    flex:                       1 0 20px;
    text-align:                 center;
    font-weight:                700;
}

.wants-view .total-table-container table {
    width:                      100%;
}

.wants-view .total-table-container tr th:last-child,
.wants-view .total-table-container tr td:last-child {
    text-align:                 right;
}

.wants-view .accordion-body .criteria-description .info-tooltip,
.mobile-results-table thead th .info-tooltip {
    display:                    flex;
    flex-direction:             column;
    position:                   absolute;
    background-color:          rgba(255,255,255); /* Background color for the tooltip */
    color:                     black; /* Text color for the tooltip */
    box-shadow:                 0 2px 4px rgba(0, 0, 0, 0.2);
    top:                        30px;
    padding:                    20px 15px 20px 20px;
    z-index:                    9999;
    opacity:                    0;
    visibility:                 hidden;
    transition:                 all 0.3s ease-in-out;
}

.wants-view .accordion-body .criteria-description .info-tooltip.activa,
.mobile-results-table thead th .info-tooltip.activa {  
    opacity:                    0.96;
    visibility:                 visible;
}

.wants-view .accordion-body .criteria-description .info-tooltip > h5,
.mobile-results-table thead th .info-tooltip > h5 {
    font-weight:                700;
    flex:                       1 0 0;

}

.wants-view .accordion-body .criteria-description .info-tooltip .definition-icon,
.mobile-results-table thead th .info-tooltip .definition-icon {
    border:                      unset;
    width:                       24px;
    height:                      24px;
    background:                  url("../../../images/history_edu.svg") center center no-repeat;
    background-size:             24px 24px;
    margin-left:                 6px;
    flex:                        0 0 24px;
}
.wants-view .accordion-body .criteria-description .info-tooltip .metric-icon,
.mobile-results-table thead th .info-tooltip .metric-icon {
    border:                      unset;
    width:                       24px;
    height:                      24px;
    background:                  url("../../../images/analytics.svg") center center no-repeat;
    background-size:             24px 24px;
    margin-left:                 6px;
}
.wants-view .accordion-body .criteria-description .info-tooltip .source-icon,
.mobile-results-table thead th .info-tooltip .source-icon {
    border:                      unset;
    width:                       24px;
    height:                      24px;
    background:                  url("../../../images/description.svg") center center no-repeat;
    background-size:             24px 24px;
    margin-left:                 6px;
}

.wants-view .accordion-body .criteria-description .info-tooltip .tooltip-title,
.mobile-results-table thead th .info-tooltip .tooltip-title {
    font-weight:                500;
    display:                    block;
    position:                   relative;
}

.wants-view .accordion-body .criteria-description .info-tooltip .text-column,
.mobile-results-table thead th .info-tooltip .text-column {
    font-weight:                400;
    color:                      var(--darkBlue);
    opacity:                    0.5;
    font-size:                  14px;
    display:                    block;
    position:                   relative;
    text-wrap:                  wrap;
}

.wants-view .accordion-body .criteria-description .info-tooltip .tooltip-row,
.mobile-results-table thead th .info-tooltip .tooltip-row {
    display:                    flex;
    gap:                        10px;
    width:                      100%;
    margin-top:                 12px;
    
}

.wants-view .accordion-body .criteria-description .criteria-tooltip{
    display:                    none;
    flex-direction:             column;
    background-color:          rgba(255,255,255); /* Background color for the tooltip */
    color:                     black; /* Text color for the tooltip */
    box-shadow:                 0 2px 4px rgba(0, 0, 0, 0.2);
    top:                        40px;
    left:                       180px;
    padding:                    20px;
    position:                   absolute;
    width:                      300px;
    z-index:                    9999;
}

.wants-view .accordion-body .criteria-description .criteria-tooltip.extended{
    width:                      600px;
}

.wants-view .accordion-body .criteria-description .criteria-tooltip .criteria-tooltip-header {
    display:                    flex;
    justify-content:            space-between;
    margin-bottom:              10px;
}

.wants-view .accordion-body .criteria-description .criteria-tooltip.extended .criteria-tooltip-row{
    height:                     70px;
}

.wants-view .accordion-body .criteria-description .criteria-tooltip.extended .criteria-tooltip-row > h5:first-of-type{
    margin-left:                4px;
    font-weight:                500;
    opacity:                    0.6;
    flex:                       0 0 80%;
    padding:                    5px;
}


.wants-view .accordion-body .criteria-description .criteria-tooltip.activa{
    display:                    flex;
    max-height:                 300px;

}

.wants-view .accordion-body .criteria-description .criteria-tooltip > h5{
    font-weight:                700;
    margin-bottom:              5px;
}

.wants-view .accordion-body .criteria-description .criteria-tooltip .criteria-tooltip-row{
    list-style-type:            none;
    display:                    flex;
    justify-content:            space-between;
    border-bottom:              1px solid rgba(7, 27, 57, 0.05);
    height:                     40px;
    align-items:                center;
    padding:                    0px 0px 10px;

}

.wants-view .accordion-body .criteria-description .criteria-tooltip .criteria-tooltip-row > h5:first-of-type{
    margin-left:                4px;
    font-weight:                500;
    opacity:                    0.6;
    flex:                       0 0 42%;
}



.wants-view .totals-row {
    display:                    flex;
    padding:                    30px 50px;
    position:                   relative;
}

.wants-view .totals-row .dangerMessage {
    width: 								25%;
	height: 							max-content;
	padding: 							25px 30px;
	background-color: 					#FFFFFF;
	box-shadow: 						0px 15px 50px rgba(7, 27, 57, 0.15);
	display: 							flex;
	gap: 								10px;
	align-items: 						flex-start;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
	left: 								62%;
	transition: 						all 0.3s ease-in-out;
	z-index: 							999;
	opacity: 							0;
	visibility: 						hidden;
}

.wants-view .totals-row .dangerMessage.show {
    opacity: 							1;
	visibility: 						visible;
}

.wants-view .totals-row .dangerMessage::before {
    content: 							"";
	width: 								0;
	height: 							0;
	border-top: 						15px solid transparent;
	border-bottom: 						15px solid transparent;
	border-right: 						15px solid #FFFFFF;
	position: 							absolute;
	right: 								100%;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
}

.wants-view .totals-row .dangerMessage > img {
    width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
}

.wants-view .totals-row .dangerMessage > p {
    font-size: 							14px;
	opacity: 							0.6;
	font-weight: 						400;
}

/* Mobile danger message */
.wants-view .mobile-results-table .tooltip-row .dangerMessage {
	height: 							max-content;
	padding: 							10px 20px;
	background-color: 					#FFFFFF;
	box-shadow: 						0px 15px 50px rgba(7, 27, 57, 0.15);
	display: 							flex;
	gap: 								10px;
	align-items: 						flex-start;
	transition: 						all 0.3s ease-in-out;
	z-index: 							999;
	opacity: 							0;
	visibility: 						hidden;
}

.wants-view .mobile-results-table .tooltip-row .dangerMessage.show {
    opacity: 							1;
	visibility: 						visible;
}

.wants-view .mobile-results-table .tooltip-row .dangerMessage::before {
    content: 							"";
	width: 								0;
	height: 							0;
	border-top: 						15px solid transparent;
	border-bottom: 						15px solid transparent;
	border-right: 						15px solid #FFFFFF;
	position: 							absolute;
	right: 								100%;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
}

.wants-view .mobile-results-table .tooltip-row .dangerMessage > img {
    width: 								15px;
	height: 							15px;
	object-fit: 						contain;
	object-position: 					center center;
}

.wants-view .mobile-results-table .tooltip-row .dangerMessage > p {
    white-space:                        pretty;
    font-size: 							10px;
	opacity: 							0.6;
	font-weight: 						400;
}

.wants-view .totals-row > h5{
    flex:                               1 0 0;
    text-align:                         center;
    font-weight:                        700;
}

.wants-view .totals-row > div:first-of-type{
    flex:                               0 0 40%;
}

.wantsViewCriteriaModal .dropdown.disabled {
    cursor:                             not-allowed;
}

.wantsViewCriteriaModal .btn:disabled {
    color:                              rgba(0, 0, 0, 0.5);
}

.wantsViewCriteriaModal .modal-body .criteriaPoints .criterias > .inputContainer > input:disabled {
    color:                              rgba(0, 0, 0, 0.5);
    cursor:                             not-allowed;
}

@media screen and (width <= 991px) {
    .wants-view .header-sticky .openClose-comparebox-container {
        justify-content:                space-between;
    }
    
    .wants-view .header-section {
        padding-left:                   20px;
    }

    .wants-view .accordion-header.active .accordion-button:not(.collapsed){
        background:                 var(--darkBlue);
    }

    .wants-view .accordion-header.active .accordion-button:not(.collapsed) .button-title,
    .wants-view .accordion-header.active .accordion-button:not(.collapsed) .points-weight {
        color:                      #fff;
        opacity:                    1;
    }

    /* .wants-view .accordion-header.active .accordion-button:not(.collapsed)::after {

    } */

    /* .wants-view .header-section .criterion-wrapper {
        flex:                           0 0 50%;
    } */

    .wants-view .accordion-header .accordion-button {
        padding:                        30px 20px;
    }

    /* .wants-view .header-section .btn-header {
        white-space:                    nowrap;
    } */

    .wants-view .accordion-header .accordion-button .button-title {
        flex:                           0 0 40%;
        padding-right:                  20px;
        font-size:                      14px;
        font-weight:                    700;
        opacity:                        1;
    }

    .wants-view .edit-mode-input {
        background:                 rgba(255, 255, 255, 1);
        text-align:                 center;
    }

    /* .wants-view .accordion-header .accordion-button .points-weight {
        flex:                           0 0 25%;
    } */

    .mobile-results-table thead th .info-tooltip {
        position:                       absolute;
        top:                            50%;
        left:                           50%;
        transform:                      translate(-50%, -50%);
        width:                          90%;
    }

    .wants-view .accordion-header .accordion-button::after {
        right:                          10px;
    }

    /* .wants-view .accordion-header .accordion-button .button-title.edit-mode {
        flex:                           0 0 32%;
    }

    .wants-view .inputContainer {
        flex:                           0 0 20%;
    }

    .wants-view .edit-mode-input {
        padding-left:                  10px;
    } */
}