@media screen and (width <= 991px) {
	.containerSettings{
		width: 								330px;
		height:                             60%;
		background-color: 					#FFFFFF;
		box-shadow: 						0px 15px 50px 0px rgba(7, 27, 57, 0.15);
		padding: 							40px 20px;
		position: 							fixed;
		top:                                15%;
		bottom: 							0;
		left: 								50%;
		transform:                          translateX(-50%);
		visibility: 						hidden;
		opacity: 							0;
		transition: 						all 0.3s ease-in-out;
		z-index:                            100;
		overflow-y:                         auto;
	}

	.containerSettings .titleAndType .currencyButtonContainer {
		display: 							flex;
		gap: 								5px;
	}

	.containerSettings .titleAndType .currencyButtonContainer > button {
		font-size: 							14px;
		padding-inline: 					10px;
		border: 							none;
		background: 						var(--gradient);
		background-clip: 					text;
		-webkit-background-clip: 			text;
		-webkit-text-fill-color: 			transparent;
	}

	.containerSettings .titleAndType .currencyButtonContainer > button.selected {
		border: 							none;
		background: 						var(--gradient), var(--blue);
		background-clip: 					text, padding-box;
		-webkit-background-clip: 			text, padding-box;
		-webkit-text-fill-color: 			transparent;
	}
	
	.containerSettings .mobile-settings-close {
		position: 							absolute;
		width:                              12px;
		height:                             12px;
		object-fit:                         contain;
		top: 								15px;
		right: 								15px;
		cursor: 							pointer;
	}
	
	.containerSettings table {
		display:                            block;
		white-space:                        nowrap;
		overflow-x:                         auto;
	}
	
	/* .payrollSettings,
	.unionSettings{
		width: 								max-content;
		padding: 							30px;
	} */
	
	.payrollSettings .popData,
	.unionSettings .popData{
		margin-bottom: 						15px;
	}

	.unionSettings .popData{
		overflow-x:							auto;
	}

	.unionSettings .popData span {
		font-size: 							12px;
	}

	.payrollSettings .popData th,
	.unionSettings .popData th{
		font-size: 							14px;
		padding: 							0 3px;
	}
	
	.payrollSettings .popData td,
	.unionSettings .popData td{
		font-size: 							12px;
		padding: 							3px;
	}
	
	.payrollSettings .popData td:first-of-type,
	.unionSettings .popData td:first-of-type{
		padding-right: 						15px;
	}
	
	.payrollSettings .popData td input,
	.unionSettings .popData td input,
	.payrollSettings .popData td span,
	.unionSettings .popData td span{
		font-size: 							12px;
		padding:                            10px;
	}
	
	.wantsMobileContainer .tab-content  .popData.distance{
		height: 							200px;
		overflow-y: 						auto;
	}
	
	.unionSettings{
		padding: 							30px;
	}
	
	.unionSettings .tablePopDataContainer {
		display: 							flex;
		flex-flow: 							column nowrap;
		white-space: 						nowrap;
		overflow:                           auto;
		min-width: 							500px;
	}
	
	.unionSettings .tablePopDataContainer .tableTheadContainer,
	.unionSettings .tablePopDataContainer .tableTotalContainer {
		padding-right: 8px;
	}

	.unionSettings .tablePopDataContainer .tableTheadContainer,
	.unionSettings .tablePopDataContainer .tableTotalContainer,
	.unionSettings .tablePopDataContainer .tableBodyContainer .tableRowContainer {
		display: 							grid;
		grid-template-columns: 				minmax(110px, 2fr) repeat(auto-fit, minmax(70px, 1fr));
		column-gap: 						5px;
		margin-bottom: 						10px;
	}

	/* .unionSettings .tablePopDataContainer .tableTheadContainer,
	.unionSettings .tablePopDataContainer .tableTotalContainer {
		display: 							flex;
		flex-flow: 							row nowrap;
		gap: 								5px;
		margin-bottom: 						10px;
	} */
	
	.unionSettings .tablePopDataContainer .tableTotalContainer > span {
		padding: 							5px;
		background: 						var(--gradient), linear-gradient(180deg, #009AFF1A 10%, #8CA5FF1A 100%);
		background-clip: 					text, padding-box;
		-webkit-background-clip: 			text, padding-box;
		-webkit-text-fill-color: 			transparent;
	}
	
	.unionSettings .tablePopDataContainer .tableTheadContainer > span,
	.unionSettings .tablePopDataContainer .tableTotalContainer > span {
		overflow:                           hidden;
		text-overflow:                      ellipsis;
		/* width: 								25%; */
		font-weight: 						500;
	}
	
	.unionSettings .tablePopDataContainer .tableTheadContainer > span:not(:first-of-type),
	.unionSettings .tablePopDataContainer .tableTotalContainer > span:not(:first-of-type) {
		text-align: 						center;
	}
	
	.unionSettings .tablePopDataContainer .tableBodyContainer {
		display: 							flex;
		flex-flow: 							column nowrap;
		margin-bottom: 						10px;
		max-height: 						22vh;
		overflow-y: 						auto;
	}
	
	/* .unionSettings .tablePopDataContainer .tableBodyContainer .tableRowContainer {
		display: 							flex;
		flex-direction:						row;
		gap: 								5px;
		margin-bottom: 						5px;
	} */
	
	.unionSettings .tablePopDataContainer .tableBodyContainer .tableRowContainer > span {
		font-weight: 						500;
		text-overflow: 						ellipsis;
		overflow: 							hidden;
		align-self: 						center;
		/* flex:                               0 0 20%; */
	}
	
	.unionSettings .tablePopDataContainer .tableBodyContainer .tableRowContainer > span,
	.unionSettings .tablePopDataContainer .tableBodyContainer .tableRowContainer > input {
		/* text-wrap: 							pretty; */
		/* width: 								25%; */
		height: 							inherit;
	}
	
	.unionSettings .tablePopDataContainer .tableBodyContainer .tableRowContainer > input {
		padding:                            10px;
		text-align:                         center;
	}
	
	.costOfLivingSettings .popData th:not(:first-of-type) {
		width: 300px;
		text-align: 						center;
		white-space: 						nowrap;
	}
	
	.costOfLivingSettings .popData td:first-of-type {
		font-size: 							12px;
		font-weight: 						500;
		width: 								90px;
		padding-right: 						25px;
	}
	
	.costOfLivingSettings .popData td:not(:first-of-type) {
		min-width: 							150px;
	}
	
	.payrollSettings .popData tbody input {
		text-align: 						center;
	}
	
	.containerSettings.show{
		visibility: 						visible;
		opacity: 							1;
	}
	
	.containerSettings .titleAndType{
		display: 							flex;
		align-items: 						center;
		justify-content: 					space-between;
		margin-bottom: 						15px;
		gap: 								15px;
	}
	
	.containerSettings h5{
		font-weight: 						700;
		margin-bottom: 						5px;
	}
	
	.containerSettings .titleAndType .dropdown-toggle{
		font-size: 							14px;
		padding: 							0 20px 0 0;
		background: 						var(--gradient);
		background-clip: 					text;
		-webkit-background-clip: 			text;
		-webkit-text-fill-color: 			transparent;
		border-bottom: 						unset;
	}
	
	.containerSettings .titleAndType .dropdown-toggle::after{
		right: 								0;
	}
	
	.containerSettings .payrollFile{
		margin-bottom: 						30px;
	}
	
	.containerSettings .payrollFile input[type="file"]{
		background-color:                   transparent !important;
		padding:                            0 !important;
	}
	
	.containerSettings .payrollFile input[type="file"]::file-selector-button{
		padding:                            10px 15px;
		border:                             unset;
		background:                         var(--gradient), var(--blue);
		background-clip:                    text, padding-box;
		-webkit-background-clip:            text, padding-box;
		-webkit-text-fill-color:            transparent;
		cursor:                             pointer;
		transition:                         all 0.3s ease-in-out;
	}
	
	.containerSettings .payrollFile input[type="file"]::file-selector-button:hover{
		background:                         var(--gradient), var(--darkBlue);
		background-clip:                    text, padding-box;
		-webkit-background-clip:            text, padding-box;
	}
	
	.containerSettings .payrollFile .inputFileContainer{
		display:                            flex;
		justify-content:                    space-between;
		padding-right:                      15px;
		align-items:                        center;
		margin-bottom:                      25px;
	}
	
	.containerSettings .payrollFile .inputFileContainer > img{
		cursor: 							pointer;
	}
	
	.containerSettings .payrollFile .inputFileContainer .selectFileContainer {
		display:                            flex;
		align-items:                        center;
		gap:                                10px;
		white-space:                        nowrap;
		overflow:                           hidden;
	}
	
	.containerSettings .payrollFile .inputFileContainer .selectFileContainer > button {
		height:                             40px;
	}
	
	.containerSettings .payrollFile .inputFileContainer .selectFileContainer > span {
		overflow:                           hidden;
		text-overflow:                      ellipsis;
	}
	
	.containerSettings .cities{
		display: 							flex;
		flex-flow: 							row wrap;
		gap: 								20px;
		margin-bottom: 						20px;
	}
	
	.containerSettings .cities .inputContainer{
		flex:  								0 0 100%;
		border: 							unset;
	}
	
	.containerSettings .cities .inputContainer::after{
		content: 							unset;
	}
	
	.containerSettings .cities .inputContainer > .label{
		font-weight: 						500;
	}
	
	.containerSettings .cities .inputContainer > input{
		background-color: 					rgba(4, 26, 57, 0.02);
	}
	
	.containerSettings .cities .inputContainer > .valueSelected{
		font-size: 							14px;
		position: 							absolute;
		right: 								10px;
		bottom: 							10px;
		background: 						var(--gradient);
		background-clip: 					text;
		-webkit-background-clip: 			text;
		-webkit-text-fill-color: 			transparent;
		pointer-events: 					none;
	}
	
	.containerSettings .actions{
		display: 							flex;
		align-items: 						center;
		justify-content: 					space-between;
	}
	
	.containerSettings .payrollActions {
		justify-content: 					flex-end;
		gap: 								10px;
	}
	
	.containerSettings .actions #cancelFile{
		background: 						transparent;
		-webkit-text-fill-color: 			unset;
	}
	
	.containerSettings .actions #cancelFile:hover{
		background-color: 					unset;
	}
	
	.containerSettings .actions .btnBgBlueTextGradient{
		margin-left: 						auto;
		border: 							unset;
		padding: 							5px 15px;
		background: 						var(--gradient), var(--blue);
		background-clip: 					text, padding-box;
		-webkit-background-clip: 			text, padding-box;
		-webkit-text-fill-color: 			transparent;
		transition: 						all 0.3s ease-in-out;
	}
	
	.containerSettings .payrollActions .btnBgBlueTextGradient{
		margin-left: 						unset;
	}
	
	.containerSettings .actions .btnBgBlueTextGradient:hover{
		background-color: 					var(--darkBlue);
	}
	
	.containerSettings .actions .btnBgBlueTextGradient:disabled{
		background: 						transparent;
		-webkit-text-fill-color: 			unset;
	}
}


@media screen and (width >= 500px) {
    .unionSettings{
        width:                          450px;
    }
}

@media screen and (width >= 600px) {
	.containerSettings {
		width: 							auto;
	}
	
    .unionSettings,
	.costOfLivingSettings {
        width:                          550px;
    }
}

@media screen and (width >= 800px) {
    .unionSettings,
	.costOfLivingSettings {
        width:                          700px;
    }
}