.containerSection {
    position:                           absolute;
    top:                                100vh;
    bottom:                             0;
    left:                               0;
    right:                              0;
    z-index:                            999;
    border-radius:                      35px 35px 0 0;
    background:                         var(--grey);
    box-shadow:                         0 -50px 60px 0 rgba(7, 27, 57, 0.20);
    visibility:                         hidden;
    opacity:                            0;
    transition:                         all 0.3s ease-in-out;
    overflow-y:                         auto;
}

.containerSection.opened {
    visibility:                         visible;
    opacity:                            1;
    top:                                100px;
    bottom:                             unset;
    padding:                            110px 0 190px;
    /* min-height:                         calc(100vh - 100px); */
    min-height:                         100%;
    overflow-y:                         visible;
}

.containerSection .containerMinimizeClose {
    display:                            flex;
    position:                           absolute;
    top:                                30px;
    right:                              30px;
    gap:                                30px;
}

.containerSection .containerMinimizeClose > img {
    width:                              14px;
    height:                             14px;
    object-fit:                         contain;
    object-position:                    center center;
    cursor:                             pointer;
}

.containerSection .siteSelection-header {
    display:                            flex;
    width:                              100%;
    justify-content:                    space-between;
    gap:                                20%;
}

.containerSection .siteSelection-header .activeMenu,
.containerSection .siteSelection-header .containerDraftStatus {
    display:                            flex;
    width:                              100%;
}

.containerSection .siteSelection-header .activeMenu {
    flex-direction:                     column;
    justify-content:                    flex-start;
    gap:                                10px;
}

.containerSection .siteSelection-header .containerDraftStatus {
    flex-direction:                     column;
    align-items:                        flex-end;
    gap:                                10px;
}

.containerSection .siteSelection-header .containerDraftStatus .draftName-button-container {
    display:                            flex;
    flex-direction:                     row;
    width:                              100%;
    position:                           relative;
    height:                             fit-content;
}


.containerSection .siteSelection-header .containerDraftStatus .draftName-button-container .containerDraftName {
    display:                            flex;
    position:                           relative;
    flex-direction:                     row;
    width:                              100%;
    height:                             100%;
}

.containerSection .siteSelection-header .containerDraftStatus .draftName-button-container .containerDraftName .draftName {
    height:                             100%;
}

.containerSection .siteSelection-header .containerDraftStatus .draftName-button-container .containerDraftName > img {
    width:                              20px;
    height:                             20px;
    object-fit:                         contain;
    object-position:                    center center;
    position:                           absolute;
    top:                                0;
    bottom:                             0;
    right:                              15px;
    margin:                             auto 0;
}

.containerSection .siteSelection-header .containerDraftStatus .wizardStatus {
    color:                              rgba(0, 0, 0, 0.5);
    font-size:                          10px;
    font-weight:                        400;
    padding-right:                      10%;
}

.containerSection .floatSaveChanges {
    border: 							unset;
    border-radius: 						100px;
    background-color: 					var(--blue);
    padding: 							10px;
    transition: 						all 0.3s ease-in-out;
    position: 							fixed;
    right: 								30px;
    bottom: 							30px;
    z-index: 							999;    
}

.containerSection .floatSaveChanges:hover{
    background-color: 					var(--darkBlue);
}

.containerSection .containerWizardNavigation {
    display:                            flex;
    position:                           fixed;
    bottom:                             45px;
    left:                               0;
    right:                              0;
    margin:                             0 auto;
    width:                              fit-content;
    background-color:                   #FFFFFF;
    box-shadow:                         0 4px 30px 0 rgba(7, 27, 57, 0.05);
}

.containerSection .containerWizardNavigation .btnNavigation {
    padding:                            10px 30px;
    opacity:                            0.4;
    border:                             unset;
    background-color:                   white;
}

.containerSection .containerWizardNavigation .btnNavigation:not(:disabled) {
    opacity:                            0.8;
}

.containerSection .containerWizardNavigation .btnNavigation.active {
    background-color:                   var(--darkBlue);
    color:                              #FFFFFF;
    opacity:                            1;
}

.containerSection .secondaryButton, .containerSection .previewReport {
    display:                            inline-flex;
    background:                         var(--gradient), var(--blue);
    background-clip:                    text, padding-box;
    -webkit-background-clip:            text, padding-box;
    -webkit-text-fill-color:            transparent;
    padding:                            10px 10px;
    border:                             unset;
    gap:                                5px;
    align-items:                        center;
    justify-content:                    center;
    transition: 						all 0.3s ease-in-out;
}

.containerSection .secondaryButton:disabled {
    background:                         rgba(0, 0, 0, 0.05);
    -webkit-text-fill-color:            unset;
}

.containerSection .secondaryButton:hover:not(:disabled), 
.containerSection .previewReport:hover:not(:disabled) {
    background-color:                   var(--darkBlue);
}

.containerSection .containerActions {
    display:                            flex;
    justify-content:                    flex-end;
    gap:                                10px;
    align-items:                        center;
}

.containerSection .containerActions .secondaryButton,
.containerSection .containerActions .previewReport {
    padding:                            10px 50px;
    margin-left:                        unset;
}

.containerSection .containerActions .secondaryButton > img{
    width:                              18px;
    height:                             18px;
    object-fit:                         contain;
    object-position:                    center center;
}

.containerSection .containerActions .previewReport > img{
    width:                              18px;
    height:                             18px;
    object-fit:                         contain;
    object-position:                    center center;
}

.containerSection .cancelButton {
    padding:                            10px 50px;
    justify-content:                    center;
    align-items:                        center;
    border:                             unset;
    background:                         transparent;
}

.containerSection .sectionDescription {
    font-weight:                        400;
    opacity:                            0.5;
}

/* .containerSection .containerDraftName {
    width:                              310px;
    align-items:                        center;
    gap:                                150px;
    position:                           relative;
}

.containerSection .containerDraftName > input {
    padding:                            10px 40px 10px 20px;
}

.containerSection .containerDraftName > img {
    width:                              20px;
    height:                             20px;
    object-fit:                         contain;
    object-position:                    center center;
    position:                           absolute;
    top:                                0;
    bottom:                             0;
    right:                              15px;
    margin:                             auto 0;
} */

/* .containerSection .savingDraftContainer {
    margin-left:                        10px;
} */

.containerSection .loading {
    width:                              30px;
    height:                             30px;
    visibility:                         hidden;
    opacity:                            0;
    transition:                         all 0.3s ease-in-out;
}

.containerSection .loading.show {
    visibility:                         visible;
    opacity:                            1;
}

.containerSection .animationContainer{
	position: 							fixed;
	top: 								0;
	left: 								0;
	right: 								0;
	bottom: 							0;
	z-index: 							999;
	visibility: 						hidden;
	opacity: 							0;
}

.containerSection .animationContainer.show{
	animation: 							animatedContainer 0.5s forwards;
}

.containerSection .animationContainer .columnAnimation{
	width: 								0%;
	height: 							100%;
	position: 							absolute;
	top: 								0;
	left: 								0;
	background-color: 					#041A39;
}

.containerSection .animationContainer .columnAnimation:nth-of-type(2){
	left: 								25%;
}

.containerSection .animationContainer .columnAnimation:nth-of-type(3){
	left: 								50%;
}

.containerSection .animationContainer .columnAnimation:nth-of-type(4){
	left: 								75%;
}

.containerSection .animationContainer .columnAnimation.animate{
	animation: 							animatedWidth 0.5s forwards;
}

.containerSection .placesFoundContainer {
    background: 						rgba(255, 255, 255, 0.90);
    box-shadow: 						0 15px 50px 0 rgba(4, 26, 57, 0.15);
    position: 							absolute;
    width: 								100%;
    z-index: 							999;
    visibility: 						hidden;
    opacity: 							0;
    transition: 						all 0.3s ease-in-out;
    max-height: 						0;
    overflow: 							hidden;
}

.containerSection .placesFoundContainer.show {
    visibility: 						visible;
    opacity: 							1;
    max-height: 						1000px;
}

.containerSection .placesFoundContainer .placeFound{
    padding: 							10px 35px;
    cursor: 							pointer;
}

.containerSection .placesFoundContainer .placeFound:hover{
    background: 						var(--gradient), rgba(4, 26, 57, 0.10);
    background-clip: 					text, padding-box;
    -webkit-background-clip: 			text, padding-box;
    -webkit-text-fill-color: 			transparent;
}

@keyframes animatedContainer {
	0%{
		visibility: 					hidden;
		opacity: 						0;
	}
	100%{
		visibility: 					visible;
		opacity: 						1;
	}
}
@keyframes animatedWidth {
	0%{
		width: 							0%;
	}
	100%{
		width: 							25%;
	}
}

@media screen and (width <= 991px) {
    /* .container-xl {
        padding-left:                   0 !important;
        padding-right:                  0 !important;
    } */

    .containerSection .floatSaveChanges {
        right: 								22px;
        bottom: 							40px;
    }
    
    .containerSection .siteSelection-header {
        display:                        flex;
        flex-direction:                 column;
        gap:                            30px;
        padding-inline:                 10px;
    }

    .containerSection .containerActions {
        flex-direction:                 column;
        gap:                            20px;
        /* padding-right:                  20px; */
    }

    .containerSection .containerActions .previewReport {
        width:                          100%;
    }

    .containerSection .containerActions .secondaryButton {
        width:                          100%;
        order:                          1;
    }
    
    .containerSection .containerActions .cancelButton {
        width:                          100%;
        order:                          2;
    }

    .containerSection .secondaryButton {
        padding-inline:                 20px;
    }

    .containerSection .siteSelection-header .activeMenu > h1 {
        font-size:                      32px;
    }

    .containerSection .siteSelection-header .containerDraftStatus .draftName-button-container {
        height:                         45px;
    }

    .containerSection .siteSelection-header .containerDraftStatus .wizardStatus {
        padding-right:                  0;
    }
}
