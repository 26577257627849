.containerSectionReport .mobile-filters-section {
    display:                                    flex;
    align-self:                                 flex-end;
}

.containerSectionReport .mobile-filters-section .primaryButton {
    display:                                    inline-flex;
    border:                                     unset;
    padding:                                    10px;
    justify-content:                            center;
    align-items:                                center;
    background:                                 #FFFFFF;
    gap:                                        5px;
}

.containerSectionReport .mobile-filters-section .primaryButton::before {
    content:                                    "";
    display:                                    block;
    width:                                      20px;
    height:                                     20px;
    background:                                 url("../../images/tune.svg") center center no-repeat;
    background-size:                            20px;
    margin-right:                               5px;
}

.containerSectionReport .mobile-filters-section .primaryButton::after {
    content:                                    "";
    display:                                    block;
    width:                                      25px;
    height:                                     25px;
    background:                                 url("../../images/arrowDown.svg") center center no-repeat;
    background-size:                            25px;
}

.containerSectionReport .mobile-filters-section .primaryButton > span {
    font-size:                                  12px;
    font-weight:                                400;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container {
    position:                       	        absolute;
	top: 									    100vh;
	bottom:                          		    0;
	left:                            		    0;
	right:                            	 	    0;
	z-index:                          	 	    999;
	border-radius:                    	 	    35px 35px 0 0;
	background:                       	 	    var(--grey);
	box-shadow:                       	 	    0 -50px 60px 0 rgba(7, 27, 57, 0.20);
	overflow-y:                       	 	    auto;
	visibility:                       	 	    hidden;
	opacity:                          	 	    0;
	transition: 							    all 0.3s ease-in-out;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container.opened {
    visibility:                       	 	    visible;
	opacity:                          	  	    1;
	bottom: 								    0;
	top: 									    0;
	padding:                           	 	    110px 0;
	min-height: 							    100%;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .close-image {
    position:                                   absolute;
    top:                                        30px;
    right:                                      30px;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .container .filtersContainer {
    display:                                    flex;
    flex-direction:                             column;
    gap:                                        30px;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .container .filtersContainer .filter {
    display:                                    flex;
    flex-direction:                             row;
    align-items:                                center;
    gap:                                        15px;
    padding-bottom:                             30px;
    border-bottom:                              1px solid rgba(0, 0, 0, 0.05);
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .container .filtersContainer .filter::after {
    content:                                    "";
    display:                                    block;
    width:                                      25px;
    height:                                     25px;
    background:                                 url("../../images/arrowDown.svg") center center no-repeat;
    rotate:                                     -90deg;
    margin-left:                                auto;
    background-size:                            25px;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .container .filtersContainer .filter:last-child {
    border-bottom:                              unset;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo {
    display: flex;
    flex-direction: column;
    gap:                                        20px;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filterInfoContainer {
    display:                                    flex;
    justify-content:                            space-between;
    align-items:                                center;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filterInfoContainer > button {
    padding: 								    5px 15px;
	background: 							    transparent;
	border: 								    #FFFFFF;
	text-decoration: 						    none;
	-webkit-text-fill-color: 				    black;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filterInfoContainer > button:last-of-type {
    background: 							    var(--gradient), var(--darkBlue);
	background-clip: 						    text, padding-box;
	-webkit-background-clip: 				    text, padding-box;
	-webkit-text-fill-color: 				    transparent;
	transition: 							    all 0.3s ease-in-out;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filterInfoContainer > button:disabled {
    background: 							    rgba(0, 0, 0, 0.05);
	opacity: 								    0.5;
	-webkit-text-fill-color: 				    black;
}

/* CSS for client filter section */

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filter-client-section {
    display:                                    flex;
    flex-direction:                             column;
    gap:                                        20px;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filter-client-section .mobile-filter-search-input-container::after {
    content:                                    "";
    display:                                    block;
    width:                                      25px;
    height:                                     25px;
    background:                                 url("../../images/search.svg") center center no-repeat;
    background-size:                            25px;
    position:                                   absolute;
    top:                                        50%;
    transform:                                  translateY(-50%);
    right:                                      25px;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filter-client-section .mobile-clients-container {
    display:                                    flex;
    flex-direction:                             column;
    gap:                                        20px;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filter-client-section .mobile-clients-container .mobile-client {
    display:                                    flex;
    flex-direction:                             row;
    justify-content:                            space-between;
    align-items:                                flex-start;
    padding:                                    20px 0;
    border-bottom:                              1px solid rgba(0, 0, 0, 0.05);
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filter-client-section .mobile-clients-container .mobile-client .mobile-client-title {
    display:                                    flex;
    flex-direction:                             row;
    align-items:                                center;
    gap:                                        10px;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filter-client-section .mobile-clients-container .mobile-client .mobile-client-title > label {
    font-size:                                  16px;
    font-weight:                                700;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filter-client-section .mobile-clients-container .mobile-client .mobile-client-info {
    display:                                    flex;
    flex-direction:                             column;
    gap:                                        5px;
    align-items:                                flex-end;
    opacity:                                    50%;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filter-client-section .mobile-clients-container .mobile-client .mobile-client-info > span {
    font-size:                                  12px;
}

/* CSS for type filter section */
.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filter-type-section{
    margin-top:                                 40px;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filter-type-section .mobile-type{
    display:                                    flex;
    flex-direction:                             row;
    align-items:                                center;
    gap:                                        20px;
    padding-bottom:                             40px;
    border-bottom:                              1px solid rgba(0, 0, 0, 0.05);
}

/* CSS for Date filter section */

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filter-date-section {
    display:                                    flex;
    flex-direction:                             column;
    width:                                      100%;
    gap:                                        20px;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filter-date-section .inputs-date-container {
    display:                                    flex;
    flex-direction:                             row;
    gap:                                        20px;
    justify-content:                            space-between;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filter-date-section .date-container {
    display:                                    flex;
    flex-direction:                             row;
    align-items:                                center;
    width:                                      48%;
}

.containerSectionReport .mobile-filters-section .mobile-filters-container .filterInfo .filter-date-section .date-container > input {
    margin-left:                                10px;
    background:                                 rgba(0, 0, 0, 0.03);
    padding:                                    6px 15px;
}

/* CSS for Calendar */
.rdrDateRangePickerWrapper .rdrCalendarWrapper {
    width:                                      100%;
    background:                                 transparent;
}

.rdrDateRangePickerWrapper .rdrDefinedRangesWrapper,
.rdrDateRangePickerWrapper .rdrCalendarWrapper .rdrDateDisplayWrapper {
    display:                                    none;
}

.rdrDateRangePickerWrapper .rdrCalendarWrapper .rdrMonthAndYearWrapper {
    justify-content:                            space-between;
}
.rdrDateRangePickerWrapper .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrMonthAndYearPickers select {
    font-weight:                                700;
    color:                                      black;
}

.rdrDateRangePickerWrapper .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton {
    background:                                 transparent;
}

.rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton > i {
    border-style:                               none;
}

.rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextButton {
    margin: 0;
}

.rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrPprevButton {
    margin: 0;
}

.rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextButton::after {
    content:                                    "";
    display:                                    block;
    width:                                      25px;
    height:                                     25px;
    mask:                                       url("../../images/arrowDown.svg") center center no-repeat;
    background:                                 black;
    background-size:                            25px;
    rotate:                                    -90deg;
}

.rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrPprevButton::before {
    content:                                    "";
    display:                                    block;
    width:                                      25px;
    height:                                     25px;
    mask:                                       url("../../images/arrowDown.svg") center center no-repeat;
    background:                                 black;
    background-size:                            25px;
    rotate:                                     90deg;
}

.rdrCalendarWrapper .rdrMonths .rdrMonth {
    width:                                      100%;
}

.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrWeekDays .rdrWeekDay {
    color:                                      black;
    font-weight:                                700;
}

.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrWeekDays .rdrWeekDay:nth-of-type(1),
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrWeekDays .rdrWeekDay:nth-of-type(7) {
    color:                                      transparent;
    background:                                 var(--gradient);
    background-clip:                            text;
    -webkit-background-clip:                   text;
    -webkit-text-fill-color:                   transparent;
}

.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDayPassive .rdrDayNumber > span {
    opacity:                                    0.5;
    color:                                      black;
}

.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay:nth-of-type(1) .rdrDayNumber > span,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay:nth-of-type(7) .rdrDayNumber > span,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay:nth-of-type(8) .rdrDayNumber > span,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay:nth-of-type(14) .rdrDayNumber > span,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay:nth-of-type(15) .rdrDayNumber > span,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay:nth-of-type(21) .rdrDayNumber > span,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay:nth-of-type(22) .rdrDayNumber > span,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay:nth-of-type(28) .rdrDayNumber > span,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay:nth-of-type(29) .rdrDayNumber > span,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay:nth-of-type(35) .rdrDayNumber > span,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay:nth-of-type(36) .rdrDayNumber > span,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay:nth-of-type(42) .rdrDayNumber > span {
    color:                                      var(--gradient);
}

.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay .rdrInRange,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay .rdrStartEdge,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay .rdrEndEdge {
    background-color:                           var(--blue);
}

.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDayHovered .rdrDayNumber::after {
    border:                                     transparent;
}

.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay .rdrInRange,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay .rdrStartEdge,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay .rdrEndEdge,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay .rdrDayEndPreview,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay .rdrDayStartPreview {
    border:                                     none;
    border-radius:                              0;
}

.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay:has(.rdrInRange) > .rdrDayNumber > span,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay:has(.rdrStartEdge) > .rdrDayNumber > span,
.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDay:has(.rdrEndEdge) > .rdrDayNumber > span {
    color:                                      var(--gradient);
}

.rdrCalendarWrapper .rdrMonths .rdrMonth .rdrDays .rdrDayToday .rdrDayNumber span::after {
    background:                                 var(--gradient);
}
