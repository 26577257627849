.report-results-wrapper {
    width:                      100%;
    height:                     100%;
    position:                   relative;
    overflow-X:                 hidden;
}


.report-results-wrapper .button-wrapper{
    background:               #FFF;
    color:                    #000;
    display:                    flex;
    align-items:                center;
    box-shadow:                 0px 15px 15px 0px rgba(35, 107, 216, 0.10);
    position:                   absolute;
    top:                        -100px;
    transition:                 top 0.5s ease-in-out;
    z-index:                    5;
}

.report-results-wrapper .button-wrapper.show{
    top:                        30px;
}

.report-results-wrapper .button-wrapper:first-of-type{
    left:                       30px;
}

.report-results-wrapper .button-wrapper:nth-of-type(2){
    right:                      30px;
}


.report-results-wrapper .button-wrapper .button-item{
    background:                 unset;
    border:                     unset;
    padding:                    10px 20px; 
    display:                    flex; 
    align-items:                center;
    gap:                        10px; 
    transition:                 background ease 0.3s;
}

.report-results-wrapper .button-wrapper .button-item span {
    font-weight:                400;
    font-size:                  14px;  
}

.report-results-wrapper .button-wrapper #edit-save-button {
    width:                      fit-content;
}

.report-results-wrapper .button-wrapper:first-of-type .button-item#send-mail {
    gap:                        10px;
}

.report-results-wrapper .button-wrapper:first-of-type .button-item#send-mail::before,
.report-results-wrapper .button-wrapper:first-of-type .button-item#send-mail::after {
    content:                      "";
    width:                        24px;
    height:                       24px;
}

.report-results-wrapper .button-wrapper:first-of-type .button-item#send-mail::before {
    mask:                         url("../../images/forward_to_inbox.svg") center center no-repeat;
    background:                   #041A39;
    background-size:              24px 24px;
    left:                         -10px; /* Adjust the left margin as needed */
}

.report-results-wrapper .button-wrapper:first-of-type .button-item#send-mail::after {
    mask:                         url("../../images/expand_less.svg") center center no-repeat;
    background:                   #041A39;
    background-size:              24px 24px;
    right:                        10px; /* Adjust the right margin as needed */
    transform:                    rotate(180deg);
    transition:                   transform 0.2s ease;
}

.report-results-wrapper .button-wrapper:first-of-type .button-item:first-child::before{
    content:                     "";
    width:                       24px;
    height:                      24px;
    mask:                        url("../../images/download.svg") center center no-repeat;
    background:                #041A39;
    background-size:             24px 24px;
}

.report-results-wrapper .button-wrapper .button-item:not(:disabled):hover::before,
.report-results-wrapper .button-wrapper .button-item.active::before,
.report-results-wrapper .button-wrapper .button-item:hover::after,
.report-results-wrapper .button-wrapper .button-item.active::after {
    background:                 var(--gradient) !important;
}

.report-results-wrapper .button-wrapper:nth-of-type(2) .button-item#go-back::before{
    content:                     "";
    width:                       24px;
    height:                      24px;
    mask:                        url("../../images/go_back.svg") center center no-repeat;
    background:                 #041A39;
    background-size:             24px 24px;
}

.report-results-wrapper .button-wrapper:nth-of-type(2) .button-item#edit-save-button::before{
    content:                     "";
    width:                       24px;
    height:                      24px;
    mask:                        url("../../images/edit.svg") center center no-repeat;
    background:                 #041A39;
    background-size:             24px 24px;
}

.report-results-wrapper .button-wrapper:nth-of-type(2) .button-item.save#edit-save-button::before{
    mask:                        url("../../images/save.svg") center center no-repeat;
}

.report-results-wrapper .button-wrapper:nth-of-type(2) .button-item#map::before{
    content:                     "";
    width:                       24px;
    height:                      24px;
    mask:                        url("../../images/public.svg") center center no-repeat;
    background:                 #041A39;
    background-size:             24px 24px;
}

.report-results-wrapper .button-wrapper:nth-of-type(2) .button-item:last-child::before{
    content:                     "";
    width:                       24px;
    height:                      24px;
    mask:                        url("../../images/data_table.svg") center center no-repeat;
    background:                 #041A39;
    background-size:             24px 24px;
}


.report-results-wrapper .button-wrapper .button-item:not(:disabled):hover,
.report-results-wrapper .button-wrapper .button-item.active {
    background:                 #041A39;
    color:                      #FFF;
}

.report-results-wrapper .button-wrapper .button-item.save:hover {
    background:                 var(--darkBlue);
    color:                      #FFF;
}

.report-results-wrapper .button-wrapper:first-of-type .button-item.active#send-mail::after{
    transform:                  rotate(360deg);
}

.report-results-wrapper .button-wrapper .button-item.active::after{
    transform:                  rotate(360deg);
}

.report-results-wrapper .button-wrapper .button-item.clicked::before{
    background-color:           linear-gradient(90deg, #009AFF 19.79%, #8CA5FF 100%);
}


.report-results-wrapper .summary-button{
    position:                   absolute;
    bottom:                     30px;
    right:                      30px;
    display:                    flex;
    flex-direction:             column;
    background-color:           #FFF;
    filter:                     drop-shadow(0px 15px 50px rgba(35, 107, 216, 0.25));
    opacity:                    0.96;
    z-index:                    3;
    transition:                 all 0.5s ease-in-out;
    width:                      350px;
    height:                     250px;
}

.report-results-wrapper .summary-button.show{
    right:                      -50%;
}

.report-results-wrapper .summary-button .hide-show-summary{
    display:                    flex;
    align-items:                center;    
    justify-content:            center;
    position:                   absolute;
    width:                      78px;
    right:                      50%;
    transform:                  translateX(50%);
    bottom:                     100%;
    background:                 #FFF;
    border-top-left-radius:     15px;
    border-top-right-radius:    15px;
}

.report-results-wrapper .summary-button .hide-show-summary > img {
    transition: rotate 0.3s ease-in-out;
}

.report-results-wrapper .summary-button.toggle .hide-show-summary > img {
    rotate:                     180deg;
}

.report-results-wrapper .summary-button .hide-show-summary::after,
.report-results-wrapper .summary-button .hide-show-summary::before{
    content:                    "";
    position:                   absolute;
    height:                     16px;
    width:                      10px;
    border:                     6px solid white;
}

.report-results-wrapper .summary-button .hide-show-summary::after {
    bottom:                     0;
    left:                       88%;
    border-top-right-radius:    15px;
}

.report-results-wrapper .summary-button .hide-show-summary::before {
    bottom:                     0;
    right:                      88%;
    border-top-left-radius:     15px;
}

.report-results-wrapper .summary-button .summary-box-head {
    font-weight:                700;
    color:                      #FFF;
    background-color:           #041A39;
    padding:                     20px 20px 20px 20px;
    filter:                     drop-shadow(0px 15px 30px rgba(35, 107, 216, 0.25));
    opacity:                    0.95;
    display:                    flex;
    justify-content:            space-between;
}

.report-results-wrapper .summary-button .scores-row{
    padding:                    30px 22px 10px;
}

.report-results-wrapper .summary-button .scores-row .slick-slider.slick-initialized{
    width:                      100%;
}

.report-results-wrapper .summary-button .scores-row .slick-track{
    display:                    flex !important;
}


.report-results-wrapper .summary-button .scores-row .slick-slide{
    height:                     inherit !important;
}

.report-results-wrapper .summary-button .scores-row .slick-slide > div:first-of-type{
    height:                     100% !important;
}
.report-results-wrapper .slick-prev::before,
.report-results-wrapper .slick-next::before
{
    content:                    "";
}
.report-results-wrapper .slick-arrow.slick-prev,
.report-results-wrapper .slick-arrow.slick-next
{
    top:                            30px;
    line-height:                    0;
    width:                          20px;
    height:                         20px;
    background:                     url("../../images/expand_less.svg") center center no-repeat;
    background-color:               var(--gradient);
    background-size:                20px 20px;
}
.report-results-wrapper .slick-arrow.slick-prev{
    transform:                      rotate(270deg);
}

.report-results-wrapper .slick-arrow.slick-next{
    transform:                      rotate(90deg);
}

.report-results-wrapper .summary-button .scores-row .score-container{
    text-align:                     center;
    display:                        flex !important;
    flex-direction:                 column;
    justify-content:                space-evenly;
    height:                         100%;
}
.report-results-wrapper .summary-button .scores-row .score-container > h5 {
    font-size:                  14px;
    font-weight:                500;
}

.report-results-wrapper .summary-button .scores-row .score-container .std {
    color:                      var(--dark-blue, #041A39);
    font-size:                  40px;
    font-weight:                400;
    opacity:                    0.5;
}


.report-results-wrapper .summary-button .scores-row .score-container .highest {
    font-weight:                500;
    background:                 var(--gradient_boton_hover, linear-gradient(180deg, #009AFF 19.79%, #8CA5FF 100%));
    background-clip:            text;
    -webkit-background-clip:    text;
    -webkit-text-fill-color:    transparent;
    opacity:                    1;
    font-size:                  40px;
}

.report-results-wrapper .summary-button .slider-detailsButton-container {
    padding-bottom:             20px;
    transition:                 all 0.7s ease-in-out;
    max-height:                 200px;
}

.report-results-wrapper .summary-button .slider-detailsButton-container > button {
    border:                     unset;
    margin:                     0 23px 25px 61%;
    background:                 rgba(0, 163, 255, 0.10);
    display:                    flex;
    gap:                        5px;
    align-items:                center;
    padding:                    6px 10px;
    transition:                 background ease 0.3s;
    font-weight:                400;
    font-size:                  14px;
}

.report-results-wrapper .summary-button .slider-detailsButton-container > button > span {
    font-weight:                400;
    font-size:                  14px;
    background:                 var(--gradient_boton_hover, linear-gradient(180deg, #009AFF 19.79%, #8CA5FF 100%));
    background-clip:            text;
    -webkit-background-clip:    text;
    -webkit-text-fill-color:    transparent;
    text-wrap:                  nowrap;
}

.report-results-wrapper .summary-button .slider-detailsButton-container > button:hover {
    background:                 var(--darkBlue);
}

.report-results-wrapper .button-wrapper .email-menu{
    position:                   absolute;
    display:                    none;
    top:                        44px;
    left:                       167px;
}

.report-results-wrapper .button-wrapper .email-menu.show{
    display:                    flex;
    flex-direction:             column;
    gap:                        3px;
    background:                 rgba(255, 255, 255, 0.90);
}

.report-results-wrapper .button-wrapper .email-menu.show .option-mail{
    font-weight:                400;
    color:                      #000;
    padding:                    10px 35px;
    text-align:                 justify;
    
}

.report-results-wrapper .button-wrapper .email-menu.show .option-mail:hover{
    background:                 rgba(4, 26, 57, 0.10);
    color:                      #009AFF;
}

.fade.mail-form-modal.modal .modal-dialog-centered .modal-content{
    background:                 #FFF;
    box-shadow:                 0px 50px 200px 0px rgba(4, 26, 57, 0.15);
    border-radius:              0;
    border:                     unset;
}

.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-header{
    display:                        flex;
    flex-direction:                 column-reverse;
    padding:                        20px 20px 15px 50px;
    border:                         unset;
}

.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-header .modal-title.h4{
    margin-right:                   auto;
    font-size:                      23px;
    font-weight:                    500;
}

.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-body{
    padding:                        0px 50px 30px 50px;
    border:                         unset;
    
}

.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-body .mail-capture{
    background:                   rgba(4, 26, 57, 0.04);
    font-size:                      16px;
    font-weight:                    300;
    padding:                        12px 40px 12px 25px;
}
.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-body .mail-capture.error{
    border-color:                  red;
    background:                   rgba(138, 9, 5, 0.08);

}
.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-body .error-msg::before{
    content: "🛈 ";

}
.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-body .error-msg{
    max-height:                      0;
    color:                         red;
    transition:                      height 0.4s ease-in-out, opacity 0.4s ease-in-out;
    padding:                         5px;
    position:                        absolute;
    opacity:                         0;
    font-size:                       13px;
}
.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-body .error-msg.error{
    max-height:                      fit-content;
    opacity:                         0.9;
}


.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-footer{
    border:                         unset;
    padding:                        0 50px 30px 0;
}

.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-footer > button{
    border:                         unset;
    background:                     var(--gradient), var(--blue);
    background-clip:                text, padding-box;
    -webkit-background-clip:        text, padding-box;
    -webkit-text-fill-color:        transparent;
    transition:                     background ease 0.3s;
    padding:                        10px 50px;
    gap:                            10px;
    font-size:                      16px;
    font-weight:                    400;
}

.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-footer > button:hover{
    background-color:               var(--darkBlue);
}

.fade.modal-backdrop{
    display:                        none;
}

.fade.confirmation-modal.modal .modal-dialog.modal-dialog-centered .modal-content{
    background:                     #FFF;
    box-shadow:                       0px 50px 200px 0px rgba(4, 26, 57, 0.15);
    border-radius:                    0;
    border:                           unset;
}

.fade.confirmation-modal.modal .modal-dialog.modal-dialog-centered .modal-content{
    display:                         flex;
    align-items:                     center;
    padding:                         80px 100px;
}
.fade.confirmation-modal.modal .modal-dialog.modal-dialog-centered {
    border:                          unset;
    padding:                         0;
}
.fade.confirmation-modal.modal .modal-dialog.modal-dialog-centered .check-icon{
    width:                           50px;
    height:                          50px;
    mask:                            url("../../images/check.svg") center center no-repeat;
    background-color:               #08D1A1;
    background-size:                 50px 50px;
}

.confirm-swal-popup {
    padding:                         80px 165px;
    border-radius:                   0;
    gap:                             20px;
    height:                          fit-content;
    width:                           fit-content;
}


.confirm-swal-container .swal2-image{
    margin-top:                      0;
    margin-bottom:                   0;
}

.confirm-swal-title{
    padding:                         0;
    font-size:                       23px;
    font-weight:                     500;
    color:                         black;
    max-width:                       100%;
}

.report-results-wrapper .arrow-button-container{
    display:                        flex;
    justify-content:                center;
    align-items:                    center;
}

.report-results-wrapper .arrow-button-container> button{
    border: none;
}

.report-results-wrapper .arrow-button-container .prev-button{
    width:                           20px;
    height:                          20px;
    background:                      url("../../images/expand_less.svg") center center no-repeat;
    background-size:                 20px 20px;
    transform:                      rotate(270deg);
}

.report-results-wrapper .arrow-button-container .prev-button.hidden,
.report-results-wrapper .arrow-button-container .next-button.hidden{
    visibility: hidden;
}

.report-results-wrapper .arrow-button-container .next-button{
    width:                                   20px;
    height:                                  20px;
    background:                              url("../../images/expand_less.svg") center center no-repeat;
    background-size:                         20px 20px;
    transform:                               rotate(90deg);
}

.report-results-wrapper .arrow-button-container .prev-button:hover,
.report-results-wrapper .arrow-button-container .next-button:hover
{
    background:                            url("../../images/expand_less_dark.svg") center center no-repeat;
}

.report-results-wrapper .arrow-button-container .prev-button:hover
{ 
    transform:                              rotate(0deg);
}

.report-results-wrapper .arrow-button-container .next-button:hover
{ 
    transform:                               rotate(180deg);
}


.report-results-wrapper .summary-wrapper .arrow-button-container{
    margin-top: 22px;
}

.report-results-wrapper .details-wrapper .arrow-button-container{
    margin-top: 25px;
}

.report-results-wrapper .details-wrapper .slick-arrow.slick-prev{
    top: 120px;
}

.report-results-wrapper .details-wrapper .slick-arrow.slick-next{
    top: 120px;
}


@media screen and (width <= 991px) {
    .report-results-wrapper .button-wrapper:first-of-type {
        flex-direction:             column;
        gap:                        20px;
        background:                 transparent;
    }

    .report-results-wrapper .button-wrapper:first-of-type.rowOrder {
        flex-direction:             row;
        flex-wrap:                  nowrap;
        box-shadow:                 unset;
    }

    .report-results-wrapper .button-wrapper .button-item {
        background:                 #fff;
        box-shadow:                 0px 15px 15px 0px rgba(35, 107, 216, 0.10);
    }

    .report-results-wrapper .button-wrapper:first-of-type.rowOrder .button-item{
        flex-shrink:                1;
    }

    .report-results-wrapper .button-wrapper:first-of-type .button-item#send-mail {
        position:                   relative;
    }

    .report-results-wrapper .button-wrapper:first-of-type .button-item#send-mail::after {
        content:                    unset;
    }

    .report-results-wrapper .button-wrapper .email-menu {
        top:                        0;
        left:                       100%;
    }
    
    .report-results-wrapper .summary-button{
        bottom:                     73px;
        right:                      50%;
        transform:                  translateX(50%);
        height:                     auto;
        width:                      95%;
    }

    .report-results-wrapper .summary-button.toggle .slider-detailsButton-container {
        max-height:                 0px;
        padding-bottom:             unset;
        overflow:                   auto;
        scrollbar-width:            none;
    }

    .report-results-wrapper .summary-button.mobileHide {
        opacity:                    0;
        visibility:                 hidden;
    }

    .report-results-wrapper .summary-button .slider-detailsButton-container > button {
        width:                      85%;
        margin:                     0 auto;
        justify-content:            center;
    }

    .confirm-swal-popup {
        padding:                     20px 20px;
    }
}