/* report is ready title CSS properties */
.videoMapScrollContainer{
  width:                              100%;
  height:                             100%;
}

.titleContainer {
  position:                           relative;
  display:                            flex;
  justify-content:                    center;
  align-items:                        center;
  width:                              100%;
  height:                             100vh;
  height:                             100dvh;
  background:                         white;
  overflow:                           hidden;
  opacity:                            1;
  visibility:                         visible;
  z-index:                            9999;
  transition:                         all 0.5s ease-in;
}

.titleContainer.ready {
  height:                             270vh;
}

.titleContainer.hide {
  background:                         transparent;
  opacity:                            0;
  visibility:                         hidden;
  height:                             100vh;
  height:                             100dvh;
}

.titleContainer .animatedContainer {
  position:                           fixed;
  display:                            flex;
  align-items:                        center;
  justify-content:                    center;
  top:                                0;
  left:                               0;
  height:                             100vh;
  height:                             100dvh;
  width:                              100%;
  overflow:                           hidden;
}

.titleContainer .animatedContainer .containerAnimation {
  position:                           absolute;
  top:                                0;
  width:                              100%;
  height:                             100%;
  overflow:                           hidden;
  display:                            flex;
  align-items:                        center;
  justify-content:                    center;
  opacity:                            0;
}

.titleContainer .animatedContainer .containerAnimation.show {
  opacity:                            1;
}

.titleContainer .animatedContainer .containerAnimation .animatedTitle {
  display:                            flex;
  position:                           absolute;
  top:                                10%;
  transform:                          matrix(1, 0, 0, 1, 0, 0);
  text-align:                         center;
}

.titleContainer .animatedContainer .containerAnimation .animatedTitle.animate {
  animation:                          flashingTitle 0.55s alternate infinite ease-in-out;
  animation-delay:                    0.5s;
}

.titleContainer .animatedContainer .containerAnimation .animatedTitle .visuallyHidden {
  position:                           absolute;
  clip:                               rect(1px,1px,1px,1px);
  clip-path:                          inset(0px 0px 99.9% 99.9%);
  overflow:                           hidden;
  height:                             1px;
  width:                              1px;
  padding:                            0;
  border:                             0;
}

.titleContainer .animatedContainer .containerAnimation .animatedTitle .reportReady {
  background-size:                    contain;
  background-repeat:                  no-repeat;
  background-position:                50%;
  background-image:                   url("../../images/prodensa-report-ready-desfasado.svg");
  background-size:                    860px 600px;
  width:                              860px;
  height:                             600px;
}

.titleContainer .animatedContainer .containerAnimation .scrollImg {
  position:                           absolute;
  bottom:                             7%;
  left:                               50%;
  transform:                          translateX(-50%);
  cursor:                             pointer;
  opacity:                            1;
  visibility:                         visible;
  transition:                         all 0.3s ease-in-out;
}

.titleContainer .animatedContainer .containerAnimation .scrollImg.hide {
  opacity:                            0;
  visibility:                         hidden;
}

.titleContainer .animatedContainer .containerAnimation .scrollText {
  bottom:                             15%;
}

/* video CSS properties */

.videoContainer {
  position:                           absolute;
  z-index:                            999999;
  left:                               0;
  top:                                0;
  width:                              100%;
  height:                             100vh;
  height:                             100dvh;
  overflow:                           hidden;
  opacity:                            1;
  visibility:                         visible;
  transition:                         all 0.5s ease-in-out;
}

.videoContainer.hide {
  opacity:                            0;
  visibility:                         hidden;
}

.videoContainer .video {
  position:                           absolute;
  width:                              100%;
  top:                                50%;
  left:                               50%;
  transform:                          translate(-50%, -50%);
}

@keyframes flashingTitle {
	0%{
		opacity: 						  1;
	}
	100%{
    opacity: 						  0.2;
	}
}

@media screen and (width <= 475px) {
  .titleContainer .animatedContainer .containerAnimation .animatedTitle .reportReady {
    background-size:                    360px 300px;
    width:                              360px;
  }
}

@media screen and (991px >= width >= 476px) {
  .titleContainer .animatedContainer .containerAnimation .animatedTitle .reportReady {
    background-size:                    560px 300px;
    width:                              560px;
  }
}