.street-config-wrapper .head-wrapper {
    margin-bottom:                          20px;
}

.street-config-wrapper .head-wrapper .searchContainer {
    display:                                flex;
    align-self:                             center;
    position:                               relative;
    width:                                  30%;
    min-width:                              200px;
    align-content:                          center;
}

.street-config-wrapper .head-wrapper .searchContainer::after {
    content: 								"";
	display: 								block;
	width: 									25px;
	height: 								25px;
	background: 							url("../../images/search.svg") center center no-repeat;
	background-size: 						25px;
	position: 								absolute;
	top: 									0;
	bottom: 								0;
	right: 									25px;
	margin:  								auto 0;
	cursor: 								pointer;
}

.street-config-wrapper .new-user {
    display:                                flex;
    justify-content:                        flex-end;
    margin-bottom:                          20px;
}

.street-config-wrapper {
    width:                                  100%;
}

.street-config-wrapper .user-table {
    display:                                grid;
    grid-template-rows:                     1fr auto;
    overflow:                               auto;
}

.street-config-wrapper .user-table .table-head {
    background:                             #FFF;
    box-shadow:                             0px 5px 30px 0px rgba(35, 107, 216, 0.08);
}

.street-config-wrapper .user-table .table-head .table-head-item {
    display:                                flex;
    gap:                                    10px;
    padding:                                20px;
}

.street-config-wrapper .user-table .table-head .table-head-item > span {
    font-size:                              16px;
    font-weight:                            700;
}

.street-config-wrapper .user-table .table-head .table-head-item > img {
    cursor:                                 pointer;
    transition:                             all 0.3s ease-in-out;
}

.street-config-wrapper .user-table .table-head .table-head-item > img.active.up {
    transform:                              rotate(180deg);
}

.street-config-wrapper .user-table .table-head,
.street-config-wrapper .user-table .table-body .table-row {
    display:                                grid;
    grid-template-columns:                  minmax(110px, 1fr) minmax(250px, 3fr) minmax(300px, 5fr) repeat(2, minmax(130px, 1fr)) minmax(60px, 1fr);
    border-bottom:                          1px solid rgba(0, 0, 0, 0.05);
}

.street-config-wrapper .user-table .table-body .table-row .table-row-item {
    display:                                flex;
    padding:                                10px 20px;
    position:                               relative;
}

.street-config-wrapper .user-table .table-body .table-row .table-row-item:first-of-type {
    display:                                flex;
    align-items:                            center;
    padding:                                10px;
}

.street-config-wrapper .user-table .table-body .table-row .table-row-item .profile-picture {
    width:                                  60px;
    height:                                 60px;
    object-fit:                             cover;
    object-position:                        center center;
    aspect-ratio:                           1 / 1;
    border:                                 3px solid transparent;
    border-radius:                          50%;
    background:                             var(--gradient) no-repeat center center;
    background-size:                        70px 70px;
    background-clip:                        border-box;
}

.street-config-wrapper .user-table .table-body .table-row .table-row-item .no-image-container {
    display:                                flex;
    justify-content:                        center;
    align-items:                            center;
    width:                                  60px;
    height:                                 60px;
    border-radius:                          50%;
    background:                             #fff;
    padding:                                2px 0 0 4px;
}

.street-config-wrapper .user-table .table-body .table-row .table-row-item .no-image-container .no-image {
    width:                                  30px;
    height:                                 30px;    
}

.street-config-wrapper .user-table .table-body .table-row .table-row-item {
    align-self:                             center;
}

.street-config-wrapper .user-table .table-body .table-row #clientMail > span {
    overflow:                               hidden;
    white-space:                            nowrap;
    text-overflow:                          ellipsis;
}

.street-config-wrapper .user-table .table-body .table-row #userStatus > .inactive {
    color:                                  var(--red);
}

.street-config-wrapper .user-table .table-body .table-row .options > img {
    width:                                  25px;
    height:                                 25px;
    object-fit:                             contain;
    object-position:                        center center;
    cursor:                                 pointer;
    justify-self:                           center;
}

.street-config-wrapper .user-table .table-body .table-row .options {
    justify-self:                           center;
}

.street-config-wrapper .user-table .table-body .table-row .options .moreOptions {
    display:                                flex;
    position:                               absolute;
    background:                             rgba(255, 255, 255, 0.9);
    box-shadow:                             0px 15px 50px 0px rgba(4, 26, 57, 0.15);
    right:                                  0;
    flex-flow:                              column nowrap;
    width:                                  120px;
    overflow:                               hidden;
    max-height:                             0px;
    z-index:                                9999;
    opacity:                                0;
    visibility:                             hidden;
    transition:                             all 0.3s ease-in-out;
    transform:                              translateY(30%);
}

.street-config-wrapper .user-table .table-body .table-row .options .moreOptions.showUserMenu {
    max-height:                             1000px;
    opacity:                                1;
    visibility:                             visible;
}

.street-config-wrapper .user-table .table-body .table-row:last-of-type .options .moreOptions, 
.street-config-wrapper .user-table .table-body .table-row:nth-last-child(2) .options .moreOptions {
    transform:                              translateY(-100%);
}

.street-config-wrapper .user-table .table-body .table-row .options .moreOptions .moreOptions-item {
    padding:                                10px 35px;
    cursor:                                 pointer;
}

.street-config-wrapper .user-table .table-body .table-row .options .moreOptions .moreOptions-item.disable {
    color:                                  var(--red);
}

.street-config-wrapper .user-table .table-body .table-row .options .moreOptions .moreOptions-item.enable {
    color:                                  var(--green);
}

.street-config-wrapper .user-table .table-body .table-row .options .moreOptions .moreOptions-item:hover {
    background:                             var(--gradient), rgba(4, 26, 57, 0.10);
    background-clip:                        text, padding-box;
    -webkit-background-clip:                text, padding-box;
    -webkit-text-fill-color:                transparent;
}

.street-config-wrapper .user-table .table-body .table-row .options .moreOptions .moreOptions-item.disable:hover {
    background:                             rgba(4, 26, 57, 0.10);
    background-clip:                        padding-box;
    -webkit-background-clip:                padding-box;
    -webkit-text-fill-color:                var(--red);
}

.street-config-wrapper .user-table .table-body .table-row .options .moreOptions .moreOptions-item.enable:hover {
    -webkit-text-fill-color:                var(--green);
}

/* Edit/Add user */

.street-config-wrapper .new-edit-user-section {
    display:                                flex;
    flex-direction:                         column;
}

.street-config-wrapper .new-edit-user-section .inputContainer input:disabled {
    opacity:                                0.5;
    cursor:                                 not-allowed;
}

.street-config-wrapper .new-edit-user-section .dropdowns-container {
    display:                                flex;
    gap:                                    25px;
}

.street-config-wrapper .new-edit-user-section .dropdowns-container .inputContainer {
    display:                                flex;
    flex-direction:                         column;
    gap:                                    5px;
    position:                               relative;
}


.street-config-wrapper .new-edit-user-section .dropdowns-container .inputContainer .dropdown-button {
    display:                                flex;
    align-items:                            center;
    justify-content:                        flex-start;
    padding:                                5px 40px 5px 20px;
    border:                                 none;
    color:                                  rgba(0, 0, 0, 0.7);
    background-color:                       #fff;
    cursor:                                 pointer;
    border-bottom:                          1px solid transparent;
    transition:                             all 0.3s ease-in-out;
    position:                               relative;
    min-width:                              130px;
}

.street-config-wrapper .new-edit-user-section .dropdowns-container .inputContainer .dropdown-button.active {
    color:                                  rgba(0, 0, 0, 1);
}

.street-config-wrapper .new-edit-user-section .dropdowns-container .inputContainer .dropdown-button::after {
    content:                                "";
    background:                             url('../../images/arrowDown.svg') no-repeat center center;
    transition:                             all 0.3s ease-in-out;
    width:                                  16px;
    height:                                 16px;
    position:                               absolute;
    top:                                    50%;
    right:                                  8px;
    transform:                              translateY(-50%);
}

.street-config-wrapper .new-edit-user-section .dropdowns-container .inputContainer .dropdown-button.active::after {
    transform:                              translateY(-50%) rotate(180deg);
}

.street-config-wrapper .new-edit-user-section .dropdowns-container .inputContainer .dropdown-button:hover {
    color:                                  rgba(0, 0, 0, 1);
    border-bottom:                          1px solid #009AFF;
}

.street-config-wrapper .new-edit-user-section .dropdowns-container .inputContainer .dropdown-list {
    opacity:                                0;
    visibility:                             hidden;
    position:                               absolute;
    top:                                    100%;
    left:                                   0;
    width:                                  100%;
    background:                             #fff;
    box-shadow:                             0px 5px 30px 0px rgba(35, 107, 216, 0.15);
    transition:                             all 0.3s ease-in-out;
    z-index:                                5;
}

.street-config-wrapper .new-edit-user-section .dropdowns-container .inputContainer .dropdown-list.show {
    opacity:                                1;
    visibility:                             visible;
}

.street-config-wrapper .new-edit-user-section .dropdowns-container .inputContainer .dropdown-list span {
    padding:                                10px 20px;
    cursor:                                 pointer;
}

.street-config-wrapper .new-edit-user-section .dropdowns-container .inputContainer .dropdown-list span:hover {
    background:                             var(--gradient), rgba(4, 26, 57, 0.10);
    background-clip:                        text, padding-box;
    -webkit-background-clip:                text, padding-box;
    -webkit-text-fill-color:                transparent;
}

.street-config-wrapper .new-edit-user-section .actions-container {
    display:                                flex;
    align-self:                             flex-end;
    gap:                                    20px;
    margin-top:                             50px;
}

.street-config-wrapper .new-edit-user-section .actions-container .confirmButton {
    order:                                  2;
    background:                             var(--blue);
    background-clip:                        border-box;
    -webkit-background-clip:                border-box;
    padding:                                10px 50px;
    border-radius:                          0;
    transition:                             all 0.3s ease-in-out;
    border:                                 unset;
}

.street-config-wrapper .new-edit-user-section .actions-container button:focus-visible {
    outline:                                unset;
}

.street-config-wrapper .new-edit-user-section .actions-container button:active {
    border:                                 unset;
}


.street-config-wrapper .new-edit-user-section .actions-container .confirmButton:hover {
    background:                             var(--darkBlue);
}

.street-config-wrapper .new-edit-user-section .actions-container .confirmButton:disabled {
    background:                             rgba(0, 0, 0, 0.05);
    cursor:                                 not-allowed;
}

.street-config-wrapper .new-edit-user-section .actions-container .confirmButton > span {
    background:                             var(--gradient);
    background-clip:                        text;
    -webkit-background-clip:                text;
    -webkit-text-fill-color:                transparent;
}

.street-config-wrapper .new-edit-user-section .actions-container .confirmButton:disabled > span {
    background:                             rgba(0, 0, 0, 0.40);
    background-clip:                        text;
    -webkit-background-clip:                text;
    -webkit-text-fill-color:                transparent;
}

@media (width <= 991px) {
    .street-config-wrapper .head-wrapper {
        flex-direction:                     column;
        gap:                                20px;
    }

    .street-config-wrapper .user-table {
        scrollbar-width:                    none;
        background-image:                   linear-gradient(to right, var(--grey), var(--grey)),
                                            linear-gradient(to right, var(--grey), var(--grey)),
                                            linear-gradient(to right, rgba(111, 113, 116, 0.5),rgba(245, 246, 247, 0.5)),
                                            linear-gradient(to left, rgba(111, 113, 116, 0.5), rgba(245, 246, 247, 0.5));
        background-position:                left center, right center, left center, right center;
        background-repeat:                  no-repeat;
        background-size:                    40px 100%, 40px 100%, 40px 100%, 40px 100%;
        background-attachment:              local, local, scroll, scroll;
    }

    .street-config-wrapper .head-wrapper .head-text > h5 {
        width:                              100%;
    }

    .street-config-wrapper .head-wrapper .searchContainer {
        width:                              100%;
    }
}