.mobile-footer {
    display:                        flex;
    width:                          100%;
    height:                         73px;
    position:                       fixed;
    bottom:                         0;
    background-color:               #fff;
    z-index:                        100;
    box-shadow:                     0 -3px 20px 0 rgba(7, 27, 57, 0.1);
}

.mobile-footer .footer-section #Client::before {
    content:                        "";
    width:                          30px;
    height:                         30px;
    mask:                           url(../../images/person_alone.svg) center center no-repeat;
    background:                     rgba(0, 0, 0);
    display:                        flex;
    margin:                         0 auto;
}

.mobile-footer .footer-section #Assumptions::before {
    content:                        "";
    width:                          30px;
    height:                         30px;
    mask:                           url(../../images/data_table.svg) center center no-repeat;
    background:                     rgba(0, 0, 0);
    display:                        flex;
    margin:                         0 auto;
}

.mobile-footer .footer-section #Musts::before {
    content:                        "";
    width:                          30px;
    height:                         30px;
    mask:                           url(../../images/checklist_rtl.svg) center center no-repeat;
    background:                     rgba(0, 0, 0);
    display:                        flex;
    margin:                         0 auto;
}

.mobile-footer .footer-section #Wants::before {
    content:                        "";
    width:                          30px;
    height:                         30px;
    mask:                           url(../../images/percentage.svg) center center no-repeat;
    background:                     rgba(0, 0, 0);
    display:                        flex;
    margin:                         0 auto;
}

.mobile-footer .footer-section {
    display:                        flex;
    flex-direction:                 column;
    width:                          25%;
    align-items:                    center;
    justify-content:                center;
    padding:                        30px;
}


.mobile-footer .footer-section .title {
    font-size:                      10px;
    font-weight:                    700;
    margin-top:                     5px;
    text-align:                     center;
    opacity:                        0.4;
}

.mobile-footer .footer-section .title.active {
    opacity:                        1;
}

.mobile-footer .footer-section .title.active::before {
    background:                     var(--gradient) !important;
}

.mobile-footer .footer-section .disabled {
    pointer-events:                 none;
    cursor:                         not-allowed;
    opacity:                        0.1;
}