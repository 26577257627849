.inputGroupContainer {
    margin-bottom:                      15px;
}

.inputGroupContainer .border-info-input:disabled {
    opacity:                            50%;
    cursor:                             not-allowed;
}

.inputGroupContainer .file-container {
    display:                            flex;
    align-items:                        center;
    gap:                                15px;
}

.inputGroupContainer .file-container .border-file-input {
    background-color:                   transparent;
    padding:                            10px 15px;
    border:                             unset;
    background:                         var(--blue);
    cursor:                             pointer;
    transition:                         all 0.3s ease-in-out; 
}

.inputGroupContainer .file-container .border-file-input > span {
    background:                         var(--gradient);
    background-clip:                    text;
    -webkit-background-clip:            text;
    -webkit-text-fill-color:            transparent;
}

.inputGroupContainer .file-container .border-file-input:disabled {
    cursor:                             not-allowed;
    opacity:                            unset;
    background:                         rgba(0, 0, 0, 0.3);
}

.inputGroupContainer .file-container .border-file-input:disabled > span {
    background:                         rgba(0, 0, 0, 0.7);
    background-clip:                    text;
    -webkit-background-clip:            text;
    -webkit-text-fill-color:            transparent;
}

.inputGroupContainer .file-container .border-file-input:hover:not(:disabled) {
    background:                         var(--darkBlue);
}

.inputGroupContainer .file-container .border-file-input:hover:not(:disabled) > span {
    background:                         var(--gradient);
    background-clip:                    text;
    -webkit-background-clip:            text;
    -webkit-text-fill-color:            transparent;
}
