/* SUMMARY */
.summary-wrapper{
    width:                          100%;
    height:                         100%;
    overflow-y:                     auto;
    transition:                     left 1s ease;
    position:                       absolute;
    padding:                        160px 0 100px 0;
    left:                           -100%;
    top: 							0;
    z-index:                        3;
    background-color:               var(--grey);
}

.summary-wrapper.show{
	left: 0;
	box-shadow:                 30px 0px 30px 0px rgba(35, 107, 216, 0.5);
}

.summary-wrapper .summary-header{
    display:                        flex;
    align-items:                    center;
    font-weight:                    700;  
    justify-content:                space-between;  
}

.summary-wrapper .summary-header .summary-title-container{
    display: flex;
}

.summary-wrapper .summary-header >div> .return-summary{
    background-color:               unset;
    border:                         unset;
    width:                          25px;
    height:                         25px;
    background:                     url("../../images/expand_less.svg") center center no-repeat;
    transform:                      rotate(270deg);
    background-size:                25px 25px;
    margin-right:                   5px;
}

.summary-wrapper .row{
    margin-top:                     30px;
}

.summary-wrapper .no-gutters > [class*='col-'] {
	padding-right:                      0;
	padding-left:                        0;
    padding-bottom:                      20px;
}

.summary-wrapper .table-head-fixed{
    background:                         white;
    padding:                            25px 25px 25px 45px;
    font-weight:                        700;
}
.summary-wrapper .table-row-fixed{
    font-weight:                        700;
    padding:                            35px 25px 25px 45px;
    border-bottom:                      1px solid rgba(0, 0, 0, 0.05);
    height:                             91px;
}

.summary-wrapper .table-row-fixed.points{
    font-weight:                        400;
    opacity:                            0.6;
}

.summary-wrapper .table-header{
    display:                            flex;
}

.summary-wrapper .table-header .city-title{
    font-weight:                        700;
    padding:                            25px;
    min-width:                          33%;
    background:                       white;
    text-align:                         center;
}

.summary-wrapper .table-row{
    display:                            flex;
    align-items:                        center;
}

.summary-wrapper .table-row .number{
    min-width:                          33%;
    padding:                            25px ;
    border-bottom:                      1px solid rgba(0, 0, 0, 0.05);
    text-align:                         center;
    height:                             91px;
}


.summary-wrapper .table-row .number .regular{
    line-height:                        3;
}

.summary-wrapper .table-row .number .highest{
    background:                     rgba(25, 218, 171, 0.30);
    padding:                            10px 0px;
}

.summary-wrapper .table-row .number .highest-total{
    background:                       rgba(25, 218, 171, 0.70);
    padding:                            10px 0px;
    font-weight:                        700;
}

.summary-wrapper .table-container{
    overflow-x:                         auto;
}

  /* Mobile */

.mobile {
    margin-top:                         0px !important;
}

.summary-start {
    display:                            flex;
    justify-content:                    space-between;
    align-items:                        center;
    padding:                            15px 25px;
    background:                         white;
}

.summary-cat-section .summary-cat-header {
    display:                            flex;
    justify-content:                    space-between;
    align-items:                        center;
    padding:                            10px 25px;
    background:                         rgba(0, 0, 0, 0.03);
}

.summary-cat-section .summary-cat-header > h5,
.summary-cat-section .summary-scores .city-score > h5,
.summary-total-scores .city-total-score > h5 {
    font-weight:                        700;
    font-size:                          12px;
}

.summary-cat-section .summary-cat-header > span,
.summary-cat-section .summary-scores .city-score > span {
    font-weight:                        400;
    font-size:                          12px;
    opacity:                            0.6;
}

.summary-cat-section .summary-scores,
.summary-total-scores {
    display:                            flex;
    align-items:                        center;
    padding:                            20px 0;
    overflow-x:                         auto;
}

.summary-cat-section .summary-scores .city-score,
.summary-total-scores .city-total-score {
    display:                            flex;
    text-align:                         center;
    flex-direction:                     column;
    align-items:                        center;
    justify-content:                    center;
    padding:                            0 10px;
    width:                              33.3%;
    gap:                                5px;
}

.summary-cat-section .summary-scores .city-score > span,
.summary-total-scores .city-total-score > span {
    padding:                            5px 10px;
}

.summary-cat-section .summary-scores .city-score .highest,
.summary-total-scores .city-total-score .highest {
    background:                         rgba(25, 218, 171, 0.30);
}

.summary-totals {
    align-items:                        center;
    padding:                            10px 25px;
    background:                         rgba(0, 0, 0, 0.03);
}

.summary-total-scores .city-total-score > span {
    font-size:                          16px;
    font-weight:                        700;
}
