.details-wrapper{
    width:                              100%;
	overflow-y: 						auto;
    position: 							absolute;
	left: 								100%;
	padding: 							145px 0;
	top: 								0;
	transition: 						left 1s ease-in-out;
	background-color: 					var(--grey);
	z-index: 							4;
	height: 							100%;
	box-shadow:                 		-10px 0px 0px 0px rgba(35, 107, 216, 0.1);
}

.details-wrapper.active{
	left: 								0;
}

.details-wrapper .title-section{
	display: 							flex;
	position: 							relative;
	align-items: 						center;
	justify-content: 					space-between;	
}

.details-wrapper .title-section .title{
	display: 							flex;
	align-items: 						center;
	gap: 								25px;
}

.details-wrapper .title-section .extra-buttons{
	display: 							flex;
	align-items: 						center;
	gap: 								10px;
	justify-content: right;
}

.details-wrapper .title-section .title .expand-icon{
    width:                          	20px;
    height:                         	20px;
    margin-right:                   	5px;
	cursor: pointer;
}

.details-wrapper .title-section	.extra-buttons> button{
	border: 							unset;
	background: 						rgba(0, 163, 255, 0.10);
	transition: 						background 0.2s ease-in-out;
	padding: 							6px 20px;
	display: 							flex;
	gap:								5px;
}

.details-wrapper .title-section	.extra-buttons > button > span{
	font-size: 							16px;
	font-weight: 						400;
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
}

.details-wrapper .title-section .extra-buttons > button:hover{
	background:							#041A39;
}

.details-wrapper .city-score-section{
	display: 							flex;
	padding: 							65px;
}

.details-wrapper .city-score-section.horizontal{
	padding: 	 	 	 	 	 	 	 65px 0px;
}

.details-wrapper .item-score-wrapper{
	padding-bottom: 	 	 	 	 	 10px;
	height: 100%;
}
.details-wrapper .item-score-wrapper:first-of-type{
	padding-right: 	 	 	 	 	 	 25px;
	padding-left:  	 	 	 	 	 	 0;
}

.details-wrapper .item-score-wrapper:nth-of-type(2){
	padding: 	 	 	 	 	 	 	 0 25px;
}

.details-wrapper .item-score-wrapper:last-of-type{
	padding-left: 		 	 	 	 	 25px;
	padding-right:  		 	 	 	 0;
}

.details-wrapper .city-score-section .city-score-item{
	background-color: 					white;
	padding: 							50px 100px;
	text-align: 						center;
	height: 100%;
}

.details-wrapper .city-score-section .city-score-item.horizontal{
	padding: 	 	 	 	 	 	 	20px 25px 20px 35px;
	display: 	 	 	 	 	 	 	flex;
	justify-content: 	 	 	 	 	space-between;
	align-items:  	 	 	 	 	 	center;
}

.details-wrapper .city-score-section .city-score-item.horizontal > div{
	display: 	 	 	 	 		 	 flex;
	flex-direction:  	 	 	 	 	 column;
	text-align: 	 	 	 	 	 	 left;
}

.details-wrapper .city-score-section .city-score-item.horizontal > div > h1{
	font-size: 	 	 	 	 	 		 45px;
}

.details-wrapper .city-score-section .city-score-item >div> p{
	font-size: 							10px;
	font-weight: 						500;
	opacity: 							0.5;
}

.details-wrapper .city-score-section .city-score-item > div> .score{
	font-size: 							90px;
	font-weight: 						400;
	color: 							#041A39;
	opacity: 							0.5;
	background-clip: 					text;
}

.details-wrapper .city-score-section .city-score-item >div>.score.highest{
	font-weight: 						500;
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
	opacity: 							1;
}

.details-wrapper .mobile-cities-score-section {
	display: 							flex;
	flex-direction: 					column;
	width: 								100%;
	gap: 								5px;
	margin: 							30px 10px;
	padding-inline: 					10px;
}

.details-wrapper .mobile-cities-score-section .city-section {
	display: 							flex;
	justify-content: 					space-between;
	align-items: 						center;
	width: 								100%;
	padding: 							20px 30px 15px 10px;
	background: 						#fff;
}

.details-wrapper .mobile-cities-score-section .city-section.highest {
	/* background: 						linear-gradient(180deg, #0099ff59 19.79%, #8ca5ff67 100%); */ /* original gradient with opacity */
	background: 						linear-gradient(180deg, #9FD5FA 19.79%, #CFDAFA 100%); /* different colors with the same result */
}

.details-wrapper .mobile-cities-score-section .city-section .score-section h4 {
	text-align: 						center;
}

.details-wrapper .mobile-cities-score-section .city-section .score-section h4 {
	opacity: 							0.4;
}

.details-wrapper .mobile-cities-score-section .city-section span {
	font-size: 							10px;
	font-weight: 						500;
	opacity: 							0.4;
}

.details-wrapper .mobile-cities-score-section .city-section.highest .score-section h4,
.details-wrapper .mobile-cities-score-section .city-section.highest span {
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
	opacity: 							1;
}

.fade.comments-modal.modal .modal-content{
	border-radius: 						0;
	border: 							unset;
	box-shadow: 						0px 50px 200px 0px rgba(4, 26, 57, 0.15);
}

.fade.comments-modal.modal .modal-header{
	display: 							flex;
	flex-direction: 					column-reverse;
	border: 							unset;
    padding:                        	20px 20px 15px 50px;

}

.fade.comments-modal.modal .modal-header .modal-title{
	margin-right: 						auto;
}

.fade.comments-modal.modal .modal-body{
	padding: 							45px 50px;
}

.fade.comments-modal.modal .modal-body .comment-save{
	background:                   	rgba(4, 26, 57, 0.04);
	overflow-y: show;
	border: none;
}

.fade.comments-modal.modal .modal-body .comment-save:focus{
	border: none;
}



.fade.comments-modal.modal .modal-footer{
	border:								unset;
	padding: 							0 50px 50px 0;
}

.fade.comments-modal.modal .modal-footer > button {
	border: 							unset;
	padding: 							10px 50px;
	background: 						rgba(0, 163, 255, 0.10);
	font-size: 							16px;
	font-weight: 						400px;
	transition: 						background 0.2s ease-in-out;
}

.fade.comments-modal.modal .modal-footer > button:hover {
	background-color: 				#041A39;
	color: 							white;
}

.details-wrapper .selector-buttons{
	display: 							flex;
	gap: 								10px;
	align-items: 						center;
	justify-content: 					flex-start;
	margin-bottom: 						10px;
}

.details-wrapper .selector-buttons .selector-tab{
	border: 							unset;
	background: 						white;
	padding: 							15px 40px;
	transition: 						background 0.2s ease-in-out;
	border-radius: 						0;
	margin-top: 						10px;
}

.details-wrapper .two-tables-container{
	display: 							flex;
	width: 								fit-content;
}

.details-wrapper .two-tables-container table:first-child{
	width: 								600px;
}

.details-wrapper .two-tables-container table:nth-child(2){
	width: 								500px;
	overflow-x: 						auto;
}

.details-wrapper .selector-buttons .selector-tab.active{
	background: 						#041A39;
}

.details-wrapper .selector-buttons .selector-tab.active>h5{
	color: 								white;
}

.details-wrapper .selector-buttons .selector-tab > h5{
	font-size: 							14px;
	font-weight: 						400;
	color: 								black;
}

.details-wrapper .selector-buttons .selector-tab > p{
	font-size: 							8px;
	font-weight: 						400;
}

.details-wrapper .no-gutters > [class*='col-'] {
	padding-right: 						0;
	padding-left: 						0;
}


.fade.criteria-modal.modal .modal-content{
	border-radius:  	 	 	 	 	0;
	border:  	 	 	 	 	  	 	none;
	box-shadow: 						0px 50px 200px 0px rgba(4, 26, 57, 0.15);
	padding: 	 	 	 	 	 	 	30px 50px 50px 50px;
}

.fade.criteria-modal.modal .modal-header{
	padding:  	 	 	 	 	 	 	0;
	justify-content:  	 	 	 	 	space-between;
	align-items:  	 	 	 	 	 	flex-start;
	align-self:  	 	 	 	 	 	stretch;
	border: 	  	 	 	 	 	 	none;
}



.fade.criteria-modal.modal .modal-header .modal-title.h4{
	font-size: 	 	 	 	 	 	 	16px;
	font-weight: 	 	 	 	 	 	700;
}

.fade.criteria-modal.modal .modal-body{
	border: 	 	 	 	 	 	 	none;
	padding: 	 	 	 	 	 	 	0;
	margin-top: 	 	 	 	 	 	15px;
}

.fade.fade.criteria-modal.modal .modal-body .criteria-modal-content{
	display: 	 	 	 	 	 	 	flex;
	flex-direction: 	 	 	 	 	column;
	gap: 	 	 	 	 	 	 		30px;
}

.fade.criteria-modal.modal .modal-body .criteria-modal-content .head-section{
	display: 	 	 	 	 	 	 	flex;
	justify-content: 	 	 	 	 	space-between;
	align-items: 	 	 	 	 	 	center;
}

.fade.criteria-modal.modal .modal-body .criteria-modal-content .head-section >h5:first-of-type{
	font-weight: 	 	 	 	 	 	 700;
}

.fade.criteria-modal.modal .modal-body .criteria-modal-content .head-section >h5:last-of-type{
	font-size: 	 	 	 	 	 	 	 14px;
	font-weight: 	 	 	 	 		 400;
	background:  	 	 	 		 	 var(--gradient);
	background-clip: 	 	 	 	 	 text;
	-webkit-background-clip: 	 	 	 text;
	-webkit-text-fill-color: 	 	 	 transparent;
}


.fade.criteria-modal.modal .modal-body .criteria-modal-content .body-section{
	display: 	 	 	 	 	 		 flex;
	justify-content:  		 	 	 	 space-between;
	gap: 	 	 	 	 		 	 	 10px;
}

.fade.criteria-modal.modal .modal-body .criteria-modal-content .body-section .criteria-sub{
	display: 	 	 	 	 	 	 	flex;
	flex-direction: 	 	 	 	 	column;
	flex: 	 	 	 	 	 	 	 	1 0 0;
	gap: 	 	 	 	 	 	 		10px;
}

.fade.criteria-modal.modal .modal-body .criteria-modal-content .body-section .criteria-sub>h5{
	font-weight: 	 	 	 	 	 	400;
}


.fade.criteria-modal.modal .modal-body .criteria-modal-content .body-section .criteria-sub .from-row{
	display: 	 	 	 	 	 	 	flex;
	gap: 	 	 	 	 	 	 	 	10px;
	justify-content: 	 	 	 	 	space-between;
}

.fade.criteria-modal.modal .modal-body .criteria-modal-content .body-section .criteria-sub .from-row .from-to{
	display: 	 	 	 	 	 	 	flex;
	flex: 	 	 	 	 	 	 	 	1 0 0;
	justify-content: 	 	 	 	 	space-between;
	padding: 	 	 	 		 	 	10px 10px 10px 15px;
	background: 	 	 	 	 		rgba(4, 26, 57, 0.02);
}

.fade.criteria-modal.modal .modal-body .criteria-modal-content .body-section .criteria-sub .from-row .from-to>h5:first-of-type{
	opacity: 	 	 	 	 	 	 	0.4;
}

.fade.criteria-modal.modal .modal-body .criteria-modal-content .body-section .criteria-sub .from-row .from-to>h5{
	font-weight: 	 	 	 	 	 	500;
}


.fade.criteria-modal.modal .modal-body .criteria-modal-content .body-section .criteria-sub .text-crit{
	display: 	 	 	 	 	 	 	flex;
	flex: 	 	 	 	 	 		 	1 0 0;
	justify-content: 	 	 	 	 	flex-end;
	padding: 	 	 	 	 	 		10px 10px 10px 15px;
	background: 	 	 	 	 		rgba(4, 26, 57, 0.02);
	font-weight:  	 	 	 	 	 	500;
}

.fade.criteria-modal.modal .modal-body .criteria-modal-content .body-section .points-sub{
	display: 	 	 	 	 	 	 	flex;
	flex-direction: 	 	 	 	 	column;
	gap: 	 	 	 	 	 	 	 	10px;
	justify-content: 	 	 	 	 	space-between;
}
.fade.criteria-modal.modal .modal-body .criteria-modal-content .body-section .points-sub>h5{
	font-weight: 	 	 	 	 	 	400;
	text-align: 	 	 	 	 	 	left;
}


.fade.criteria-modal.modal .modal-body .criteria-modal-content .body-section .points-sub .point{
	border-radius: 	 	 	 	 	 	10px;
	background: 	 	 	 	 		rgba(4, 26, 57, 0.02);
	text-align: 	 	 	 	 	 	center;
	font-weight:  		 	 	  		400;
	padding: 							10px;
	
}

.details-wrapper .table-container{
	overflow-x: 						auto;
}

.details-wrapper .table-heading{
	padding: 							25px 10px;
	background-color: 					white;
	font-weight: 						700;
}

.details-wrapper .table-heading.qty,
.details-wrapper .table-number.qty
{
	padding: 							25px 10px 25px 30px;
	text-align: 						left;
}

.details-wrapper .separator-row{
	background: 						rgba(7, 27, 57, 0.05);
	padding: 							25px 10px;
	height: 							74px;
	width: 								100%;
	font-weight: 						700;
}


.details-wrapper .table-number{
	padding: 							25px 10px;
	text-align: 						center;
	border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
	height: 							90px;
	line-height: 						2;

}

.details-wrapper .table-text{
	padding: 							25px 10px;
	font-weight: 						700;
	border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
	height: 							90px;
	line-height: 						2;

}


.details-wrapper .table-container .table-p-header{
	display: 							flex;
}

.details-wrapper .table-container .table-p-header .table-heading{
	min-width: 							35%;
	height: 							70px;
	text-align: 						center;
}

.details-wrapper .table-container .score-row{
	display: 							flex;
}

.details-wrapper .table-container .score-row .table-number{
	min-width: 							35%;
}

.details-wrapper .table-container .final-row,
.details-wrapper .table-container .red-data,
.details-wrapper .table-container .extra-data
{
	display: 							flex;
}

.details-wrapper .table-container .final-row > h5,
.details-wrapper .table-container .extra-data > h5,
.details-wrapper .table-container .red-data > h5{
	min-width: 							35%;
}


.details-wrapper .second-lowest >span {
	background: 						rgba(25, 218, 171, 0.30);
	text-align: 						center;
	padding: 							10px;
}

.details-wrapper .lowest >span{
	background: 						rgba(25, 218, 171, 0.70);
	padding: 							10px;
}

.details-wrapper .table-container .final-row .table-number >span{
	padding: 							10px;
}

.details-wrapper .table-container .extra-data .table-number{
	opacity: 							0.6;
}


.details-wrapper .table-container .red-data > h5{
	color:  							#FF316F;
	text-align: 						center;
	padding: 							10px;
}

.details-wrapper .slick-track{
	display: 							flex !important;
}

.details-wrapper .slick-slide{
	height: 							inherit !important;
}

.details-wrapper .slick-slide > div:first-of-type{
	height: 							100% !important;
}



.payroll-table-section table {
	display: 							block;
	white-space: 						nowrap;
	overflow-x: 						auto;
	scrollbar-width:            		none;
    background-image:           		linear-gradient(to right, var(--grey), var(--grey)),
										linear-gradient(to right, var(--grey), var(--grey)),
										linear-gradient(to right, rgba(188, 190, 200, 0.5),rgba(245, 246, 247, 0.5)),
										linear-gradient(to left, rgba(188, 190, 200, 0.5), rgba(245, 246, 247, 0.5));
    background-position:        		left center, right center, left center, right center;
    background-repeat:          		no-repeat;
    background-size:            		40px 100%, 40px 100%, 40px 100%, 40px 100%;
    background-attachment:      		local, local, scroll, scroll;
}

.payroll-table-section thead {
	background:                 		rgba(255, 255, 255, 0.7);
    box-shadow:                 		0px 5px 30px 0px rgba(35, 107, 216, 0.08);
}

.payroll-table-section thead th,
.payroll-table-section tbody tr td{
	padding:                    		20px;
    font-size:                  		12px;
}

.payroll-table-section tbody tr td:nth-child(1) {
	text-align: 						center;
}

/* .payroll-table-section tbody tr td:not(:nth-child(1)):not(:nth-child(2)) {
	text-align: 						right;
} */



.payroll-table-section tbody tr {
	border-bottom: 						1px solid rgba(7, 27, 57, 0.05);
}



@media screen and (width <= 991px) {
	.details-wrapper .title-section {
		flex-direction: 				column;
		gap: 							10px;
	}
	
	.details-wrapper .title-section .title {
		gap: 							5px;
	}

	.details-wrapper .title-section .extra-buttons{
		flex-wrap: 						wrap;
		justify-content: 				start;		
	}

	.details-wrapper .selector-buttons .col {
		overflow-x: 					auto;
		scrollbar-width: 				none;
		margin-top: 					10px;
		background-image:           	linear-gradient(to right, var(--grey), var(--grey)),
										linear-gradient(to right, var(--grey), var(--grey)),
										linear-gradient(to right, rgba(50, 70, 50, 0.5),rgba(245, 246, 247, 0.5)),
										linear-gradient(to left, rgba(50, 70, 50, 0.5), rgba(245, 246, 247, 0.5));
		background-position:        	left center, right center, left center, right center;
		background-repeat:          	no-repeat;
		background-size:            	40px 100%, 40px 100%, 40px 100%, 40px 100%;
		background-attachment:      	local, local, scroll, scroll;
	}

	.details-wrapper .selector-buttons .nav {
		display: 						flex;
		flex-direction: 				row;
		width: 							max-content;
		flex-wrap: 						nowrap;
	}
	
	.details-wrapper .selector-buttons .selector-tab {
		margin-top: 					unset;
		background: 					rgba(255, 255, 255, 0.75);
		padding: 						10px 20px;
	}

	.details-wrapper .selector-buttons .selector-tab > p {
		font-size: 						12px;
		background: 					var(--gradient);
		color: 							unset;
		background-clip: 				text;
		-webkit-background-clip: 		text;
		-webkit-text-fill-color: 		transparent;
	}
}