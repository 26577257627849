.config-pdf-container .title-activate-section .switch {
    position:                       relative;
    display:                        inline-block;
    width:                          100px;
    height:                         39px;
}

  /* Hide default HTML checkbox */
.config-pdf-container .title-activate-section .switch input {
    opacity:                        0;
    width:                          0;
    height:                         0;
}

/* lable inside the slider */
.insideLabel {
    position:                       absolute;
    top:                            8px;
    z-index:                        2;
    cursor:                         pointer;
    pointer-events:                 none;
    transition:                     all 0.1s ease-out;
}

.config-pdf-container .title-activate-section .switch #label-on {
    left:                           15px;
    color:                          white;
    opacity:                        1;
    visibility:                     visible;
}

.config-pdf-container .title-activate-section .switch #label-on.hidden {
    opacity:                        0;
    visibility:                     hidden;
}

.config-pdf-container .title-activate-section .switch #label-off {
    left:                           39px;
    color:                          grey;
    opacity:                        1;
    visibility:                     visible;
}

.config-pdf-container .title-activate-section .switch #label-off.hidden {
    opacity:                        0;
    visibility:                     hidden;
}

  /* The slider */
.config-pdf-container .title-activate-section .slider {
    position:                       absolute;
    cursor:                         pointer;
    top:                            0;
    left:                           0;
    right:                          0;
    bottom:                         0;
    background-color:               white;
    -webkit-transition:             .4s;
    transition:                     .4s;
}

.config-pdf-container .title-activate-section .slider:before {
    position:                       absolute;
    content:                        "";
    height:                         26px;
    width:                          26px;
    left:                           4px;
    bottom:                         7px;
    background-color:               rgba(4, 26, 57, 0.3);
    -webkit-transition:             .35s;
    transition:                     .35s;
}

.config-pdf-container .title-activate-section .slider.isChecked:before {
    background-color:               white;
}

.config-pdf-container .title-activate-section input:checked + .slider {
    background-color:               rgba(8, 209, 161, 1);
}

.config-pdf-container .title-activate-section input + .slider {
    background-color:               var(--grey);
}

.config-pdf-container .title-activate-section input:focus + .slider {
    box-shadow:                     0 0 1px rgba(0, 163, 255, 0.1)
}

.config-pdf-container .title-activate-section input:checked + .slider:before {
    -webkit-transform:              translateX(67px);
    -ms-transform:                  translateX(67px);
    transform:                      translateX(67px);
}

  /* Rounded sliders */
.config-pdf-container .title-activate-section .slider.round {
    border-radius:                  34px;
}

.config-pdf-container .title-activate-section .slider.round:before {
    border-radius:                  50%;
}

.config-pdf-container {
	padding: 						135px 0 230px 0;
	position: 						absolute;
	top: 							0;
	right: 							0;
	background: 					var(--grey);
	width: 							100vw;
	opacity: 						0;
	visibility: 					hidden;
	height: 						100%;
	transition: 					all 0.3s ease-in-out;
	overflow-y: 					hidden;
    border-radius:                  35px 35px 0px 0px;
}

.config-pdf-container.show {
	opacity: 						1;
	visibility: 					visible;
	height: 						auto;
	min-height: 					100%;
}

.config-pdf-container .closeImg {
    position:                       absolute;
    top:                            30px;
    right:                          30px;
    cursor:                         pointer;
}

.config-pdf-container .main-container {
    display:                        grid;
    position:                       relative;
    grid-template-columns:          1fr 4fr;
    column-gap:                     80px;
    padding:                        20px;
    height:                         900px;
    background-color:               white;
}

.config-pdf-container .title-activate-section {
    display:                        flex;
    align-items:                    center;
    justify-content:                space-between;
    padding-right:                  50px;
}

.config-pdf-container .title-activate-section .activate-container {
    display:                        flex;
    align-items:                    center;
    gap:                            10px;
}

.config-pdf-container .savePdfConfig {
    position:                       absolute;
    top:                            30px;
    right:                          30px;
    padding:                        10px 40px;
}

/* NAV SECTION CSS */
.config-pdf-container .nav-section {
    background-color:               var(--blue);
}

.config-pdf-container .option-container {
    display:                        flex;
    align-items:                    center;
    gap:                            10px;
    padding:                        15px 10px;
    border-radius:                  10px;
    cursor:                         pointer;
}

.config-pdf-container .option-container.selected {
    background-color:               var(--darkBlue);
}

.config-pdf-container .option-container.selected > .option-title {
    color:                          var(--green);
}
.config-pdf-container .option-container > .option-title {
    color:                          rgba(0, 0, 0, 0.5);
    font-size:                      14px;
}

.config-pdf-container .option-container .option-number {
    display:                        flex;
    border:                         1px solid var(--green);
    border-radius:                  50%;
    width:                          20px;
    height:                         20px;
    align-items:                    center;
    justify-content:                center;
    color:                          var(--green);
}

.config-pdf-container .option-container .option-number.checked {
    background-color:               var(--green);
}

.config-pdf-container .option-container .option-number > span {
    font-size:                      14px;
    transform:                      translateY(1px);
}

/* FORMS SECTION CSS */

.config-pdf-container .forms-section {
    display:                        flex;
    flex-direction:                 column;
    padding-top:                    100px;
    overflow-y:                     hidden;
}

.config-pdf-container .forms-section .inputs-area {
    margin-bottom:                  30px;
    overflow-y:                     auto;
    padding-right:                  40px;
}

.config-pdf-container .forms-section > header {
    margin-bottom:                  50px;
}

.config-pdf-container .forms-section .inputs-area > h3 {
    margin-bottom:                  20px;
}

.tooltip-img {
    height:                         250px;
    width:                          auto;
    object-fit:                     cover;
}

.config-pdf-container .forms-section .actions-save-container {
    align-self:                     flex-end;
    margin-top:                     auto;
}

.config-pdf-container .forms-section .actionsContainer {
    display:                        flex;
    align-items:                    center;
    gap:                            10px;
    align-self:                     flex-end;
    margin-top:                     auto;
}

.config-pdf-container .forms-section .actionsContainer .secondaryButton{
    padding-inline:                 70px;
}

.config-pdf-container .forms-section .input-label-container > label {
    font-weight:                    500;
}

.config-pdf-container .forms-section .inputContainer {
    display:                        flex;
    flex-direction:                 column;
}

.config-pdf-container .forms-section .inputContainer > input,
.config-pdf-container .forms-section .inputContainer > textarea {
    background-color:               var(--grey);
}

.config-pdf-container .forms-section .inputContainer > textarea {
    min-height:                     150px;
}

.config-pdf-container .forms-section .inputContainer .input-label-container {
    display:                        flex;
    align-items:                    center;
    gap:                            5px;
}

.config-pdf-container .forms-section .inputContainer .input-label-container > span {
    color:                         rgba(0, 0, 0, 0.5);
    pointer-events:                 none;
}

/* .config-pdf-container .forms-section .inputContainer .input-label-container > img {
    cursor:                         pointer;
} */

.config-pdf-container .forms-section .inputContainer .openAIButtonContainer {
    display:                        flex;
    align-items:                    center;
    gap:                            10px;
    margin-top:                     10px;
    align-self:                     flex-end;
}

.config-pdf-container .forms-section .inputContainer .openAIButtonContainer > img {
    width:                          32px;
}

.config-pdf-container .forms-section .inputContainer .chatgptButton::after {
    content:                        "";
    mask:                           url("../../images/openai.svg") center center no-repeat;
    background:                     var(--gradient);
    background-size:                20px;
    height:                         20px;
    width:                          20px;
    transition:                     background 0.3s ease-in-out;
}

.config-pdf-container .forms-section .inputContainer .chatgptButton:disabled::after {
    background:                     grey;
}

.config-pdf-container .forms-section .extra-options-container {
    display:                        flex;
    gap:                            5px;
    margin-bottom:                  20px;
}

.config-pdf-container .forms-section .extra-options-container .option-container.selected .extra-option-title {
    color:                          var(--green);
}

.config-pdf-container .forms-section .city-forms-container {
    margin-bottom:                  80px;
}

.config-pdf-container .forms-section .cityTitle {
    font-weight:                    700;
    margin-bottom:                  10px;
}

.config-pdf-container .forms-section .imgButton {
    display:                        flex;
    gap:                            10px;
    padding-inline:                 50px;
    max-width:                      250px;
    white-space:                    nowrap;
}

.config-pdf-container .forms-section .imgButton::after {
    content:                        "";
    mask:                           url("../../../assets/images/attach_blue.svg") no-repeat center center;
    height:                         20px;
    width:                          20px;
    background:                     var(--gradient);
    background-size:                20px 20px;
}

.config-pdf-container .forms-section .image-name-container {
    display:                        flex;
    align-items:                    center;
    gap:                            15px;
    margin-top:                     10px;
}

.config-pdf-container .forms-section .image-name-container > a {
    white-space:                    nowrap;
}

.config-pdf-container .forms-section .image-name-container > span {
    white-space:                    nowrap;
    overflow:                       hidden;
    text-overflow:                  ellipsis;
}

.config-pdf-container .forms-section .want-title-container {
    display:                        flex;
    align-items:                    center;
    gap:                            15px;
}

.config-pdf-container .forms-section .want-title-container > h5 {
    font-weight:                    700;
}

@media (width <= 991px) {
    .config-pdf-container .main-container {
        grid-template-columns:          1fr;
        padding:                        10px;
        height:                         auto;
    }

    .config-pdf-container .nav-section {
        padding:                        30px 20px;
        overflow:                       hidden;
    }

    .config-pdf-container .nav-section > header {
        display:                        flex;
        flex-direction:                 column;
        gap:                            10px;
        margin-bottom:                  20px;
    }

    .config-pdf-container .nav-section > header > span {
        font-size:                      14px;
    }
    
    .config-pdf-container .title-activate-section {
        align-items:                    flex-end;
        padding-right:                  0;
    }

    .config-pdf-container .title-activate-section > h4 {
        font-weight:                    700;
    }
    
    .config-pdf-container .title-activate-section .activate-container {
        display:                        flex;
        flex-direction:                 column;
        align-items:                    center;
        gap:                            5px;
    }

    .config-pdf-container .title-activate-section .activate-container > span {
        font-size:                      8px;
        font-weight:                    400;
    }

    .insideLabel {
        font-size:                      12px;
        top:                            10px;
    }

    .config-pdf-container .title-activate-section .switch #label-on {
        left:                           20px;
    }

    .config-pdf-container .nav-section .nav-options-container {
        display:                        flex;
        gap:                            5px;
        overflow-x:                     auto;
    }
    
    .config-pdf-container .nav-section .nav-options-container::-webkit-scrollbar {
        height:                         0px;
    }

    .config-pdf-container .nav-section .nav-options-container .option-container {
        border:                         1px solid rgba(0, 154, 255, 1);
    }
    
    .config-pdf-container .nav-section .nav-options-container span {
        white-space:                    nowrap;
    }

    .config-pdf-container .forms-section {
        padding-top:                    50px;
    }

    .config-pdf-container .forms-section .inputs-area > h3 {
        font-size:                      20px;
    }
    
    .config-pdf-container .forms-section .inputContainer .input-label-container > img {
        margin-left:                    auto;
    }

    .config-pdf-container .forms-section .input-label-container > label,
    .config-pdf-container .forms-section .inputContainer .input-label-container > span {
        font-size:                      14px;
    }

    .config-pdf-container .forms-section .actions-save-container {
        display:                        flex;
        flex-direction:                 column;
        gap:                            5px;
        position:                       fixed;
        bottom:                         80px;
        left:                           20px;
        right:                          20px;
        background-color:               #fff;
        padding:                        10px;
    }

    .config-pdf-container .forms-section .actions-save-container .actionsContainer {
        width:                          100%;
    }

    .config-pdf-container .forms-section .actionsContainer > button {
        width:                          100%;
        background:                     rgba(241, 242, 245, 0.3);
        -webkit-text-fill-color:        #000;
    }

    .config-pdf-container .forms-section .actionsContainer > button:hover {
        background:                     rgba(241, 242, 245, 0.3);
        -webkit-text-fill-color:        #000;
    }
}