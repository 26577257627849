/* Navigation Menu */
.navigation {
	flex: 							0 0 315px;
	position:    	 				relative;
	width: 							315px;
	height: 						100vh;
	height: 						100dvh;
	height:  						auto;
	background-color: 				#FFFFFF;
	box-shadow:						15px 15px 100px 0px rgba(4, 26, 57, 0.10);
	top: 							0;
	left: 							0;
	z-index: 						999;
	transition: 					all 0.3s ease-in-out;
}

.animated-navigation {
	animation: 						animateNavigation 1s forwards 2s;
}

.navigation .logo{
	width: 							130px;
	margin-top: 					20px;
	margin-left: 					20px;
	margin-bottom: 					45px;
	object-fit: 					contain;
	object-position: 				center center;
}
.navigation .logo-settings-wrapper{
	display: 						flex;
}

.navigation .profile{
	width: 							130px;
	height: 						130px;
	object-fit: 					cover;
	object-position: 				center center;
	border-radius: 					100px;
	border: 						5px solid transparent;
	background: 					var(--gradient) no-repeat center center;
	background-size: 				130px 130px;
	background-clip: 				border-box;
	margin: 						0 auto 15px;
}

.navigation .userContainer{
	position: 						relative;
	margin: 						0 auto 40px;
}

.navigation .userContainer > .userTitle{
	width: 							fit-content;
	display: 						flex;
	align-items: 					center;
	cursor: 						pointer;
	transition: 					all 0.3s ease-in-out;
	margin: 						0 auto;
}

.navigation .userContainer > .userTitle.active{
	background: 					var(--gradient);
	background-clip: 				text;
	-webkit-background-clip: 		text;
	-webkit-text-fill-color: 		transparent;
}

.navigation .userContainer > .userTitle::after{
	content: 						"";
	width: 							20px;
	height: 						20px;
	background: 					url("../../images/arrowDown.svg")  center center no-repeat;
	background-size: 				20px;
	transition: 					all 0.3s ease-in-out;
}

.navigation .userContainer > .userTitle.active::after{
	transform: 						rotate(180deg);
}

.navigation .userContainer .userOptions{
	width: 							fit-content;
	position: 						absolute;
	top: 							100%;
	left: 							0;
	right: 							0;
	margin: 						0 auto;
	background: 					rgba(255, 255, 255, 0.90);
	box-shadow: 					0px 15px 50px 0px rgba(4, 26, 57, 0.15);
	backdrop-filter: 				blur(5px);
	transition: 					all 0.3s ease-in-out;
	visibility: 					hidden;
	opacity: 						0;
	z-index: 						9;
}

.navigation .userContainer .userOptions.active{
	visibility: 					visible;
	opacity: 						1;
}

.navigation .userContainer .userOptions > .userChoice,
.navigation .menuOption .subOptions > .subOptionTitle{
	padding: 						10px 35px;
	cursor: 						pointer;
}

.navigation .userContainer .userOptions > .userChoice:hover,
.navigation .menuOption .subOptions > .subOptionTitle:hover{
	background: 					var(--gradient), rgba(4, 26, 57, 0.10);
	background-clip: 				text, padding-box;
	-webkit-background-clip: 		text, padding-box;
	-webkit-text-fill-color: 		transparent;
}

.navigation .menuOption .subOptions > .subOptionTitle.disableOption {
	pointer-events: 				none;
	cursor: 						not-allowed;
	opacity: 						0.3;
}

.navigation .menuOption{
	position: 						relative;
	width: 							100%;
	border: 						unset;
	background-color: 				transparent;
	padding: 						25px 55px;
	transition: 					all 0.3s ease-in-out;
	text-align: 					start;
	display: 						flex;
	flex-flow: 						column nowrap;
	gap: 							5px;
	cursor: 						pointer;
	transition: 					all 0.3s ease-in-out;
}

.navigation .menuOption.settings{
	padding: 						0;
	justify-content: 				flex-start;
	cursor: 						default;
	position:						absolute;
	bottom: 						2%;
	right: 							0;
	width: 							100%;
	height: 						50px;
}

.navigation .menuOption.active{
	width: 							105%;
	background-color: 				var(--darkBlue);
}

.navigation .menuOption .iconOption{
	width: 							25px;
	height: 						25px;
	object-fit: 					contain;
	object-position: 				center center;
	pointer-events: 				none;
}

.navigation .menuOption .titleOption{
	font-weight: 					500;
	transition: 					all 0.3s ease-in-out;
	pointer-events: 				none;
}

.navigation .menuOption .descriptionOption{
	font-size: 						14px;
	opacity: 						0.6;
	pointer-events: 				none;
}

.navigation .menuOption.active .titleOption{
	background: 					var(--gradient);
	background-clip: 				text;
	-webkit-background-clip: 		text;
	-webkit-text-fill-color: 		transparent;
}

.navigation .menuOption .subOptions{
	width: 							max-content;
	height:                         100%;
    display:                        grid;
    grid-template-columns:          1fr;
    grid-auto-rows:             	1fr;
	position: 						absolute;
	top: 							0;
	left: 							100%;
	background: 					rgba(255, 255, 255, 0.90);
	box-shadow: 					0px 15px 50px 0px rgba(4, 26, 57, 0.15);
	visibility: 					hidden;
	opacity: 						0;
	transition: 					all 0.3s ease-in-out;
	z-index: 						9;
	align-items: 					center;
}

.navigation .menuOption .subOptions.active{
	visibility: 					visible;
	opacity: 						1;
}

@keyframes animateNavigation{
	0%{
		left: 						-315px;
		opacity: 					0;
	}
	100%{
		left: 						0;
		opacity: 					1;
	}
}

.navigation .menuOption .settings-logo{
	width: 							25px;
	height: 						25px;
	position: 						absolute;
	right: 							30px;
	top: 							50%;
	transform: 						translateY(-50%);
	cursor: 						pointer;
}

.navigation .menuOption .settings-menu,
.navigation .menuOption .settings-menu .update-menu {
	position: 						 absolute;
	background: 					 rgba(255, 255, 255, 0.90);
	left: 							 100%;
	opacity: 	      	 	 	 	 0;
	bottom: 						 0;
	transition:  	 	 	 	     all 0.3s ease-in-out;
	z-index: 	 	 	 	 	 	 2;
	border: 	 	 	 	 	 	 unset;
	visibility: 					 hidden;
}

.navigation .menuOption .settings-menu .setting-option {
	white-space: 					nowrap;
	padding: 	 	 	 	 	 	15px;
	cursor: 						pointer;
}

.navigation .menuOption .settings-menu .setting-option:hover {
	background: 					var(--gradient), rgba(4, 26, 57, 0.10);
    background-clip: 				text, padding-box;
    -webkit-background-clip: 		text, padding-box;
    -webkit-text-fill-color: 		transparent;
}

.navigation .menuOption .settings-menu.show,
.navigation .menuOption .settings-menu .update-menu.show {
	opacity: 	 	 	 	 	 	1;
	visibility: 					visible;
	z-index: 	 	 	 	 	 	99;
}

.change-password .modal-body .password-input-container {
	margin-bottom: 					15px;
	position: 						relative;
}

.change-password .modal-body .password-input-container .passwordIcon {
	width: 							25px;
	height: 						25px;
	background: 					url("../../images/visibilityOff.svg");
	background-size: 				25px;
	background-position: 			center center;
	cursor: 						pointer;
	position: 						absolute;
	right: 							20px;
	bottom: 						8px;
}

.change-password .modal-body .password-input-container .passwordIcon.show {
	background: 					url("../../images/visibility.svg");
	background-size: 				25px;
	background-position: 			center center;
}

.change-password .modal-body .password-error {
	display: 						inline-flex;
	align-items: 					center;
	color: 							var(--red);
	font-size: 						10px;
	transition: 					all 0.3s ease-in-out;
	visibility: 					hidden;
	opacity: 						0;
}

.change-password .modal-body .password-error::before {
	content: 						"";
	mask: 							url("../../images/info.svg") no-repeat center center;
	background-color: 				var(--red);
	background-size: 				24px 24px;
	width: 							24px;
	height: 						24px;
}
.change-password .modal-body .password-error.show {
	visibility: 					visible;
	opacity: 						0.7;
}

.change-password .modal-footer .password-button:disabled {
	opacity: 						0.5;
	cursor: 						not-allowed;
}

.change-password .modal-footer .password-button:disabled:hover {
	cursor: 						not-allowed;
}


/*-- MEDIA QUERIES TABLET --*/
/* @media screen and (max-width: 1200px) and (min-width: 991px) { */
@media screen and (991px >= width <= 1200px) {
	.navigation {
		width:  					280px;
	}
	.navigation .menuOption {
		padding: 					25px 35px;
	}
	.navigation .userContainer {
		margin: 					0 auto 50px;
	}
	.navigation .logo {
		margin-bottom:  	 		60px;
	}
}

/*-- MEDIA QUERIES MOBILE --*/

@media (height <= 800px) {
	.navigation .profile {
		width: 								80px;
		height: 							80px;
	}

	.navigation .userContainer {
		margin: 							0 auto 5px;
	}

	.navigation .menuOption {
		padding: 							15px 55px;
	}

	.navigation .menuOption .subOptions > .subOptionTitle {
		padding: 							0 35px;
	}
}