/* WANTS SECTION */
.wantsSection{
	margin: 							75px 0 20px;
	display: 							flex;
	gap: 								10px;
}

.wantsSection nav{
	flex: 								0 0 33.33%;
}

.wantsSection nav .nav{
	flex-flow: 							column nowrap;
	gap: 								15px;
	border: 							unset;
}

.wantsSection .nav .nav-link{
	width: 								100%;
	font-size: 							15px;
	color: 								#000000;
	padding: 							20px 30px 20px 20px;
	border: 							unset;
	border-radius: 						unset;
	text-align: 						flex-start;
	display: 							flex;
	align-items: 						center;
}

.wantsSection .nav .nav-link > img{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	margin-right: 						15px;
}

.wantsSection .nav .nav-link .inputContainer{
	width: 								23%;
	height: 							42px;
	margin: 							0 0 0 auto;
	position: 							relative;
	border-radius: 						10px;
	border: 							1px solid rgba(0, 0, 0, 0.05);
	overflow: 							hidden;
}

.wantsSection .nav .nav-link .inputContainer::after{
	content: 							"%";
	width: 								fit-content;
	height: 							fit-content;
	font-size: 							14px;
	position: 							absolute;
	right: 								30px;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
}

.wantsSection .nav .nav-link .inputContainer > input{
	background-color: 					transparent;
	padding: 							10px 5px 10px 20px;
	border-radius: 						10px;
	border: 							unset;
}

.wantsSection .nav .nav-link.active{
	background-color: 					var(--darkBlue);
	font-size: 							700;
	color: 								#FFFFFF;
}

.wantsSection .nav .nav-link.active .inputContainer > input{
	border: 							1px solid rgba(0, 163, 255, 0.30);
	color: 								#FFFFFF;
}

.wantsSection .nav .navTotal{
	display: 							flex;
	align-items: 						center;
	justify-content: 					flex-end;
	margin-top: 						5px;
	gap: 								25px;
	font-weight: 						700;
	margin-right: 						30px;
	position: 							relative;
}

.wantsSection .nav .navTotal .dangerMessage,
.wantsSection .tab-content .addAndTotal .dangerMessage{
	width: 								75%;
	height: 							max-content;
	padding: 							25px 30px;
	background-color: 					#FFFFFF;
	box-shadow: 						0px 15px 50px rgba(7, 27, 57, 0.15);
	display: 							flex;
	gap: 								10px;
	align-items: 						flex-start;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
	left: 								105%;
	transition: 						all 0.3s ease-in-out;
	z-index: 							9;
	opacity: 							0;
	visibility: 						hidden;
}

.wantsSection .nav .navTotal .dangerMessage.show,
.wantsSection .tab-content .addAndTotal .dangerMessage.show{
	opacity: 							1;
	visibility: 						visible;
}

.wantsSection .nav .navTotal .dangerMessage::before,
.wantsSection .tab-content .addAndTotal .dangerMessage::before{
	content: 							"";
	width: 								0;
	height: 							0;
	border-top: 						15px solid transparent;
	border-bottom: 						15px solid transparent;
	border-right: 						15px solid #FFFFFF;
	position: 							absolute;
	right: 								100%;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
}

.wantsSection .nav .navTotal .dangerMessage > img,
.wantsSection .tab-content .addAndTotal .dangerMessage > img{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
}

.wantsSection .nav .navTotal .dangerMessage > p,
.wantsSection .tab-content .addAndTotal .dangerMessage > p{
	font-size: 							14px;
	opacity: 							0.6;
	font-weight: 						400;
}

.wantsSection .tab-content{
	flex: 								1 0 0;
	
}

.wantsSection .tab-content .infoTitle{
	display: 							flex;
	align-items: 						center;
	padding: 							20px 60px 20px 30px;
	background-color: 					#FFFFFF;
}

.wantsSection .tab-content .infoTitle > h5{
	font-weight: 						700;
}

.wantsSection .tab-content .infoTitle > h5:first-of-type{
	flex: 								0 0 60%;
}

.wantsSection .tab-content .infoTitle > h5:last-of-type{
	margin-left: 						auto;
	margin-right: 						5px;
}

.wantsSection .tab-content .infoValues{
	display: 							flex;
	align-items: 						center;
	padding: 							20px 60px 20px 30px;
	border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
	position: 							relative;
}

.wantsSection .tab-content .infoValues .titleAndMenus{
	flex: 								0 0 60%;
	display: 							flex;
	align-items: 						center;
	gap: 								10px;
	position: 							relative;
	padding-right: 						30px;
}

.wantsSection .tab-content .infoValues .titleAndMenus > img{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							pointer;
}

.wantsSection .tab-content .infoValues .titleAndMenus > h5{
	font-weight: 						700;
}

.wantsSection .tab-content .infoValues .titleAndMenus > button{
	color: 								var(--darkBlue);
	padding: 							5px 15px;
	border: 							unset;
	background-color: 					rgba(0, 0, 0, 0.05);
}

.wantsSection .tab-content .infoValues .titleAndMenus .informationContainer{
	width: 								90%;
	box-shadow: 						0px 15px 50px rgba(7, 27, 57, 0.15);
	background-color: 					#FFFFFF;
	padding: 							40px 35px;
	position: 							absolute;
	top: 								150%;
	left: 								-25px;
	z-index: 							999;
	opacity: 							0;
	visibility: 						hidden;
	transition: 						all 0.3s ease-in-out;
}

.wantsSection .tab-content .infoValues .titleAndMenus .informationContainer::before{
	content: 							"";
	width: 								0;
	height: 							0;
	border-left: 						15px solid transparent;
	border-right: 						15px solid transparent;
	border-bottom: 						15px solid #FFFFFF;
	position: 							absolute;
	left: 								5%;
	bottom: 							100%;
}

.wantsSection .tab-content .infoValues:nth-of-type(n+8) .titleAndMenus .informationContainer{
	top: 								unset;
	bottom: 							150%;
	left: 								-25px;
}

.wantsSection .tab-content .infoValues:nth-of-type(n+8) .titleAndMenus .informationContainer::before{
	border-top: 						15px solid #FFFFFF;
	border-bottom: 						unset;
	left: 								5%;
	top: 								100%;
	bottom: 							0;
}

.wantsSection .tab-content .infoValues .titleAndMenus .informationContainer.show{
	visibility: 						visible;
	opacity: 							1;
}

.wantsSection .tab-content .infoValues .titleAndMenus .informationContainer > h5{
	font-weight: 						700;
	margin-bottom: 						25px;
}

.wantsSection .tab-content .infoValues .titleAndMenus .informationContainer > .rowInfo{
	display: 							flex;
	align-items: 						flex-start;
	gap: 								10px;
	margin-bottom: 						25px;
}

.wantsSection .tab-content .infoValues .titleAndMenus .informationContainer > .rowInfo:last-of-type{
	margin-bottom: 						0;
}

.wantsSection .tab-content .infoValues .titleAndMenus .informationContainer > .rowInfo > img{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
}

.wantsSection .tab-content .infoValues .titleAndMenus .informationContainer > .rowInfo .title{
	font-weight: 						500;
}

.wantsSection .tab-content .infoValues .titleAndMenus .informationContainer > .rowInfo .description{
	background-color: 					var(--grey);
	font-size: 							14px;
	color: 								var(--darkBlue);
	opacity: 							0.5;
	padding: 							10px;
}

.wantsSection .tab-content .infoValues .infoPoints{
	opacity: 							0.6;
}

.wantsSection .tab-content .infoValues .inputContainer{
	margin: 							0 0 0 auto;
	flex: 								0 0 80px;
	position: 							relative;
	border-radius: 						10px;
	border: 							1px solid rgba(0, 0, 0, 0.05);
    overflow: 							hidden;
}

.wantsSection .tab-content .infoValues .inputContainer::after{
	content: 							"%";
	font-size: 							16px;
	width: 								fit-content;
	height: 							fit-content;
	position: 							absolute;
	right: 								20px;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
}

.wantsSection .tab-content .infoValues .inputContainer > input{
	background-color: 					transparent;
	font-size: 							16px;
	opacity: 							0.6;
}

.wantsSection .tab-content .infoValues .deleteRow{
	width: 								16px;
	height: 							16px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	margin:  							auto 0;
	right: 								10px;
	cursor: 							pointer;
}

.wantsSection .tab-content .infoValues .settingsVariable{
	width: 								16px;
	height: 							16px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	margin:  							auto 0;
	right: 								35px;
	cursor: 							pointer;
}

.wantsSection .tab-content .infoValues .containerSettings{
	width: 								40%;
	background-color: 					#FFFFFF;
	box-shadow: 						0px 15px 50px 0px rgba(7, 27, 57, 0.15);
	padding: 							50px;
	position: 							absolute;
    bottom: 							85%;
    margin: 							auto 0;
    right: 								0;
	visibility: 						hidden;
	opacity: 							0;
	transition: 						all 0.3s ease-in-out;
}

.wantsSection .tab-content .infoValues .payrollSettings,
.wantsSection .tab-content .infoValues .unionSettings{
	width: 								max-content;
	padding: 							30px;
}

.wantsSection .tab-content .infoValues .payrollSettings .hasPercentage {
	position: 							relative;
}

.wantsSection .tab-content .infoValues .payrollSettings .hasPercentage::after {
	content: 							"%";
	position: 							absolute;
	right: 								10px;
	top: 								50%;
	transform: 							translateY(-50%);
	color: 								rgba(0, 162, 255, 0.90);
	
}

.wantsSection .tab-content .infoValues .payrollSettings .popData,
.wantsSection .tab-content .infoValues .unionSettings .popData{
	margin-bottom: 						15px;
}

.wantsSection .tab-content .infoValues .payrollSettings .popData th,
.wantsSection .tab-content .infoValues .unionSettings .popData th{
	font-size: 							14px;
	padding: 							0 3px;
}

.wantsSection .tab-content .infoValues .payrollSettings .popData td,
.wantsSection .tab-content .infoValues .unionSettings .popData td{
	font-size: 							12px;
	padding: 							3px;
}

.wantsSection .tab-content .infoValues .payrollSettings .popData td:first-of-type,
.wantsSection .tab-content .infoValues .unionSettings .popData td:first-of-type{
	padding-right: 						15px;
}

.wantsSection .tab-content .infoValues .payrollSettings .popData td input,
.wantsSection .tab-content .infoValues .unionSettings .popData td input,
.wantsSection .tab-content .infoValues .payrollSettings .popData td span,
.wantsSection .tab-content .infoValues .unionSettings .popData td span{
	font-size: 							12px;
}

.wantsSection .tab-content  .popData.distance{
	height: 							200px;
	overflow-y: 						auto;
}

.wantsSection .tab-content .infoValues .unionSettings{
	height: 							auto;
	padding: 							30px;
	max-width: 							800vw;
	/* width: 								auto; */
}

.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer {
	display: 							flex;
	flex-flow: 							column nowrap;
	white-space: 						nowrap;
}

.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer .tableTheadContainer,
.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer .tableTotalContainer {
	padding-right: 						8px;
}

.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer .tableTheadContainer,
.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer .tableTotalContainer,
.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer .tableBodyContainer .tableRowContainer {
	display: 							grid;
	grid-template-columns: 				minmax(230px, 3fr) repeat(auto-fit, minmax(70px, 2fr));
	column-gap: 						5px;
	margin-bottom: 						10px;
}

.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer .tableTheadContainer > span {
	text-overflow: 						ellipsis;
	overflow: 							hidden;
}

.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer .tableTotalContainer > span {
	padding: 							5px;
	background: 						var(--gradient), linear-gradient(180deg, #009AFF1A 10%, #8CA5FF1A 100%);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer .tableTheadContainer > span,
.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer .tableTotalContainer > span {
	font-weight: 						600;
}

.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer .tableTheadContainer > span:not(:first-of-type),
.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer .tableTotalContainer > span:not(:first-of-type) {
	text-align: 						center;
}

.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer .tableBodyContainer {
	display: 							flex;
	flex-flow: 							column nowrap;
	margin-bottom: 						10px;
	max-height: 						22vh;
	overflow-y: 						auto;
}

.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer .tableBodyContainer .tableRowContainer > span {
	align-self: 						center;
}

.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer .tableBodyContainer .tableRowContainer > span {
	font-weight: 						500;
	text-overflow: 						ellipsis;
	overflow: 							hidden;
}

.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer .tableBodyContainer .tableRowContainer > span,
.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer .tableBodyContainer .tableRowContainer > input {
	height: 							inherit;
}

.wantsSection .tab-content .infoValues .unionSettings .tablePopDataContainer .tableBodyContainer .tableRowContainer > input {
	padding: 							10px 5px;
	text-align: 						center;
}

.wantsSection .tab-content .infoValues .costOfLivingSettings .popData th:not(:first-of-type) {
	text-align: 						center;
	white-space: 						nowrap;
}

.wantsSection .tab-content .infoValues .costOfLivingSettings .popData td:first-of-type {
	font-size: 							16px;
	font-weight: 						500;
	width: 								250px;
	padding-right: 						25px;
}

.wantsSection .tab-content .infoValues .costOfLivingSettings .popData td:not(:first-of-type) {
	width: 								150px;
}

.wantsSection .tab-content .infoValues .payrollSettings .popData tbody input {
	text-align: 						center;
}

.wantsSection .tab-content .infoValues .containerSettings.show{
	visibility: 						visible;
	opacity: 							1;
}

.wantsSection .tab-content .infoValues .containerSettings::after{
	content: 							"";
	width: 								0;
	height: 							0;
	border-top: 						15px solid #FFFFFF;
	border-left: 						20px solid transparent;
	border-right: 						20px solid transparent;
	position: 							absolute;
	top: 								100%;
	right: 								23px;
}

.wantsSection .tab-content .infoValues .containerSettings .titleAndType{
	display: 							flex;
	align-items: 						center;
	justify-content: 					space-between;
	margin-bottom: 						25px;
	gap: 								15px;
}

.wantsSection .tab-content .infoValues .containerSettings h5{
	font-weight: 						700;
	margin-bottom: 						5px;
}

.wantsSection .tab-content .infoValues .containerSettings .titleAndType .dropdown-toggle{
	font-size: 							14px;
    padding: 							0 20px 0 0;
    background: 						var(--gradient);
    background-clip: 					text;
    -webkit-background-clip: 			text;
    -webkit-text-fill-color: 			transparent;
    border-bottom: 						unset;
}

.wantsSection .tab-content .infoValues .containerSettings .titleAndType .dropdown-toggle::after{
	right: 								0;
}

.wantsSection .tab-content .infoValues .containerSettings .titleAndType .currencyButtonContainer {
	display: 							flex;
	gap: 								5px;
}

.wantsSection .tab-content .infoValues .containerSettings .titleAndType .currencyButtonContainer > button {
	font-size: 							14px;
	padding-inline: 					10px;
	border: 							none;
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
}

.wantsSection .tab-content .infoValues .containerSettings .titleAndType .currencyButtonContainer > button.selected {
	border: 							none;
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.wantsSection .tab-content .infoValues .containerSettings .payrollFile{
	margin-bottom: 						30px;
}

.wantsSection .tab-content .infoValues .containerSettings .payrollFile input[type="file"]{
	background-color:                   transparent !important;
	padding:                            0 !important;
}

.wantsSection .tab-content .infoValues .containerSettings .payrollFile input[type="file"]::file-selector-button{
	padding:                            10px 15px;
	border:                             unset;
	background:                         var(--gradient), var(--blue);
	background-clip:                    text, padding-box;
	-webkit-background-clip:            text, padding-box;
	-webkit-text-fill-color:            transparent;
	cursor:                             pointer;
	transition:                         all 0.3s ease-in-out;
}

.wantsSection .tab-content .infoValues .containerSettings .payrollFile input[type="file"]::file-selector-button:hover{
	background:                         var(--gradient), var(--darkBlue);
	background-clip:                    text, padding-box;
	-webkit-background-clip:            text, padding-box;
}

.wantsSection .tab-content .infoValues .containerSettings .payrollFile .inputFileContainer{
	display:                            flex;
	justify-content:                    space-between;
	padding-right:                      15px;
	align-items:                        center;
	margin-bottom:                      25px;
}

.wantsSection .tab-content .infoValues .containerSettings .payrollFile .inputFileContainer > img{
	cursor: 							pointer;
}

.wantsSection .tab-content .infoValues .containerSettings .payrollFile .inputFileContainer .selectFileContainer {
	display:                            flex;
	align-items:                        center;
	gap:                                10px;
	white-space:                        nowrap;
	overflow:                           hidden;
}

.wantsSection .tab-content .infoValues .containerSettings .payrollFile .inputFileContainer .selectFileContainer > button {
	height:                             40px;
}

.wantsSection .tab-content .infoValues .containerSettings .payrollFile .inputFileContainer .selectFileContainer > span {
	overflow:                           hidden;
	text-overflow:                      ellipsis;
}

.wantsSection .tab-content .infoValues .containerSettings .cities{
	display: 							flex;
	flex-flow: 							row wrap;
	gap: 								20px;
	margin-bottom: 						40px;
}

.wantsSection .tab-content .infoValues .containerSettings .cities .inputContainer{
	flex:  								0 0 100%;
	border: 							unset;
}

.wantsSection .tab-content .infoValues .containerSettings .cities .inputContainer::after{
	content: 							unset;
}

.wantsSection .tab-content .infoValues .containerSettings .cities .inputContainer > .label{
	font-weight: 						500;
}

.wantsSection .tab-content .infoValues .containerSettings .cities .inputContainer > input{
	background-color: 					rgba(4, 26, 57, 0.02);
}

.wantsSection .tab-content .infoValues .containerSettings .cities .inputContainer > .valueSelected{
	font-size: 							14px;
	position: 							absolute;
	right: 								10px;
	bottom: 							10px;
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
	pointer-events: 					none;
}

.wantsSection .tab-content .infoValues .containerSettings .actions{
	display: 							flex;
	align-items: 						center;
	justify-content: 					space-between;
}

.wantsSection .tab-content .infoValues .containerSettings .payrollActions {
	justify-content: 					flex-end;
	gap: 								10px;
}

.wantsSection .tab-content .infoValues .containerSettings .actions #cancelFile{
	background: 						transparent;
	-webkit-text-fill-color: 			unset;
}

.wantsSection .tab-content .infoValues .containerSettings .actions #cancelFile:hover{
	background-color: 					unset;
}

.wantsSection .tab-content .infoValues .containerSettings .actions .btnBgBlueTextGradient{
	margin-left: 						auto;
	border: 							unset;
	padding: 							5px 15px;
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
	transition: 						all 0.3s ease-in-out;
}

.wantsSection .tab-content .infoValues .containerSettings .payrollActions .btnBgBlueTextGradient{
	margin-left: 						unset;
}

.wantsSection .tab-content .infoValues .containerSettings .actions .btnBgBlueTextGradient:hover{
	background-color: 					var(--darkBlue);
}

.wantsSection .tab-content .infoValues .containerSettings .actions .btnBgBlueTextGradient:disabled{
	background: 						transparent;
	-webkit-text-fill-color: 			unset;
}

.wantsSection .tab-content .addAndTotal{
	padding: 							15px 60px 0 30px;
	display: 							flex;
	align-items: 						center;
	position: 							relative;
}

.wantsSection .tab-content .addAndTotal .addContainer{
	flex: 								0 0 60%;
}

.wantsSection .tab-content .addAndTotal .addContainer > button{
	font-size: 							15px;
	padding: 							5px 10px;
	border: 							unset;
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
	display: 							flex;
	align-items: 						center;
	transition: 						all 0.3s ease-in-out;
}

.wantsSection .tab-content .addAndTotal .addContainer > button:hover{
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.wantsSection .tab-content .addAndTotal .addContainer > button::before{
	content: 							"";
	width: 								10px;
	height: 							10px;
	background: 						url("../../images/addAlone.svg") center center no-repeat;
	background-size: 					10px 10px;
	margin-right: 						5px;
}

.wantsSection .tab-content .addAndTotal > span{
	font-weight: 						700;
}

.wantsSection .tab-content .addAndTotal > span:last-of-type{
	margin: 							0 20px 0 auto;
}

.wantsSection .tab-content .addAndTotal .dangerMessage{
	width: 								35%;
	top: 								130%;
	left: 								unset;
	right: 								5%;
	bottom: 							unset;
	margin: 							0;
}

.wantsSection .tab-content .addAndTotal .dangerMessage::before{
	top: 								unset;
	bottom: 							100%;
	left: 								75%;
	border-left: 						15px solid transparent;
	border-right: 						15px solid transparent;
	border-bottom: 						15px solid #FFFFFF;
}

.wantsSection .tab-content .tabButtonsContainer{
	display: 							flex;
	gap: 								10px;
	margin-top: 						65px;
	justify-content: 					flex-end;
}

.wantsSection .tab-content .tabButtonsContainer .btnBgBlueTextGradient{
	padding: 							10px 50px;
	border: 							unset;
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
	transition: 						all 0.3s ease-in-out;
}

.wantsSection .tab-content .tabButtonsContainer .btnBgBlueTextGradient.hidden{
	visibility: 						hidden;
	opacity: 							0;
}

.wantsSection .tab-content .tabButtonsContainer .btnBgBlueTextGradient:first-of-type{
	background: 						transparent;
	-webkit-text-fill-color: 			unset;
}

.wantsSection .tab-content .tabButtonsContainer .btnBgBlueTextGradient:last-of-type:hover{
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
}

.payrollModal .modal-body {
	display: 							flex;
	flex-flow: 							row nowrap;
}

.wantsCriteriaModal .modal-body .payrollColumn {
	display: 							flex;
	position: 							relative;
	flex-flow: 							column nowrap;
	justify-content: 					center;
	height: 							100%;
	width: 								33.33%;
	gap: 								50px;
	padding: 							50px 30px;
}

.wantsCriteriaModal .modal-body .payrollColumn:nth-of-type(2)::after,
.wantsCriteriaModal .modal-body .payrollColumn:nth-of-type(2)::before {
	content: 							"";
	position: 							absolute;
	top:								40%;
	height: 							80%;
	width: 								3px;
	background: 						#000000;
	opacity: 							3%;
}

.wantsCriteriaModal .modal-body .payrollColumn:nth-of-type(2)::after {
	left: 								0;
}

.wantsCriteriaModal .modal-body .payrollColumn:nth-of-type(2)::before {
	right: 								0;
}

.wantsCriteriaModal .modal-body .payrollColumn .columnArea {
	display: 							flex;
	flex-flow: 							column nowrap;
	gap: 								10px;
	width: 								100%;
	align-items: 						center;
}

.wantsCriteriaModal .modal-body .payrollColumn .columnArea .columnNumber {
	line-height: 						1;
	font-size: 							90px;
	font-weight: 						400;
	padding: 							20px;
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
}

.wantsCriteriaModal .modal-body .payrollColumn .columnArea > p {
	text-align: 						center;
	text-wrap: 							pretty;
}

.wantsCriteriaModal .modal-body .payrollColumn .columnArea .filenameContainer {
	display: 							flex;
	flex-flow: 							row nowrap;
	width: 								100%;
	gap: 								10px;
}


.wantsCriteriaModal .modal-body .payrollColumn .columnArea .filenameContainer .filename {
	width: 								100%;
	white-space: 						nowrap;
	overflow: 							hidden;
	text-overflow:						ellipsis;
}

.wantsCriteriaModal .modal-body .payrollColumn .columnArea .filenameContainer .filename.hide, 
.wantsCriteriaModal .modal-body .payrollColumn .columnArea .filenameContainer .deleteFile.hide{
	opacity: 							0;
	visibility: 						hidden;
}

.wantsCriteriaModal .modal-body .payrollColumn .columnArea .filenameContainer > img:last-of-type {
	cursor: 							pointer;
}

.payrollModal .modal-body .payrollColumn .columnArea .applyChanges {
	width: 								100%;
	margin: 							unset
}

.payrollModal .modal-body .payrollColumn .columnArea .applyChanges {
	display: 							flex;
	align-items: 						center;
	justify-content: 					center;
	gap: 								10px;
	width: 								100%;
	margin: 							unset
}

.payrollModal .modal-body .payrollColumn .columnArea  > button::before {
	content: 							"";
	width: 								24px;
	height: 							24px;
	background:							var(--gradient);
	mask: 								url("../../images/download.svg") center center no-repeat;
	background-size: 					24px 24px;
}

.payrollModal .modal-body .payrollColumn .columnArea #upload::before {
	transform: 							rotate(180deg);
}

.wantsCriteriaModal .modal-body .actions{
	display: 							flex;
	align-items: 						center;
	justify-content: 					space-between;
}

.wantsCriteriaModal .modal-body .payrollActions {
	justify-content: 					flex-end;
	gap: 								10px;
}

.wantsCriteriaModal .modal-body .actions #cancelFile{
	background: 						transparent;
	-webkit-text-fill-color: 			unset;
}

.wantsCriteriaModal .modal-body .actions #cancelFile:hover{
	background-color: 					unset;
}

.wantsCriteriaModal .modal-body .actions .btnBgBlueTextGradient{
	margin-left: 						auto;
	border: 							unset;
	padding: 							5px 15px;
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
	transition: 						all 0.3s ease-in-out;
}

.wantsCriteriaModal .modal-body .payrollActions .btnBgBlueTextGradient{
	margin-left: 						unset;
}

.wantsCriteriaModal .modal-body .actions .btnBgBlueTextGradient:hover{
	background-color: 					var(--darkBlue);
}

.wantsCriteriaModal .modal-body .actions .btnBgBlueTextGradient:disabled{
	background: 						transparent;
	-webkit-text-fill-color: 			unset;
}

.wantsCriteriaModal .modal-content{
	border: 							unset;
	border-radius: 						unset;
}

.wantsCriteriaModal .modal-body{
	padding: 							50px;
	background-color: 					#FFFFFF;
	box-shadow: 						0px 15px 50px 0px rgba(7, 27, 57, 0.15);
}

.wantsCriteriaModal .modal-body .titleAndType{
	display: 							flex;
	align-items: 						center;
	justify-content: 					space-between;
	margin-bottom: 						15px;
}

.wantsCriteriaModal .modal-body .titleAndType > span{
	font-weight: 						700;
}

.wantsCriteriaModal .modal-body .titleAndType .dropdown{
	padding: 							3px;
	background-color: 					var(--darkBlue);
}

.wantsCriteriaModal .modal-body .titleAndType .dropdown-toggle{
	font-size: 							14px;
	padding: 							5px 25px 5px 10px ;
	background-color: 					transparent;
	color: 								#FFFFFF;
	border-bottom: 						unset;
}

.wantsCriteriaModal .modal-body .titleAndType .dropdown-toggle::after{
	right: 								5px;
}

.wantsCriteriaModal .modal-body .titleAndType .dropdown-item{
	font-size: 							16px;
	padding: 							10px 35px;
}

.wantsCriteriaModal .modal-body .titleAndType .dropdown-item:hover{
	background: 						var(--gradient), rgba(4, 26, 57, 0.10);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.wantsCriteriaModal .modal-body .criteriaPoints{
	margin-bottom: 						40px;
	display: 							flex;
	gap: 								10px;
}

.wantsCriteriaModal .modal-body .criteriaPoints .criterias{
	flex:  								1 0 0;
	display: 							flex;
	flex-flow: 							row wrap;
	gap: 								10px;
}

.wantsCriteriaModal .modal-body .criteriaPoints .criterias > span{
	flex:  								0 0 100%;
}

.wantsCriteriaModal .modal-body .criteriaPoints .criterias > .dropdown{
	flex: 								1 0 0;
}

.wantsCriteriaModal .modal-body .criteriaPoints .criterias .dropdown-toggle{
	background-color: 					rgba(4, 26, 57, 0.02);
	border: 							unset;
	font-weight: 						500;
	padding: 							10px 15px;
}

.wantsCriteriaModal .modal-body .criteriaPoints .criterias .dropdown-item{
	font-size: 							16px;
	padding: 							10px 35px;
}

.wantsCriteriaModal .modal-body .criteriaPoints .criterias .dropdown-item:hover{
	background: 						var(--gradient), var(--grey);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;

}

.wantsCriteriaModal .modal-body .criteriaPoints .criterias > .inputContainer{
	flex: 								0 0 calc(100% / 2 - (10px * 1 / 2 ));
	margin: 							0;
	transition: 						all 0.3s ease-in-out;
	position: 							relative;
}

.wantsCriteriaModal .modal-body .criteriaPoints .criterias > .inputContainer.manual{
	flex: 								0 0 100%;
}

.wantsCriteriaModal .modal-body .criteriaPoints .criterias > .inputContainer > input{
	padding: 							10px 15px;
	border: 							unset;
	background-color: 					rgba(4, 26, 57, 0.02);
}

.wantsCriteriaModal .modal-body .criteriaPoints .criterias > .inputContainer > .label{
	font-size: 							12px;
	font-weight: 						700;
}

.wantsCriteriaModal .modal-body .criteriaPoints .criterias > .inputContainer.hide{
	visibility: 						hidden;
	opacity: 							0;
}

.wantsCriteriaModal .modal-body .criteriaPoints .criterias > .inputContainer .absoluteIcon{
	font-size: 							14px;
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
	position: 							absolute;
	bottom: 							10px;
	right: 								15px;
}

.wantsCriteriaModal .modal-body .criteriaPoints .points{
	display: 							flex;
	flex-flow: 							column nowrap;
	gap: 								10px;
}

.wantsCriteriaModal .modal-body .criteriaPoints .points > .value{
	height: 							59px;
	display: 							flex;
	align-items: 						flex-end;
	justify-content: 					center;
	padding-bottom: 					10px;
}

.wantsCriteriaModal .modal-body .applyChanges{
	border: 							unset;
	padding: 							10px 15px;
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
	margin-left: 						auto;
	transition: 						all 0.3s ease-in-out;
}

.wantsCriteriaModal .modal-body .applyChanges:hover{
	background-color: 					var(--darkBlue);
}

@media screen and (width <= 991px) {
	.wantsSection {
		margin: 									30px -12px 0 -12px;
	}

	.wantsCriteriaModal .modal-body{
		padding: 									30px;
	}

	.wantsCriteriaModal .modal-body .criteriaPoints{
		margin-bottom: 								20px;
	}

	.payrollModal .modal-body {
		flex-flow: 									column nowrap;
	}

	.wantsCriteriaModal .modal-body .payrollColumn {
		width: 										100%;
	}

	.wantsCriteriaModal .modal-body .payrollColumn:nth-of-type(2)::after,
	.wantsCriteriaModal .modal-body .payrollColumn:nth-of-type(2)::before {
		content: 									"";
		position: 									absolute;
		height: 									3px;
		width: 										100%;
		background: 								#000000;
		opacity: 									5%;
	}

	.wantsCriteriaModal .modal-body .payrollColumn:nth-of-type(2)::before {
		top: 										0;
	}

	.wantsCriteriaModal .modal-body .payrollColumn:nth-of-type(2)::after {
		top: 										450px;
	}

	.wantsCriteriaModal .modal-body .criteriaPoints .criterias .dropdown-toggle {
		padding: 									10px 5px;
	}
}