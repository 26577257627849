/* GENERAL DASHBOARD */
.dashboardContainer {
	display:   							flex;
	width:  							100%;
	height:  							100vh;
	height:  							100dvh;
	overflow:  							hidden;
}
.dashboard{
	min-height: 						100vh;
	min-height: 						100dvh;
	position: 							relative;
	padding-top: 						20px;
	transition: 						all 0.3s ease-in-out;
	flex-grow:  						1;
	width: 								100%;
}

/* DASBOARD SEARCHBAR */
.dashboard .searchContainer{
	position: 							relative;
	width: 								fit-content;
	margin: 							0 50px 40px auto;
}

.dashboard .searchContainer::after{
	content: 							"";
	display: 							block;
	width: 								25px;
	height: 							25px;
	background: 						url("../../images/search.svg") center center no-repeat;
	background-size: 					25px;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	right: 								25px;
	margin:  							auto 0;
	cursor: 							pointer;
}

.dashboard .searchContainer .search{
	width: 								400px;
	padding-right: 						65px;
}

.dashboard .searchContainer .searchClients{
	position: 							absolute;
	left: 								0;
	right: 								0;
	top: 								105%;
	background-color: 					#FFFFFF;
	z-index: 							999;
	visibility: 						hidden;
	opacity: 							0;
	transition: 						all 0.3s ease-in-out;
}

.dashboard .searchContainer .searchClients.show{
	visibility: 						visible;
	opacity: 							1;
}

.dashboard .searchContainer .searchClients .clients{
	max-height: 						340px;
	overflow-y: 						auto;
}

.dashboard .searchContainer .searchClients .clients::-webkit-scrollbar{
	width: 								3px;
}

.dashboard .searchContainer .searchClients .clients::-webkit-scrollbar-track{
	background: 						rgba(0, 0, 0, 0.05);
}

.dashboard .searchContainer .searchClients .clients::-webkit-scrollbar-thumb{
	background: 						var(--darkBlue);
}

.dashboard .searchContainer .searchClients .clients .client{
	position: 							relative;
	display: 							flex;
	flex-flow: 							row wrap;
	padding: 							20px 35px 20px 30px;
	border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
	cursor: 							pointer;
	transition: 						all 0.3s ease-in-out;
}

.dashboard .searchContainer .searchClients .clients .client::before{
	content: 							"";
	display: 							block;
	width: 								1px;
	height: 							0;
	background: 						var(--gradient) center center no-repeat;
	position: 							absolute;
	top: 								0;
	left: 								0;
	transition: 						all 0.3s ease-in-out;
}

.dashboard .searchContainer .searchClients .clients .client:hover{
	background-color: 					rgba(4, 26, 57, 0.03);
}

.dashboard .searchContainer .searchClients .clients .client:hover::before{
	height: 							100%;
}

.dashboard .searchContainer .searchClients .clients .client .company{
	display: 							inline-block;
	font-weight: 						700;
}

.dashboard .searchContainer .searchClients .clients .client .name{
	display: 							inline-block;
	opacity: 							0.5;
	flex: 								1 0 0;
	text-align: 						end;
	transition: 						all 0.3s ease-in-out;
}

.dashboard .searchContainer .searchClients .clients .client:hover .name{
	opacity: 							1;
}

.dashboard .searchContainer .searchClients .clients .client:hover .name::first-letter{
	font-weight: 						700;
}

.dashboard .searchContainer .searchClients .clients .client .email{
	width: 								fit-content;
	font-size: 							12px;
	opacity: 							0.3;
	margin-left: 						auto;
	flex: 								0 0 100%;
	text-align: 						end;
}



.dashboard .searchContainer .searchClients .viewAll{
	display: 							flex;
	flex-flow: 							row nowrap;
	justify-content: 					center;
	gap: 								5px;
	padding: 							20px 0;
}

.dashboard .searchContainer .searchClients .viewAll > img{
	width: 								25px;
	height: 							25px;
	object-fit:  						contain;
	object-position: 					center center;
}

.dashboard .searchContainer .searchClients .viewAll > span{
	font-size: 							15px;
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
	cursor: 							pointer;
}

/* DASBOARD RIGHT CONTAINER */
.dashboard .dashboardRightContainer {
	padding: 	 						20px 10%;
}
.dashboard .animatedContainer{
	animation: 							animateDashboard 1s forwards 2s;
}

.dashboard .welcome{
	margin-bottom: 						40px;
}

.dashboard .recent{
	margin-bottom: 						25px;
	font-weight: 						700;
}

.dashboard  .tableReport{
	width: 								100%;
}

.dashboard  .tableReport thead{
	background: 						#FFFFFF;
	box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.08);
}

.dashboard .tableReport .sort{
	display: 							inline;
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							pointer;
	margin-left: 						5px;
	transition: 						all 0.3s ease-in-out;
}

.dashboard .tableReport .sort.DSC{
	transform: 							rotate(180deg);
}

.dashboard .tableReport thead th {
    white-space:                        nowrap;
}

.dashboard .tableReport thead .header-icon {
    display:                            flex;
    flex-wrap:                          nowrap;
    white-space:                        nowrap;
}

.dashboard  .tableReport thead th,
.dashboard  .tableReport tbody td{
	position: 							relative;
	padding: 							20px;
}

.dashboard  .tableReport tbody tr{
	border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
}

.dashboard  .tableReport .pending{
    color: 								var(--orange);
}

.dashboard  .tableReport .pending::after{
	content: 							"\025cf";
	width: 								20px;
	height: 							20px;
	margin-left: 						5px;
	color: 								var(--orange);
}

.dashboard  .tableReport .finish{
    color: 								var(--green);
}

.dashboard  .tableReport .finish::after{
	content: 							"\02714";
	width: 								20px;
	height: 							20px;
	margin-left: 						5px;
	color: 								var(--green);
}

.dashboard .tableReport .dateAndMore{
	position: 							relative;
	display: 							flex;
	flex-flow: 							row nowrap;
	justify-content: 					space-between;
}

.dashboard .tableReport .dateAndMore > span{
	opacity: 							0.6;
}

.dashboard .tableReport .dateAndMore > img{
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							pointer;
}

.dashboard .tableReport .dateAndMore .moreOptions{
	background: 						rgba(255, 255, 255, 0.90);
	box-shadow: 						0px 15px 50px 0px rgba(4, 26, 57, 0.15);
	position: 							absolute;
	top: 								75%;
	right: 								-50%;
	transform: 							translateX(-50%);
	z-index: 							999;
	visibility: 						hidden;
	opacity: 							0;
	transition: 						all 0.3s ease-in-out;
	max-height: 						0;
	overflow: 							hidden;
}

.dashboard .tableReport tbody tr:last-of-type .dateAndMore .moreOptions{
	top: 								unset;
	bottom: 							75%;
}

.dashboard .tableReport .dateAndMore .moreOptions.show{
	visibility: 						visible;
	opacity: 							1;
	max-height: 						1000px;
}

.dashboard .tableReport .dateAndMore .moreOptions .option{
	padding: 							10px 35px;
	cursor: 							pointer;
}

.dashboard .tableReport .dateAndMore .moreOptions .option:hover{
	background: 						var(--gradient), rgba(4, 26, 57, 0.10);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.dashboard .tableReport .dateAndMore .moreOptions .option:last-of-type{
	color: 								var(--red);
}

.dashboard .tableReport .dateAndMore .moreOptions .option:last-of-type:hover{
	background: 						rgba(4, 26, 57, 0.10);
	background-clip: 					padding-box;
	-webkit-background-clip: 			padding-box;
	-webkit-text-fill-color: 			var(--red);
}

.dashboard .pinButtonContainer{
	position: 							fixed;
	bottom: 							70px;
	right: 								30px;
}

.dashboard .pinButtonContainer .pinButton{
	position: 							relative;
	width: 								35px;
	height: 							35px;
	border: 							unset;
	border-radius: 						100px;
	background: 						var(--gradient) no-repeat center center;
	box-shadow: 						15px 15px 30px 0px rgba(35, 107, 216, 0.25);
}

.dashboard .pinButtonContainer .pinButton.active{
	background: 						var(--darkBlue) no-repeat center center;
}

.dashboard .pinButtonContainer .pinButton::before{
	content: 							"Show Reports";
	width: 								max-content;
	height: 							max-content;
	display: 							block;
	color: 								rgba(0, 0, 0, 0.5);
	background-color: 					#FFFFFF;
	padding: 							10px 30px;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	right: 								130%;
	margin: 							auto 0;
	visibility: 						hidden;
	opacity: 							0;
	transition: 						all 0.3s ease-in-out;
}

.dashboard .pinButtonContainer .pinButton.active::before{
	content: 							"Hide Reports";
}

.dashboard .pinButtonContainer .pinButton:hover::before{
	visibility: 						visible;
	opacity: 							1;
}

.dashboard .pinButtonContainer .pinButton::after{
	content: 							"<>";
	display: 							block;
	color: 								#FFFFFF;
	transform: 							rotate(90deg);
}

.dashboard .pinButtonContainer .pinButton.active::after{
	content: 							"><";
	background: 						var(--gradient) center center no-repeat;
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
}

.dashboard .pinButtonContainer .pinnedReports{
	display: 							flex;
	flex-flow: 							column nowrap;
	gap: 								20px;
	position: 							absolute;
	left: 								0;
	right: 								0;
	bottom: 							100%;
	margin: 							0 auto;
	visibility: 						hidden;
	opacity: 							0;
	transition: 						all 0.3s ease-in-out;
}

.dashboard .pinButtonContainer .pinnedReports.show{
	bottom: 							160%;
	visibility: 						visible;
	opacity: 							1;
}

.dashboard .pinButtonContainer .pinnedReports .reportPin{
	position: 							relative;
	border: 							unset;
	border-radius: 						100px;
	width: 								100%;
	aspect-ratio: 						1;
	background: 						var(--gradient) center center no-repeat;
	color: 								#FFFFFF;
}

.dashboard .pinButtonContainer .pinnedReports .reportPin .reportName{
	width: 								max-content;
	height: 							max-content;
	padding: 							10px;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	right: 								160%;
	margin: 							auto 0;
	background-color: 					#FFFFFF;
	color: 								rgba(0, 0, 0, 0.5);
	filter: 							drop-shadow(0px 15px 50px rgba(4, 26, 57, 0.05));
	transition: 						all 0.3s ease-in-out;
	visibility: 						hidden;
	opacity: 							0;
}

.dashboard .pinButtonContainer .pinnedReports .reportPin .reportName::after{
	content: 							"";
	display: 							block;
	width: 								0;
	height: 							0;
	border-top: 						15px solid transparent;
	border-bottom: 						15px solid transparent;
	border-left: 						15px solid #FFFFFF;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	left: 								100%;
	margin: 							auto 0;
}

.dashboard .pinButtonContainer .pinnedReports .reportPin:hover .reportName{
	visibility: 						visible;
	opacity: 							1;
}


.dashboard .privacy{
	font-size: 							12px;
	font-weight: 						400;
	opacity: 							0.4;
	cursor: 							pointer;
	position: 							fixed;
	right: 								30px;
	bottom: 							20px;
	z-index: 							2;
}

.dashboard .privacy:hover{
	opacity: 							1;
}

.dashboardContainer .animationContainer{
	position: 							fixed;
	top: 								0;
	left: 								0;
	right: 								0;
	bottom: 							0;
	z-index: 							99999;
	overflow:  							hidden;
}

.dashboardContainer .animationContainer.show{
	animation: 							animatedContainerReverse 0.5s forwards 2s;
}

.dashboardContainer .animationContainer .columnAnimation{
	width: 								25%;
	height: 							100%;
	position: 							absolute;
	top: 								0;
	left: 								0;
	background-color: 					#041A39;
}

.dashboardContainer .animationContainer .columnAnimation:nth-of-type(2){
	left: 								25%;
}

.dashboardContainer .animationContainer .columnAnimation:nth-of-type(3){
	left: 								50%;
}

.dashboardContainer .animationContainer .columnAnimation:nth-of-type(4){
	left: 								75%;
}

.dashboardContainer .animationContainer .columnAnimation.animate{
	animation: 							animatedWidthReverse 0.5s forwards 2s;
}

.dashboardContainer .animationContainer .title{
	width: 								fit-content;
	height: 							fit-content;
	color: 								#00A3FF;
	font-size:							48px;
	position: 							fixed;
	top: 								0;
	left: 								0;
	right: 								0;
	bottom: 							0;
	margin: 							auto;
	max-height: 						0;
	overflow: 							hidden;
}

.dashboardContainer .animationContainer .title::after{
	content: 							attr(data-name);
	display: 							inline-block;
	max-width: 							0;
	color: 								#FFFFFF;
	font-size: 							48px;
}

.dashboardContainer .animationContainer .title.animate{
	animation: 							animatedHeight 3s forwards 0.5s;
}

.dashboardContainer .animationContainer .title.animate::after{
	animation: 							animatedTitleAfter 3s forwards 1s;
	margin-left: 						10px;
}

.pinPrivacySpace {
	width: 								100%;
	height: 							130px;
}

.fade.mail-form-modal.modal .modal-dialog-centered .modal-content{
    background:                 #FFF;
    box-shadow:                 0px 50px 200px 0px rgba(4, 26, 57, 0.15);
    border-radius:              0;
    border:                     unset;
}

.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-header{
    display:                        flex;
    flex-direction:                 column-reverse;
    padding:                        20px 20px 15px 50px;
    border:                         unset;
}

.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-header .modal-title.h4{
    margin-right:                   auto;
    font-size:                      23px;
    font-weight:                    500;
}

.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-body{
    padding:                        0px 50px 30px 50px;
    border:                         unset;
}

.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-body input {
	background: 					var(--grey);
}

.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-body .mail-capture{
    background:                   rgba(4, 26, 57, 0.04);
    font-size:                      16px;
    font-weight:                    300;
    padding:                        12px 40px 12px 25px;
}

.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-body .mail-capture.error{
    border-color:                  red;
    background:                   rgba(138, 9, 5, 0.08);

}

.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-body .error-msg::before{
    content: "🛈 ";

}

.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-body .error-msg{
    max-height:                      0;
    color:                         red;
    transition:                      height 0.4s ease-in-out, opacity 0.4s ease-in-out;
    padding:                         5px;
    position:                        absolute;
    opacity:                         0;
    font-size:                       13px;
}

.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-body .error-msg.error{
    max-height:                      fit-content;
    opacity:                         0.9;
}


.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-footer{
    border:                         unset;
    padding:                        0 50px 30px 0;
}

.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-footer > button{
    border:                         unset;
    background:                   	var(--gradient), var(--blue);
	background-clip: 				text, border-box;
	-webkit-background-clip: 		text, border-box;
	-webkit-text-fill-color: 		transparent;
    transition:                     background ease 0.3s;
    padding:                        10px 50px;
    gap:                            10px;
    font-size:                      16px;
    font-weight:                    400;
}

.fade.mail-form-modal.modal .modal-dialog.modal-dialog-centered .modal-footer > button:disabled:hover {
	background-color:               var(--blue);
}

.fade.modal-backdrop{
    display:                        none;
}

.fade.confirmation-modal.modal .modal-dialog.modal-dialog-centered .modal-content{
    background:                     #FFF;
    box-shadow:                       0px 50px 200px 0px rgba(4, 26, 57, 0.15);
    border-radius:                    0;
    border:                           unset;
}

.fade.confirmation-modal.modal .modal-dialog.modal-dialog-centered .modal-content{
    display:                         flex;
    align-items:                     center;
    padding:                         80px 100px;
}

.fade.confirmation-modal.modal .modal-dialog.modal-dialog-centered {
    border:                          unset;
    padding:                         0;
}

.fade.confirmation-modal.modal .modal-dialog.modal-dialog-centered .check-icon{
    width:                           50px;
    height:                          50px;
    mask:                            url("../../images/check.svg") center center no-repeat;
    background-color:               #08D1A1;
    background-size:                 50px 50px;
}

.modal .modal-dialog-centered .modal-content .modal-header .btn-close:focus{
	box-shadow: 					unset;
}

@keyframes animatedContainerReverse {
	0%{
		visibility: 					visible;
		opacity: 						1;
	}
	100%{
		visibility: 					hidden;
		opacity: 						0;
	}
}

@keyframes animatedWidthReverse {
	0%{
		width: 							25%;
	}
	100%{
		width: 							0%;
	}
}

@keyframes animatedHeight {
	0%{
		max-height: 					0;
	}
	100%{
		max-height: 					1000px;
	}
}

@keyframes animatedTitleAfter {
	0%{
		max-width: 						0;
	}
	100%{
		max-width: 						1000px;
	}
}

@keyframes animateDashboard{
	0%{
		opacity: 						0;
		transform: 						translateX(200px);
	}
	100%{
		opacity: 						1;
		transform: 						translateX(0px);
	}
}

/*-- MEDIA QUERIES IPAD --*/
@media screen and (max-width: 1366px) and (min-width: 991px) {
	.dashboard .dashboardRightContainer {
		padding: 	 						20px 5%;
	}

	.tableReport {
		overflow-x: 						auto;
		display: 							block;
		white-space: 						nowrap;
	}
}

@media screen and (1200px >= width >= 1120px) {
	.dashboard {
		width: 								80%;
		padding-right: 						15%;
	}

	.dashboard .searchContainer {
		margin-right: 						0px;
	}
}

@media screen and (1119px >= width >= 992px) {
	.dashboard {
		width: 								70%;
		padding-right: 						10%;
	}

	.dashboard .searchContainer {
		margin-right: 						0px;
	}
}

/*-- MEDIA QUERIES MOBILE --*/
@media screen and (width <= 991px) {
	.dashboardContainer {
		height: 							auto;
	}

	.dashboardContainer.handleHeight {
		height: 							100dvh;
	}

	.dashboard .dashboardRightContainer {
		padding: 							20px 0;
	}
	
	.dashboard .welcome{
		margin-bottom: 						30px;
		font-size: 							32px;
	}

	.dashboard .recent {
		font-size: 							26px;
	}

	/* .dashboard .tableReport {
		margin-bottom: 						130px;
	} */

	.dashboard .dashboard-date,
	.dashboard .welcome,
	.dashboard .tableReport,
	.dashboard .recent {
		padding-inline: 					5%;
	}

	.tableReport {
		overflow-x: 						auto;
		display: 							block;
		white-space: 						nowrap;
	}

	.dashboard .privacy {
		position: 							absolute;
		left: 								30px;
		bottom: 							60px;
	}

	.dashboard .pinButtonContainer {
		position: 							absolute;
		left: 								30px;
		bottom: 							30px
	}

	.dashboard .pinButtonContainer .pinButton {
		margin: 							20px 20px 20px auto;
	}

	.dashboard .pinButtonContainer .pinnedReports {
		left: 								unset;
		right: 								20px;
		height: 							36px;
		width: 								36px;
	}

	.dashboard .pinButtonContainer .pinnedReports.show {
		bottom: 							70px;
	}
}

@media (height <= 800px) {
	.dashboard .searchContainer {
		margin: 							0 50px 20px auto;
	}

	.dashboard .welcome {
		margin-bottom: 						15px;
		font-size: 							45px;
	}

	.dashboard .recent {
		font-size: 							20px;
		margin-bottom: 						15px;
	}

	.dashboard .pinButtonContainer {
		bottom: 							50px;
	}
}
