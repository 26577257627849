.generic-wrapper{
    padding-top:                                    20px;
}

.generic-wrapper .row{
    margin-bottom:                                  50px;
}

.generic-wrapper .no-gutters > [class*='col-'] {
	padding-right:                                  0;
	padding-left:                                   0;
}

.generic-wrapper .table-container{
    overflow-x:                                     auto;
}

.generic-wrapper .table-container .table-header{
    display:                                        flex;
    font-weight:                                    700;
}

.generic-wrapper .city-title{
    min-width:                                      33.3%;
    padding:                                        25px;
    background:                                     white;
    height:                                         70px;
    text-align:                                     center;
    font-weight:                                    700;

}

.generic-wrapper .table-container .row-number{
    display:                                        flex;
}
.generic-wrapper .table-container .number{
    padding:                                        25px;
    min-width:                                      33.3%;
	border-bottom:                                  1px solid rgba(0, 0, 0, 0.05);
    text-align:                                     center;

}

.generic-wrapper .table-container .row-number .number.red{
    color:                                          red;
}


.generic-wrapper .spacer{
    height:                                         50px;
}

.generic-wrapper  .first-spacer{
    height:                                         70px;
    background:                                     white;
    font-weight:                                    700;
    padding:                                        25px;
}


.generic-wrapper .side-text{
    padding:                                        25px 20px;
    font-size:                                      14px;
    height:                                         70px;
    font-weight:                                    400;
	border-bottom:                                  1px solid rgba(0, 0, 0, 0.05);
}

.generic-wrapper .side-text.last,
.generic-wrapper .first-spacer.last{
    padding:                                        35px 20px;
    height:                                         90px;
}

.generic-wrapper  .side-text.highlighted{
    font-weight:                                    700;
}

.generic-wrapper .table-title{
    font-weight:                                    700;
    margin-bottom:                                  15px;
}

.generic-wrapper .number > span{
    padding:                                        10px;
}

.generic-wrapper .second-lowest,
.generic-wrapper .second-highest{
    background:                                     rgba(25, 218, 171, 0.30);
    padding:                                        10px;
}


.generic-wrapper .highest,
.generic-wrapper .lowest{
    background:                                     rgba(25, 218, 171, 0.70);
    padding:                                        10px;
}

.generic-wrapper .water-header {
    display:                                        flex;
    justify-content:                                space-evenly;
    align-items:                                    center;
    margin:                                         20px 0px;
}

.generic-wrapper .land-header {
    display:                                        flex;
    gap:                                            100px;
    padding:                                        25px 35px;
    align-items:                                    center;
    background:                                     white;
    margin-bottom:                                  unset;
}

.mobile-title {
    margin-bottom:                                  10px;
    font-weight:                                    700;
}

.mobile-main-title {
    font-size:                                      18px;
}

.mobile-details-results-table {
    margin-bottom:                                  20px;
}

.mobile-details-results-table table {
    display: 							            block;
	white-space: 						            nowrap;
	overflow-x: 						            auto;
	scrollbar-width:            		            none;
    background-image:           		            linear-gradient(to right, var(--grey), var(--grey)),
                                                    linear-gradient(to right, var(--grey), var(--grey)),
                                                    linear-gradient(to right, rgba(188, 190, 200, 0.5),rgba(245, 246, 247, 0.5)),
                                                    linear-gradient(to left, rgba(188, 190, 200, 0.5), rgba(245, 246, 247, 0.5));
    background-position:        		            left center, right center, left center, right center;
    background-repeat:          		            no-repeat;
    background-size:            		            40px 100%, 40px 100%, 40px 100%, 40px 100%;
    background-attachment:      		            local, local, scroll, scroll;
}

.full-width-table table {
    width:                                          100%;
    display:                                        table;
}

.mobile-details-results-table thead {
	background:                 		            rgba(255, 255, 255, 0.7);
    box-shadow:                 		            0px 5px 30px 0px rgba(35, 107, 216, 0.08);
}

.mobile-details-results-table thead th,
.mobile-details-results-table tbody tr td{
	padding:                    		            20px;
    font-size:                  		            12px;
}

.mobile-details-results-table  tbody tr td:not(:nth-child(1)) {
	text-align: 						            center;
}

.mobile-details-results-table tbody tr {
	border-bottom: 						            1px solid rgba(7, 27, 57, 0.05);
}

.mobile-details-results-table .strong {
    font-weight: 						            700;
}

.mobile-details-results-table .red {
    color: 								            red;
}

@media screen and (width <= 991px) {
    .generic-wrapper .water-header h4 {
        font-size:                                  16px;
    }

    .generic-wrapper .land-header {
        gap:                                        80px;
    }
}