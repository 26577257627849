.containerClientReport {
	position:                       	    absolute;
  top:                                  100vh;
	bottom:                          		  0;
	left:                            		  0;
	right:                            	 	0;
	z-index:                          	 	999;
	border-radius:                    	 	35px 35px 0 0;
	background:                       	 	var(--grey);
	box-shadow:                       	 	0 -50px 60px 0 rgba(7, 27, 57, 0.20);
	overflow-y:                       	 	auto;
	visibility:                       	 	hidden;
	opacity:                          	 	0;
  transition:                           all 0.3s ease-in-out
}

.containerClientReport.opened {
  visibility:                       	 	visible;
	opacity:                          	  1;
  bottom:                               unset;
  top:                                  100px;
  padding:                           	 	110px 0;
  /* min-height:                           calc(100vh - 100px); */
  min-height:                           100vh;
  min-height:                           100dvh;
}

.containerClientReport .containerMinimizeClose {
	display:                          	  flex;
	position:                         		absolute;
	top:                              		30px;
	right:                           	 	  30px;
	gap:                             		  30px;
}

.containerClientReport .containerMinimizeClose > img {
	width:                              	14px;
	height:                             	auto;
	object-fit:                         	contain;
	object-position:                    	center center;
	cursor:                             	pointer;
}

.containerClientReport .container .editClientsHeader {
	margin: 								              10px 0;
}

.containerClientReport .container .containerHeader {
	display:                            	flex;
    justify-content:                    	space-between;
	margin-top: 							57px;
	margin-bottom: 							80px;
}

.containerClientReport .container .containerHeader .clientLogTitle {
	width:                              	50%;
    height:                             	    auto;
}

.containerClientReport .container .containerHeader .searchContainer{
	display:     	  	  					flex;
	position: 								relative;
	width: 									fit-content;
	align-items:   	 						center;
}

.containerClientReport .container .containerHeader .searchContainer::after{
	content: 								"";
	display: 								block;
	width: 									25px;
	height: 								25px;
	background: 							url("../../images/search.svg") center center no-repeat;
	background-size: 						25px;
	position: 								absolute;
	top: 									0;
	bottom: 								0;
	right: 									25px;
	margin:  								auto 0;
	cursor: 								pointer;
}

.containerClientReport .container .containerHeader .searchContainer .search{
	width: 									272px;
	padding-right: 							65px;
    height:                                 50%;
}

.clientDirectory {
	display: 								flex;
	flex-direction: 						column;
	gap: 									30px;
}

.clientDirectory .filtersContainer {
    display:                           		flex;
    justify-content:                   		space-between;
    width: 									100%;
}

.clientDirectory .filtersContainer .pagesFilterContainer .itemsPerPageFilter {
	position: 								relative;
	display: 								flex;
	flex-direction: 						row;
    height:                                 100%;
    align-items:                            center;
	background: 							#FFFFFF;
	padding: 								6px 5px 6px 15px;
	cursor: 								pointer;
}

.clientDirectory .filtersContainer .pagesFilterContainer .itemsPerPageFilter > span {
	margin-right: 							10px;
	margin-left: 							5px;
    white-space:                            nowrap;
}

.clientDirectory .filtersContainer .pagesFilterContainer .itemsPerPageFilter.active {
    background: 							var(--gradient), var(--blue);
	background-clip: 						text, padding-box;
	-webkit-background-clip: 				text, padding-box;
	-webkit-text-fill-color: 				transparent;
}

.clientDirectory .filtersContainer .client-mobile-filter-section .primaryButton-mobile {
    display:                                inline-flex;
    border:                                 unset;
    padding:                                10px;
    justify-content:                        center;
    align-items:                            center;
    background:                             #FFFFFF;
    gap:                                    5px;
}

.clientDirectory .filtersContainer .client-mobile-filter-section .primaryButton-mobile::before {
    content:                                "";
    display:                                block;
    width:                                  20px;
    height:                                 20px;
    background:                             url("../../images/tune.svg") center center no-repeat;
    background-size:                        20px;
    margin-right:                           5px;
}

.clientDirectory .filtersContainer .client-mobile-filter-section .primaryButton-mobile::after {
    content:                                "";
    display:                                block;
    width:                                  25px;
    height:                                 25px;
    background:                             url("../../images/arrowDown.svg") center center no-repeat;
    background-size:                        25px;
}

.clientDirectory .filtersContainer .client-mobile-filter-section .primaryButton-mobile > span {
    font-size:                              12px;
    font-weight:                            400;
}

.clientDirectory .filtersContainer .client-mobile-filter-section .alphabert-mobile-container {
    position:                       	    absolute;
	top: 									100vh;
	bottom:                          		0;
	left:                            		0;
	right:                            	 	0;
	z-index:                          	 	999;
	border-radius:                    	 	35px 35px 0 0;
	background:                       	 	var(--grey);
	box-shadow:                       	 	0 -50px 60px 0 rgba(7, 27, 57, 0.20);
	overflow-y:                       	 	auto;
	visibility:                       	 	hidden;
	opacity:                          	 	0;
	transition: 							all 0.3s ease-in-out;
}

.clientDirectory .filtersContainer .client-mobile-filter-section .alphabert-mobile-container.opened {
    visibility:                       	    visible;
	opacity:                          	    1;
	bottom: 							    0;
	top: 								    0;
	padding:                           	    110px 0;
	min-height: 						    100%;
    overflow-y:                             hidden;
}

.clientDirectory .filtersContainer .client-mobile-filter-section .alphabert-mobile-container .close-image {
    position:                               absolute;
    top:                                    30px;
    right:                                  30px;
}

.clientDirectory .filtersContainer .client-mobile-filter-section .alphabert-mobile-container .alphabetInfo {
    display:                                flex;
    flex-direction:                         column;
    gap:                                    30px;
}

.clientDirectory .filtersContainer .client-mobile-filter-section .alphabert-mobile-container .alphabetInfo .alphabet-actions {
    display:                                flex;
    flex-direction:                         row;
    justify-content:                        space-between;
    align-items:                            center;
}

.clientDirectory .filtersContainer .client-mobile-filter-section .alphabert-mobile-container .alphabetInfo .alphabet-actions > button {
    padding: 								5px 15px;
	background: 							transparent;
	border: 								#FFFFFF;
	text-decoration: 						none;
	-webkit-text-fill-color: 				black;
}

.clientDirectory .filtersContainer .client-mobile-filter-section .alphabert-mobile-container .alphabetInfo .alphabet-actions > button:last-of-type {
    background: 							var(--gradient), var(--darkBlue);
	background-clip: 						text, padding-box;
	-webkit-background-clip: 				text, padding-box;
	-webkit-text-fill-color: 				transparent;
	transition: 							all 0.3s ease-in-out;
}

.clientDirectory .filtersContainer .client-mobile-filter-section .alphabert-mobile-container .alphabetInfo .alphabet-actions > button:disabled {
    background: 							rgba(0, 0, 0, 0.05);
	opacity: 								0.5;
	-webkit-text-fill-color: 				black;
}

.clientDirectory .filtersContainer .client-mobile-filter-section .alphabert-mobile-container .alphabetInfo .alphabet-options {
    max-height:                             60vh;
    overflow-y:                             auto;
}

.clientDirectory .filtersContainer .client-mobile-filter-section .alphabert-mobile-container .alphabetInfo .alphabet-options .letter-container {
    display:                                flex;
    flex-direction:                         row;
    align-items:                            center;
    padding:                                20px 0;
    border-bottom:                          1px solid rgba(0, 0, 0, 0.05);
    gap:                                    20px;
}

.clientDirectory .pagesFilterContainer .itemsPerPageFilter .filterOptions {
    background: 							rgba(255, 255, 255, 0.90);
	box-shadow: 							0px 15px 50px 0px rgba(4, 26, 57, 0.15);
	position: 								absolute;
	width: 									100%;
	top: 									120%;
	right:									0;
	z-index: 								999;
	visibility: 							hidden;
	opacity: 								0;
	transition: 							all 0.3s ease-in-out;
	max-height: 							0;
	overflow: 								hidden;
}

.clientDirectory .pagesFilterContainer .itemsPerPageFilter .filterOptions.show {
	visibility: 							visible;
	opacity: 								1;
	max-height: 							1000px;
}

.clientDirectory .pagesFilterContainer .itemsPerPageFilter .filterOptions .option {
	padding: 								10px 35px;
	cursor: 								pointer;
	-webkit-text-fill-color: 				black;
}

.clientDirectory .pagesFilterContainer .itemsPerPageFilter .filterOptions .option:hover {
	background: 							var(--gradient), rgba(4, 26, 57, 0.10);
	background-clip: 						text, padding-box;
	-webkit-background-clip: 				text, padding-box;
	-webkit-text-fill-color: 				transparent;
}

/* CSS for the alphabet filter */

.clientDirectory .alphabetContainer {
  display:                            flex;
  flex-direction:                     row;
  gap:                                3px;
}

.clientDirectory .alphabetContainer .buttonLetter {
  border:                             none;
  text-decoration:                    none;
  background:                         transparent;
  width:                              25px;
  height:                             auto;
}



.clientDirectory .clientsCardsSection {
  display:                            flex;
  flex-direction:                     row;
  gap:                                35px;
  flex-wrap:                          wrap;
  width:                              100%;
}

.clientDirectory .clientsCardsSection .clientCard {
  display:                            flex;
  position:                           relative;
  flex-direction:                     column;
  background:                         white;
  padding:                            50px 50px 60px 50px;
  width:                              calc(50% - 17.5px);
  height:                             auto;
  flex-shrink:                        0;
  gap:                                20px;
}

.clientDirectory .clientsCardsSection .clientCard .noteModal {
  top:                                calc(50% - 122px);
  right:                              calc(50% - 295px);
  width:                              590px;
  min-height:                         244px;
  background:                         white;
  box-shadow:                         30px 30px 100px 0px rgba(4, 26, 57, 0.20);
  z-index:                            99999;
  position:                           fixed;
  opacity:                            0;
  visibility:                         hidden;
  transition:                         all 0.3s ease-in-out;
}

.clientDirectory .clientsCardsSection .clientCard .noteModal .modalInfo {
  position:                           relative;
  display:                            flex;
  gap:                                20px;
  flex-direction:                     column;
  padding:                            40px 67px 78px 67px;
}

.clientDirectory .clientsCardsSection .clientCard .noteModal.show {
  opacity:                            1;
  visibility:                         visible;
}

.clientDirectory .clientsCardsSection .clientCard .noteModal .modalInfo > p{
  padding:                            20px;
}

.clientDirectory .clientsCardsSection .clientCard .noteModal .modalInfo > img{
  position:                           absolute;
  height:                             14px;
  width:                              14px;
  top:                                9px;
  right:                              10px;
  cursor:                             pointer;
}

.clientDirectory .clientsCardsSection .clientCard .cardOptions {
  position:                           absolute;
  right: 							              	10px;
  top: 								                5px;
  z-index: 						              	998;
}

.clientDirectory .clientsCardsSection .clientCard .cardOptions > img {
	width: 								              25px;
	height: 							              25px;
	object-fit: 						            contain;
	object-position: 			              center center;
	cursor: 							              pointer;
}

.clientDirectory .clientsCardsSection .clientCard .moreOptionsClient {
	background: 					            	rgba(255, 255, 255, 0.90);
	box-shadow: 					            	0px 15px 50px 0px rgba(4, 26, 57, 0.15);
	position: 						             	absolute;
	right: 							              	-75px;
	top: 								                25px;
	z-index: 						              	999;
	visibility: 				             		hidden;
	opacity: 						              	0;
	transition: 				             		all 0.3s ease-in-out;
	max-height: 				             		0;
	overflow: 						             	hidden;
  width:                              180px;
}

.clientDirectory .clientsCardsSection .clientCard .moreOptionsClient.show {
	visibility: 						            visible;
	opacity: 						               	1;
	max-height: 				               	1000px;
}

.clientDirectory .clientsCardsSection .clientCard .moreOptionsClient .option {
	padding: 					               		10px 35px;
	cursor: 					               		pointer;
}

.clientDirectory .clientsCardsSection .clientCard .moreOptionsClient .option:hover {
	background: 				               	var(--gradient), rgba(4, 26, 57, 0.10);
	background-clip: 				           	text, padding-box;
	-webkit-background-clip: 		      	text, padding-box;
	-webkit-text-fill-color: 			      transparent;
}

/* .clientDirectory .clientsCardsSection .clientCard .moreOptionsClient .option:last-of-type {
	color: 							               	var(--red);
}

.clientDirectory .clientsCardsSection .clientCard .moreOptionsClient .option:last-of-type:hover {
	background: 				               	rgba(4, 26, 57, 0.10);
	background-clip: 				           	padding-box;
	-webkit-background-clip: 		       	padding-box;
	-webkit-text-fill-color: 		       	var(--red);
} */

.clientDirectory .clientsCardsSection .clientCard .clientCard-title-section {
  display:                            flex;
  flex-direction:                     column;
  gap:                                20px;
}

.clientDirectory .clientsCardsSection .clientCard .clientCard-title-section .logo {
  display:                            flex;
  justify-content:                    center;
  align-items:                        center;
  border-radius:                      50%;
  background: 						            var(--gradient);
  width:                              80px;
  height:                             80px;
  box-shadow:                         15px 15px 30px 0 rgba(35, 107, 216, 0.25);
  cursor:                             pointer;
}
  
.clientDirectory .clientsCardsSection .clientCard .clientCard-title-section .logo > span{
  color:                              white;
  height:                             48px;
  width:                              23px;
  font-size:                          32px;
  text-align:                         center;
}
  
.clientDirectory .clientsCardsSection .clientCard .clientCard-title-section .logo > img{
  object-fit: 					              cover;
  object-position: 				            center center;
  width:                              80px;
  height:                             80px;
  border-radius:                      50%;
  border:                             5px solid transparent;
}

.clientDirectory .clientsCardsSection .clientCard .clientInfo {
  display:                            flex;
  flex-direction:                     row;
  flex-wrap:                          wrap;
  gap:                                10px;
  width:                              100%;
}

.clientDirectory .clientsCardsSection .clientCard .clientInfo .tooltipContainer {
  width:                              calc(50% - 5px);
  position:                           relative;
}

.clientDirectory .clientsCardsSection .clientCard .clientInfo .tooltipContainer .info {
  min-height:                         70px;
  overflow:                           hidden;
}


.clientDirectory .clientsCardsSection .clientCard .clientInfo .tooltipContainer .info > h4 {
  max-height:                         50px;
  white-space:                        normal;
  overflow:                           hidden;
  text-overflow:                      ellipsis;
  font-size:                          20px;
  font-weight:                        400;
  display:                            -webkit-box;
  -webkit-line-clamp:                 2;
  -webkit-box-orient:                 vertical;
}

.clientDirectory .clientsCardsSection .clientCard .clientInfo .tooltipContainer .info .clientMail {
  display:                            block;
}


.clientDirectory .clientsCardsSection .clientCard .clientInfo .tooltipContainer .info .tooltipClients {
  position:                           absolute;
  min-width:                          200px;
  height:                             auto;
  bottom:                             120%;
  left:                               0;
  z-index:                            999;
  background-color:                   #ffffff;
  box-shadow:                         0 2px 4px rgba(0, 0, 0, 0.1);
  padding:                            15px;
}

.clientDirectory .clientsCardsSection .clientCard .clientInfo .tooltipContainer .info .tooltipClients::before {
  content: 							              "";
	width: 								              0;
	height: 							              0;
	border-left: 						            10px solid transparent;
	border-right: 						          10px solid transparent;
	border-top: 						            10px solid #FFFFFF;
	position: 							            absolute;
	left: 								              20%;
	top: 							                  100%;
	margin: 							              0 auto;
}

.clientDirectory .clientsCardsSection .clientCard .clientInfo .tooltipContainer .info .subtitle {
  font-size:                          12px;
  opacity:                            0.5;
}

/* editClients CSS */

.editClientSection .containerActions {
  display:                            flex;
  justify-content:                    flex-end;
  gap:                                10px;
}

.editClientSection .containerActions .cancelButton {
  padding:                            10px 50px;
  justify-content:                    center;
  align-items:                        center;
  border:                             unset;
  background:                         transparent;
}

.editClientSection .containerActions .cancelButton:focus-visible {
  outline:                            unset;
}

.editClientSection .secondaryButton {
  padding:                            10px 50px;
  justify-content:                    center;
  align-items:                        center;
  margin-left:                        unset;
}

.editClientSection .secondaryButton:disabled {
  background:                         rgba(0, 0, 0, 0.05);
}

.editClientSection .secondaryButton:disabled > span {
  background:                         rgba(0, 0, 0, 0.40);
  background-clip:                    text;
  -webkit-background-clip:            text;
  -webkit-text-fill-color:            transparent;
}

.editClientSection .secondaryButton {
  display:                            inline-flex;
  background:                         var(--blue);
  padding:                            10px 10px;
  border:                             unset;
  gap:                                5px;
  align-items:                        center;
}

.editClientSection .secondaryButton > span {
  font-weight:                        400;
  background:                         var(--gradient);
  background-clip:                    text;
  -webkit-background-clip:            text;
  -webkit-text-fill-color:            transparent;
}

.editClientSection .secondaryButton:hover {
  background-color:                   var(--darkBlue);
}

.editClientSection .secondaryButton:focus-visible {
  outline:                            unset;
}

.editClientSection .secondaryButton > span:hover {
  background:                         var(--gradient);
  background-clip:                    text;
  -webkit-background-clip:            text;
  -webkit-text-fill-color:            transparent;
}

.editClientSection .secondaryButton {
  padding:                            10px 50px;
  justify-content:                    center;
  align-items:                        center;
  margin-left:                        unset;
}

.editClientSection .secondaryButton:disabled {
  background:                         rgba(0, 0, 0, 0.05);
}

.editClientSection .secondaryButton:disabled > span {
  background:                         rgba(0, 0, 0, 0.40);
  background-clip:                    text;
  -webkit-background-clip:            text;
  -webkit-text-fill-color:            transparent;
}

.editClientSection .inputContainer .placesFoundContainer {
  background: 						rgba(255, 255, 255, 0.90);
  box-shadow: 						0 15px 50px 0 rgba(4, 26, 57, 0.15);
  position: 							absolute;
  width: 								100%;
  z-index: 							999;
  visibility: 						hidden;
  opacity: 							0;
  transition: 						all 0.3s ease-in-out;
  max-height: 						0;
  overflow: 							hidden;
}

.editClientSection .inputContainer .placesFoundContainer.show {
    visibility: 						visible;
    opacity: 							1;
    max-height: 						1000px;
}

.editClientSection .inputContainer .placesFoundContainer .placeFound{
    padding: 							10px 35px;
    cursor: 							pointer;
}

.editClientSection .inputContainer .placesFoundContainer .placeFound:hover{
    background: 						var(--gradient), rgba(4, 26, 57, 0.10);
    background-clip: 					text, padding-box;
    -webkit-background-clip: 			text, padding-box;
    -webkit-text-fill-color: 			transparent;
}

.editClientSection .inputContainer input[type="file"]{
    background-color:                   transparent !important;
    padding:                            0 !important;
}

.editClientSection .inputContainer input[type="file"]::file-selector-button{
    padding:                            10px 15px;
    border:                             unset;
    background:                         var(--gradient), var(--blue);
    background-clip:                    text, padding-box;
    -webkit-background-clip:            text, padding-box;
    -webkit-text-fill-color:            transparent;
    cursor:                             pointer;
    transition:                         all 0.3s ease-in-out;
}

.editClientSection .inputContainer input[type="file"]::file-selector-button:hover{
    background:                         var(--gradient), var(--darkBlue);
    background-clip:                    text, padding-box;
    -webkit-background-clip:            text, padding-box;
}

.editClientSection .inputFileContainer {
    display:                            flex;
    justify-content:                    space-between;
    padding-right:                      15px;
    align-items:                        center;
    margin-bottom:                      25px;
}

.editClientSection .inputFileContainer > img {
    cursor:                             pointer;
}

.editClientSection .inputFileContainer .selectFileContainer {
    display:                            flex;
    align-items:                        center;
    gap:                                10px;
    white-space:                        nowrap;
    overflow:                           hidden;
}

.editClientSection .inputFileContainer .selectFileContainer > button {
    height:                             40px;
}

.editClientSection .inputFileContainer .selectFileContainer > span {
    overflow:                           hidden;
    text-overflow:                      ellipsis;
}

.editClientSection .inputContainer > textarea{
	width: 								              100%;
	border: 							              unset;
	background-color: 				        	#FFFFFF;
	padding: 							              15px 20px;
	font-size: 						            	14px;
	position: 						            	relative;
	border-bottom: 				          		1px solid transparent;
	transition: 					            	all 0.3s ease-in-out;
}

.editClientSection .inputContainer > textarea::placeholder{
	opacity: 						               	0.4;
}

.editClientSection .inputContainer > textarea:focus{
	outline: 						              	unset;
	border-bottom: 				           		1px solid #009AFF;
}

.modal-client-image .modal-dialog .modal-content{
    max-width:                          500px;
    min-height:                         400px;
    background:                         #FFF;
    box-shadow:                         0px 50px 200px 0px rgba(4, 26, 57, 0.15);
    border-radius:                      0;
    border:                             unset;
}

.modal-client-image .modal-dialog .modal-content .modal-body {
    display:                            flex;
    align-items:                        center;
    justify-content:                    center;
}

.modal-client-image .modal-dialog .modal-content .modal-body > img {
    height:                             fit-content;
    width:                              fit-content;
}


/*-- MEDIA QUERIES IPAD --*/
@media screen and (max-width: 1200px) and (min-width: 991px) {
	.containerClientReport .container .containerHeader {
		display:                            flex;
		justify-content:                    space-between;
		margin-top:                         20px;
		margin-bottom: 					    60px;
	}
    .clientDirectory .alphabetContainer {
        gap:                                2px;
    }
}

@media screen and (width <= 991px) {
    .clientDirectory .filtersContainer {
        justify-content:                flex-end;
        gap:                            20px;
    }

    .containerClientReport .container .containerHeader {
        flex-direction: 						        column;
		gap: 									              20px;
		margin-top: 							          20px;
		margin-bottom: 							        40px;
    }

    .containerClientReport .container .containerHeader .clientLogTitle,
    .containerClientReport .container .containerHeader .searchContainer,
    .containerClientReport .container .containerHeader .searchContainer .search {
        width: 								              100%;
    }

    .containerClientReport .container .containerHeader .searchContainer .search {
        height:                             100%;
    }

    .clientDirectory .clientsCardsSection {
        flex-direction:                     column;
        gap:                                15px;
    }

    .clientDirectory .clientsCardsSection .clientCard{
        width:                              100%;
        padding:                            30px;
    }

    .clientDirectory .clientsCardsSection .clientCard .clientCard-title-section {
        flex-direction:                     row;
        gap:                                25px;
        align-items:                        center;
    }

    .clientDirectory .clientsCardsSection .clientCard .clientInfo .tooltipContainer .info {
        min-height:                         60px;
    }
}