.compare-button{
    display:                    flex;
    justify-content:            flex-end;
    position:                   relative;
}

.details-wrapper .compare-button{
    justify-content:                flex-start;
}

.compare-button .compare-button-text{
    display:                    flex;
    align-items:                center;
    cursor:                     pointer;
    transition:                 background 0.1s ease-in-out;
    border:                     unset;
    font-size:                  14px;
    font-weight:                400;
    background:                 unset;
    color:                      #009AFF;
}

.wants-view .compare-button .compare-button-text.active,
.wants-view .compare-button .compare-button-text:hover{
    color: black;
}


.details-wrapper .compare-button .compare-button-text,
.summary-wrapper .compare-button .compare-button-text{
    background: 						rgba(0, 163, 255, 0.10);
    padding:                            7px 10px;
    white-space:                        nowrap;
}

.details-wrapper .compare-button .compare-button-text::before,
.summary-wrapper .compare-button .compare-button-text::before{
    content: "";
    width:                       15px;
    height:                      15px;
    mask:                        url("../../../images/compare_arrows.svg") center center no-repeat;
    background:                   var(--gradient);
    background-size:             15px 15px;
    transition:                 transform 0.1s ease-in-out;
    margin-right:               5px;
}

.details-wrapper .compare-button .compare-button-text:hover,
.details-wrapper .compare-button .compare-button-text.active,
.summary-wrapper .compare-button .compare-button-text:hover,
.summary-wrapper .compare-button .compare-button-text.active{
    background:							#041A39;
}

.details-wrapper .compare-button .compare-button-text > span,
.summary-wrapper .compare-button .compare-button-text > span{
    font-size: 							16px;
    font-weight:                        500;
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
}


.summary-box-head .compare-button .compare-button-text.active::after{
    background:                 #009AFF;
}

 .wants-view .compare-button .compare-button-text.active::after{
    background:                black;
    
}

.compare-button .compare-button-text.active::after{
    transform:                  rotate(0deg);
    transition:                 transform 0.1s ease-in-out;
}

.wants-view .compare-button .compare-button-text:hover::after{
    background:                black;
}

.compare-button .compare-button-text::after{
    content: "";
    transform:                   rotate(180deg);
    width:                       15px;
    height:                      15px;
    mask:                        url("../../../images/expand_less.svg") center center no-repeat;
    background:                   var(--gradient);
    background-size:             15px 15px;
    transition:                 transform 0.1s ease-in-out;
}

.compare-button .compare-menu{
    padding:                        35px;
    background:                    #FFF;
    box-shadow:                     0px 15px 50px 0px rgba(7, 27, 57, 0.15);
    gap:                            20px;
    display:                        flex;
    flex-direction:                 column;
    position:                       absolute;
    opacity:                        0;
    transition:                     all 0.3s ease-in-out;
    z-index:                         1;
    top:                            30px;
    right:                          -30px;
    color:                          black;
    height:                          300px;
    visibility:                      hidden;
    min-width:                      300px;
}

.summary-box-head .compare-button .compare-menu{
    top: -320px;
    right: -20px;
}

.details-wrapper .tabs-container .compare-button .compare-menu{
    right: 920px;
}

.compare-button .compare-menu .close {
    position:                   absolute;
    width:                      12px;
    height:                     12px;
    top:                        20px;
    right:                      20px;
}


.compare-button .compare-menu.active{
    z-index: 999;
    opacity: 1;
    visibility: visible;
}

.compare-button .compare-menu .compare-menu-head{
    font-weight:                    700;
    text-align:                     left;
}

.compare-button .compare-menu .compare-menu-body{
    gap:                            35px;
    display:                        flex;
    flex-direction:                 column;
    flex:                           1 0 0 ;
    overflow-y:                     auto;
}

.compare-button .compare-menu .compare-menu-body .input-group{
    display:                        flex;
    flex-direction:                 column;
    /* padding-right:                   px; */
    flex:                           1 0 0;
}

.compare-button .compare-menu .compare-menu-body .input-group .input-row{
    padding:                        0 15px;
    flex:                           1 0 0;
}

.compare-button .compare-menu .compare-menu-body .input-group .input-row{
    display:                        flex;
    gap:                            10px;
}

.compare-button .compare-menu .compare-menu-body .input-group .input-row > label {
    cursor:                         pointer;
}

.compare-button .compare-menu .compare-menu-footer{
    display:                        flex;
    align-items:                    flex-start;
    justify-content:                space-between;
}

.compare-button .compare-menu .compare-menu-footer>button{
    border:                         unset;
    background:                     unset;
    padding:                        6px 15px;
}

.compare-button .compare-menu .compare-menu-footer .apply-button{
    background:                     var(--Blue_10, rgba(0, 163, 255, 0.10));
    transition:                     background 0.2s ease-in-out;
}

.compare-button .compare-menu .compare-menu-footer .apply-button>h5{
    background:                     var(--gradient, linear-gradient(180deg, #009AFF 19.79%, #8CA5FF 100%));
    background-clip:                text;
    -webkit-background-clip:        text;
    -webkit-text-fill-color:        transparent;
}

.compare-button .compare-menu .compare-menu-footer .apply-button:hover{
    background:                     var(--darkBlue);
}

@media screen and (width <= 991px) {
    .compare-button .compare-menu {
        position:                   fixed !important;
        left:                       50% !important;
        top:                        50% !important;
        transform:                  translate(-50%, -50%) !important;
    }
    
    .summary-button .summary-box-head .compare-button .compare-menu {
        left:                       50% !important;
        top:                        50% !important;
        transform:                  translate(-50%, -120%) !important;
    }
}