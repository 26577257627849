.navigationMobile {
    display:                            flex;
    flex-direction:                     row;
    align-items:                        center;
    gap:                                5px;
    overflow-x:                         auto;
    overflow-y:                         hidden;
    height:                             210px;
    width:                              100%;
    flex-wrap:                          nowrap;
    scrollbar-width:                    none;
    position:                           relative;
    padding-inline:                     5%;
    margin-bottom:                      40px;
    background-image:                   linear-gradient(to right, var(--grey), var(--grey)),
                                        linear-gradient(to right, var(--grey), var(--grey)),
                                        linear-gradient(to right, rgba(111, 113, 116, 0.5),rgba(245, 246, 247, 0.5)),
                                        linear-gradient(to left, rgba(111, 113, 116, 0.5), rgba(245, 246, 247, 0.5));
    background-position:                left center, right center, left center, right center;
    background-repeat:                  no-repeat;
    background-size:                    40px 100%, 40px 100%, 40px 100%, 40px 100%;
    background-attachment:              local, local, scroll, scroll;
}

.navigationMobile .navMobile-card {
    display:                            flex;
    position:                           relative;
    height:                             200px;
    width:                              200px;
    flex-direction:                     column;
    gap:                                15px;
    padding:                            20px;
    background-color:                   rgba(255, 255, 255, 0.75);
    box-shadow:                         0px 5px 10px 0px rgba(35, 107, 216, 0.1);
    flex:                               0 0 200px;
}

.navigationMobile .navMobile-card > img {
    width:                              24px;
    height:                             24px;
    object-fit:                         contain;
}

.navigationMobile .navMobile-card .navMobile-card-dots {
    position:                           absolute;
    width:                              24px;
    height:                             24px;
    object-fit:                         contain;
    top:                                10px;
    right:                              5px;
    rotate:                             90deg;
}

.navigationMobile .navMobile-card .navMobile-card-options {
    position:                           absolute;
    display:                            flex;
    flex-direction:                     column;
    gap:                                20px;
    width:                              fit-content;
    height:                             auto;
    max-height:                         150px;
    overflow-y:                         auto;
    background-color:                   #fff;
    padding:                            25px;
    visibility:                         hidden;
    opacity:                            0;
    transition:                         all 0.3s ease;
    top:                                20%;
    right:                              10%;
    z-index:                            2;
    filter:                             drop-shadow(0px 0px 5px rgba(35, 107, 216, 0.15));
}

.navigationMobile .navMobile-card .navMobile-card-options::-webkit-scrollbar {
	width: 								2px;
	height:                             2px;
}

.navigationMobile .navMobile-card .navMobile-card-options.show {
    visibility:                        visible;
    opacity:                           1;
}

.navigationMobile .navMobile-card .navMobile-card-options .disabledOption {
    opacity:                           0.3;
    pointer-events:                    none;
}