.streetviewContainer {
    position:                                   absolute;
    top:                                        50px;
    left:                                       50%;
    transform:                                  translateX(-50%);
    background:                                 white;
    width:                                      80%;
    height:                                     80%;
    z-index:                                    99999;
    opacity:                                    0;
    visibility:                                 hidden;
    transition:                                 all 0.3s ease-in-out;
    box-shadow:                                 0px 15px 150px 0px rgba(7, 27, 57, 0.30);
}

.streetviewContainer.show {
    opacity:                                    1;
    visibility:                                 visible;
}

.streetviewHeader {
    display:                                    flex;
    align-items:                                center;
    justify-content:                            space-between;
    width:                                      100%;
    height:                                     50px;
    background:                                 var(--darkBlue);
    padding:                                    15px 20px;
}

.streetviewContainer .streetviewHeaderTitle {
    display:                                    flex;
    align-items:                                center;
    gap:                                        10px;
}

.streetviewContainer .streetviewHeader .streetviewHeaderTitle > img {
    transform:                                  rotate(180deg);
    width:                                      20px;
    height:                                     20px;
    cursor:                                     pointer;
}

.streetviewContainer .streetviewHeader .refresh {
    width:                                      24px;
    height:                                     24px;
    cursor:                                     pointer;
}

.streetviewContainer .tourTexts {
    color:                                      white;
    -webkit-text-fill-color:                    white;
}

.streetviewContainer .tourLocationsMenu {
    position:                                   absolute;
    bottom:                                     20px;
    left:                                       20px;
    background:                                 var(--darkBlue);
    width:                                      auto;
    z-index:                                    999999;
    opacity:                                    .9;
}

.streetviewContainer .tourLocationsMenu .tourLocationsHeader {
    display:                                    flex;
    position:                                   relative;
    align-items:                                center;
    padding:                                    10px 25px;
    gap:                                        50px;
    cursor:                                     pointer;
    box-shadow:                                 0px -15px 30px 0px rgba(35, 107, 216, 0.25);
    z-index:                                    999999;
}

.streetviewContainer .tourLocationsMenu .tourLocationsHeader .headerContainer,
.streetviewContainer .tourLocationsMenu .tourLocationsList .listCategory .categoryTitle {
    display:                                    flex;
    align-items:                                center;
    flex-direction:                             row;
    gap:                                        10px;
}

.streetviewContainer .tourLocationsMenu .tourLocationsList {
    display:                                    flex;
    flex-direction:                             column;
    gap:                                        15px;
    width:                                      100%;
    padding:                                    20px;
    position:                                   absolute;
    bottom:                                     100%;
    background:                                 white;
    max-height:                                 350px;
    visibility:                                 hidden;
    opacity:                                    0;
    transition:                                 all 0.3s ease-in-out;
    box-shadow:                                 0px 15px 50px rgba(35, 107, 216, 0.25);
}

.streetviewContainer .tourLocationsMenu .tourLocationsList.show {
    opacity:                                    .9;
    visibility:                                 visible;
}

/* bootstrap accordion classes */
.streetviewContainer .tourLocationsMenu .tourLocationsList .accordion {
    display:                                    flex;
    flex-direction:                             column;
    gap:                                        15px;
}

.streetviewContainer .tourLocationsMenu .tourLocationsList .accordion-item {
    border:                                     none;
}

.streetviewContainer .tourLocationsMenu .tourLocationsList .accordion-button {
    font-size:                                  16px;
    font-weight:                                400;
    padding:                                    0;
    gap:                                        10px;
}

.streetviewContainer .tourLocationsMenu .tourLocationsList .accordion-button:focus {
    border-color:                               unset;
    box-shadow:                                 unset;
}

.streetviewContainer .tourLocationsMenu .tourLocationsList .accordion-button:not(.collapsed) {
    background:                                 var(--gradient);
    background-clip:                            text;
    -webkit-text-fill-color:                    transparent;
    background-color:                           unset;
    box-shadow:                                 unset;
}

.streetviewContainer .tourLocationsMenu .tourLocationsList .accordion-button::after {
    background-image:                           url("/src/assets/images/arrowDownBlack.svg");
}

.streetviewContainer .tourLocationsMenu .tourLocationsList .accordion-button:not(.collapsed)::after {
    background-image:                           url("/src/assets/images/arrowDown.svg");
    transform:                                  rotate(180deg);
}

.streetviewContainer .tourLocationsMenu .tourLocationsList .accordion-body {
    padding:                                    3px 0 0 0;
}

.streetviewContainer .tourLocationsMenu .tourLocationsList .ul{
    list-style-type:                            none;
    display:                                    flex;
    flex-direction:                             column;
    gap:                                        10px;
}

.streetviewContainer .tourLocationsMenu .tourLocationsList .ul > li{
    font-size:                                  14px;
    font-weight:                                400px;
    cursor:                                     pointer;
}

@media screen and (width <= 991px) {
    .gmnoprint {
        display: none;
    }
    
    .streetviewContainer {
        top:                                        0px;
        width:                                      100%;
        height:                                     90%;
    }

    .streetviewContainer .tourLocationsMenu {
        position:                                   absolute;
        bottom:                                     -49px;
        left:                                       0;
        background:                                 var(--darkBlue);
        width:                                      100%;
        z-index:                                    999999;
    }

    .streetviewContainer .tourLocationsMenu .tourLocationsHeader {
        justify-content:                            space-between;
        padding-right:                              30px;
        gap:                                        unset;
    }
}