.results-Footer {
  display:                        flex;
  width:                          100%;
  height:                         73px;
  position:                       fixed;
  bottom:                         0;
  background-color:               #fff;
  z-index:                        100;
  box-shadow:                     0 -3px 20px 0 rgba(7, 27, 57, 0.1);
}

.results-Footer .footer-section {
  display:                        flex;
  flex-direction:                 column;
  width:                          33.33%;
  align-items:                    center;
  justify-content:                center;
  padding:                        30px;
}

.results-Footer.client .footer-section {
  display:                        flex;
  flex-direction:                 column;
  width:                          50%;
  align-items:                    center;
  justify-content:                center;
  padding:                        30px;
}

.results-Footer.client .footer-section:first-of-type {
  display:                        none;
}

.results-Footer .footer-section #goBack::before {
  content:                        "";
  display:                        flex;
  width:                          24px;
  height:                         24px;
  mask:                           url("../../images/go_back.svg") center center no-repeat;
  background:                     #041A39;
  background-size:                24px 24px;
  margin:                         0 auto;
}

.results-Footer .footer-section #edit-save::before {
  content:                        "";
  display:                        flex;
  width:                          24px;
  height:                         24px;
  mask:                           url("../../images/edit.svg") center center no-repeat;
  background:                     #041A39;
  background-size:                24px 24px;
  margin:                         0 auto;
}

.results-Footer .footer-section.save #edit-save::before {
  mask:                          url("../../images/save.svg") center center no-repeat;
}

.results-Footer .footer-section #map::before {
  content:                        "";
  display:                        flex;
  width:                          24px;
  height:                         24px;
  mask:                           url("../../images/public.svg") center center no-repeat;
  background:                     #041A39;
  background-size:                24px 24px;
  margin:                         0 auto;
}

.results-Footer .footer-section #wants::before {
  content:                        "";
  display:                        flex;
  width:                          24px;
  height:                         24px;
  mask:                           url("../../images/data_table.svg") center center no-repeat;
  background:                     #041A39;
  background-size:                24px 24px;
  margin:                         0 auto;
}

.results-Footer .footer-section .title {
  font-size:                      10px;
  white-space:                    nowrap;
  font-weight:                    700;
  margin-top:                     5px;
  text-align:                     center;
  opacity:                        0.6;
}

.results-Footer .footer-section.active .title {
  opacity:                        1;
}

.results-Footer .footer-section.active .title::before,
.results-Footer .footer-section.save .title::before {
  background:                     var(--gradient) !important
}