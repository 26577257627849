.map {
  position:                   fixed;
  width:                      100%;
  z-index:                    1;
  top:                        0;
  left:                       0;
  transition:                 left 1s ease
}

.map.hidden-left{
  left:                      -40%;
}

.map.hidden-right{
  left:                       40%;  
}

/* CSS for distance marker */

.map .distance {
  background:                 var(--darkBlue);
  padding:                    5px 10px;
}

.map .distance > span {
  color:                      white;
  font-size:                  16px;
  font-weight:                700;
}

.marker-popup {
  width:                      80px;
  height:                     100px;
  display:                    flex;
  align-items:                flex-end;
  justify-content:            center;
}

.marker-popup .cityTitle {
  position:                   absolute;
  bottom:                     -43px;
  background:                 rgba(0, 163, 255, 0.20);
  padding:                    5px 20px;
  display:                    inline-flex;
  justify-content:            center;
  align-items:                center;
  opacity:                    1;
  visibility:                 visible;
  transition:                 all 0.3s ease-in-out;
  cursor:                     pointer;
}

.marker-popup .cityTitle.hide {
  opacity:                    0;
  visibility:                 hidden;
}

.marker-popup .cityTitle > h5 {
  font-weight:                700;
}

/* non Interactable markers CSS properties */

.placeMarker {
  width:                      auto;
  position:                   relative;
  height:                     auto;
  display:                    flex;
  align-items:                center;
  justify-content:            center;
}

.placeMarker > img {
  cursor:                     pointer;
}

.placeMarker .placeTitleContainer {
  position:                   absolute;
  width:                      fit-content;
  background:                 rgba(0, 163, 255, 0.20);
  padding:                    5px 20px;
  right:                      20px;
  display:                    flex;
  white-space:                nowrap;
  flex-direction:             column;
  align-items:                center;
  justify-content:            center;
  gap:                        1px;
  opacity:                    0;
  visibility:                 hidden;
  cursor:                     pointer;
  transition:                 all 0.3s ease-in-out;
}

.placeMarker .placeTitleContainer.show {
  visibility:                 visible;
  opacity:                    1;
}

.placeMarker .placeTitleContainer .placeTitle {
  font-size:                  14px;
  font-weight:                500;
}

.placeMarker .placeTitleContainer .placeSubTitle {
  font-size:                  12px;
  font-weight:                400;
  opacity:                    0.5;
}

/* CSS for marker */
.marker {
  width:                      33px;
  height:                     42px;
  cursor:                     pointer;
  transition:                 height 0.3s ease-in-out, width 0.3s ease-in-out;
}

.marker.active {
  width:                      80px;
  height:                     100px
}

/* css of popup for popup */

.custom-popup {
  position:                   absolute;
  top:                        -200px;
  right:                       100px;
  background:                 white;
  width:                      fit-content;
  padding:                    0;
  max-height:                 0px;
  opacity:                    0;
  visibility:                 hidden;
  overflow:                   hidden;
  transition:                 all 0.4s ease-in-out;
}

.custom-popup.open {
  min-width:                  370px;
  max-height:                 750px;
  visibility:                 visible;
  opacity:                    1;
  z-index:                    4;
}

.custom-popup .close-popup > img {
  position:                   absolute;
  cursor:                     pointer;
  top:                        0px;
  right:                      0px;
  padding:                    10px;
}

.custom-popup .img {
  width:                      100%;
  height:                     300px;
  object-fit:                 cover;
}

.custom-popup .header {
  display:                    flex;
  flex-direction:             column;
  padding:                    20px 60px 20px 40px;
  background:                 var(--darkBlue);
}

.custom-popup .header > h4, 
.custom-popup .header > p {
  color:                      white;
}

.custom-popup .header >  p {
  opacity:                    0.5;
}

.custom-popup .infoContainer {
  display:                    flex;
  width:                      100%;
  flex-direction:             row;
  flex-wrap:                  wrap;
  padding:                    33px 40px;
  gap:                        30px;
}

.custom-popup .infoContainer .info {
  width:                      calc(50% - 15px);
}

.custom-popup .infoContainer .info > h4, 
.custom-popup .infoContainer .info > p {
  color:                      black;
}

.custom-popup .infoContainer .info >  p {
  opacity:                    0.5;
}

.custom-popup .infoContainer .info >  a {
  margin:                     6px 0;
  background: 				        var(--gradient);
	background-clip: 				    text;
	-webkit-background-clip: 	  text;
	-webkit-text-fill-color: 	  transparent;
}

.custom-popup .btnContainer {
  display:                    flex;
  flex-direction:             row;
  padding:                    20px 25px;
  align-items:                center;
  gap:                        10px;
}

.custom-popup .btnContainer .button {
  display:                    inline-flex;
  justify-content:            center;
  align-items:                center;
  white-space:                nowrap;
  background:                 var(--blue);
  padding:                    6px 10px;
  border:                     unset;
  gap:                        5px;
}

.custom-popup .btnContainer .button > span {
  line-height:                24px;
  font-weight:                400;
  background:                 var(--gradient);
  background-clip:            text;
  -webkit-background-clip:    text;
  -webkit-text-fill-color:    transparent;
}

.custom-popup .btnContainer .dropdown-toggle::after {
  position:                   static;
}

.custom-popup .btnContainer .primary {
  min-width:                  calc(40% - 5px);
}

.custom-popup .btnContainer .button.primary::after {
  content:                    "";
  border:                     unset;
  width:                      20px;
  height:                     20px;
  mask:                       url(../../images/man-walking.svg) center center no-repeat;
  background:                 var(--gradient);
  background-position:        center center;
  background-repeat:          no-repeat;
  background-size:            20px;
}

.custom-popup .btnContainer .secondary {
  min-width:                  calc(60% - 5px);
}

.custom-popup .btnContainer .button:disabled {
  background:                 rgba(0, 0, 0, 0.3);
  opacity:                    unset;
  cursor:                     not-allowed;
}

.custom-popup .btnContainer .button:disabled > span {
  background:                 rgba(0, 0, 0, 0.3);
  background-clip:            text;
  -webkit-background-clip:    text;
  -webkit-text-fill-color:    transparent;
}

.custom-popup .btnContainer .button:disabled > img {
  opacity:                    50%;
}

.custom-popup .btnContainer .button.primary:disabled::after {
  background:                 rgba(0, 0, 0, 0.3);
}

.custom-popup .btnContainer .button:hover:not(:disabled) {
  background-color:           var(--darkBlue);
}

.custom-popup .btnContainer .button:hover:not(:disabled) > span {
  background:                 var(--gradient);
  background-clip:            text;
  -webkit-background-clip:    text;
  -webkit-text-fill-color:    transparent;
}

.select-border-modal .modal-content{
  border:                     unset;
  border-radius:              0;
  min-height:                 175px;
  filter:                     drop-shadow(0px 15px 50px rgba(35, 107, 216, 0.25));
}

.select-border-modal .modal-body {
  display:                    flex;
  flex-direction:             column;
}

.select-border-modal .modal-body .select-border-section {
  display:                    flex;
  flex-direction:             column;
  padding:                    10px;
  flex:                       1 0 0;
}

.select-border-modal .modal-body .select-border-section > h4{
  margin-bottom:              10px;
}

.select-border-modal .modal-body .select-border-section .input-citySearch-container {
  position:                   relative;
}

.select-border-modal .modal-body .select-border-section .input-citySearch-container > input{
  padding:                    10px 15px;
  background-color:           rgba(4, 26, 57, 0.05);
}

.select-border-modal .modal-body .select-border-section .input-citySearch-container .cities-dropdown {
  background:                 rgba(255, 255, 255, 0.90);
  box-shadow:                 0 15px 50px 0 rgba(4, 26, 57, 0.15);
  position:                   absolute;
  top:                        100%;
  width:                      100%;
  z-index:                    999;
  visibility:                 hidden;
  opacity:                    0;
  transition:                 all 0.3s ease-in-out;
  max-height:                 0;
  overflow:                   hidden;
}

.select-border-modal .modal-body .select-border-section .input-citySearch-container .cities-dropdown.show {
  visibility:                 visible;
  opacity:                    1;
  max-height:                 1000px;
}

.select-border-modal .modal-body .select-border-section .input-citySearch-container .cities-dropdown .cityFound {
  padding: 							      10px 35px;
  cursor: 							      pointer;
}


.select-border-modal .modal-body .select-border-section .input-citySearch-container .cities-dropdown .cityFound:hover{
  background: 						    var(--gradient), rgba(4, 26, 57, 0.10);
  background-clip: 					  text, padding-box;
  -webkit-background-clip: 		text, padding-box;
  -webkit-text-fill-color: 		transparent;
}

.select-border-modal .modal-body .select-border-section > button{
  display:                    flex;
  align-self:                 flex-end;
  margin-top:                 30px;
  border:                     unset;
  padding:                    10px 15px;
  background:                 var(--gradient), var(--blue);
  background-clip:            text, padding-box;
  -webkit-background-clip:    text, padding-box;
  -webkit-text-fill-color:    transparent;
  transition:                 all 0.3s ease-in-out;
}

.select-border-modal .modal-body .select-border-section > button:hover{
  background-color:           var(--darkBlue);
}

@media screen and (width <= 991px) {
  .custom-popup {
    position:                 fixed;
    top:                      60%;
    right:                    50%;
    transform:                translate(50%, -40%);
  }
}