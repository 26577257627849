.homeNavbar {
    background-color:                   var(--grey);
    width:                              100%;
    position:                           relative;
}

.homeNavbar .navbar-list {
    display:                            flex;
    flex-direction:                     row;
    justify-content:                    space-between;
    align-items:                        center;
    padding:                            20px;
}

.homeNavbar .navbar-list .navbar-logo {
    height:                             50px;
    min-width:                          200px;
    object-fit: 					    contain;
	object-position: 				    center center;
}

.homeNavbar .navbar-list > li {
    list-style:                         none;
}

.homeNavbar .navbar-list .profile-search-container {
    display:                            flex;
    flex-direction:                     row;
    align-items:                        center;
    gap:                                15px;
}

.homeNavbar .navbar-list .profile-navbar,
.profileOptions-navbar .img-profile-navbar {
    width: 							    60px;
	height: 						    60px;
	object-fit: 					    cover;
	object-position: 				    center center;
	border-radius: 					    100px;
	border: 						    5px solid transparent;
	background: 					    var(--gradient) no-repeat center center;
	background-size: 				    60px 60px;
	background-clip: 				    border-box;
}

.homeNavbar .navbar-list .search-navbar {
    content: 							"";
	width: 							    44px;
	height: 						    44px;
	background: 						url("../../images/search.svg") center center no-repeat;
    background-color:                   white;
	background-size: 					25px;
	margin:  							auto 0;
    padding:                            10px;
    border:                             none;
	cursor: 							pointer;
    opacity:                            1;
    visibility:                         visible;
    transition:                         all 0.3s ease-in-out;
}

.homeNavbar .navbar-list .search-navbar.hide {
    opacity:                            0;
    visibility:                         hidden;
}

.profileOptions-navbar .modal-dialog {
    margin:                             unset;
    max-width:                          unset;
}

.profileOptions-navbar .modal-content {
    border:                             unset;
    border-radius:                      unset;
}

.profileOptions-navbar .modal-content::before {
    content:                            "";
    position:                           absolute;
    inset:                              -5px; /* control the spread */
    transform:                          translate(-10px, 0px); /* control the offsets */
    z-index:                            -1; /* place the element behind */
    background:                         var(--gradient);
    filter:                             blur(10px); 
    opacity:                            0.3;
}

.profileOptions-navbar .modal-dialog-centered {
    align-items:                        unset;
    min-height:                         35%;
}

.profileOptions-navbar .modal-body {
    gap:                                15px;
}

.profileOptions-navbar {
    width: 							    100vw;
    position: 						    absolute;
    top: 							    0;
    left: 							    0;
    right: 							    0;
}

.profileOptions-navbar .modal-body {
    display:                            flex;
    flex-direction:                     column;
    align-items:                        center;
    padding:                            25px;
}

.profileOptions-navbar .img-profile-navbar {
    height:                             140px;
    width:                              140px;
    background-size:                    140px 140px;
}

.profileOptions-navbar .profile-actions-navbar {
    display:                            flex;
    flex-direction:                     column;
    align-items:                        center;
    margin-top:                         10px;
    gap:                                25px;
}

/* navbar search component styles */
.homeNavbar .navbarSearch {
    position:                           fixed;
    display:                            flex;
    z-index:                            1;
    flex-direction:                     column;
    background-color:                   var(--grey);
    top:                                120px;
    width:                              100%;
    height:                             0;
    padding:                            20px 40px;
    gap:                                20px;
    opacity:                            0;
    visibility:                         hidden;
    transition:                         all .5s ease-out;
    overflow-y:                         hidden;
}

.homeNavbar .navbarSearch.show {
    opacity:                            1;
    height:                             100%;
    visibility:                         visible;
}

.homeNavbar .navbarSearch .navbarSearch-header {
    display:                            flex;
    flex-direction:                     row;
    justify-content:                    space-between;
    align-items:                        center;
}

.homeNavbar .navbarSearch .navbarSearch-header .navbarSearch-title {
    display:                            flex;
    font-weight:                        700;
    align-items:                        center;
}

.homeNavbar .navbarSearch .navbarSearch-header .navbarSearch-title::before {
    content:                            "";
    display:                            block;
    width:                              25px;
    height:                             25px;
    background:                         url("../../images/arrowDown.svg") center center no-repeat;
    rotate:                             90deg;
    background-size:                    25px;
    margin-right:                       10px;
}

.homeNavbar .navbarSearch .navbarSearch-header .primaryButton {
    display:                            inline-flex;
    border:                             unset;
    padding:                            10px;
    justify-content:                    center;
    align-items:                        center;
    background:                         var(--blue);
    gap:                                5px;
}

.homeNavbar .navbarSearch .navbarSearch-header .primaryButton:focus-visible {
    outline:                            unset;
}

.homeNavbar .navbarSearch .navbarSearch-header .primaryButton > span {
    font-weight:                        400;
    background:                         var(--gradient);
    background-clip:                    text;
    -webkit-background-clip:            text;
    -webkit-text-fill-color:            transparent;
}

.homeNavbar .navbarSearch .navbarSearch-input-container::after {
    content:                            "";
    display:                            block;
    width:                              25px;
    height:                             25px;
    background:                         url("../../images/search.svg") center center no-repeat;
    background-size:                    25px;
    position:                           absolute;
    top:                                50%;
    transform:                          translateY(-50%);
    right:                              25px;
}

.homeNavbar .navbarSearch .navbar-clients-section {
    display:                            flex;
    flex-direction:                     column;
    gap:                                20px;
    overflow-y:                         auto;
}

.homeNavbar .navbarSearch .navbar-clients-section .navbarSearch-client {
    display:                            flex;
    flex-direction:                     row;
    justify-content:                    space-between;
    padding:                            20px 0;
    border-bottom:                      1px solid rgba(0, 0, 0, .05);
}

.homeNavbar .navbarSearch .navbar-clients-section .navbarSearch-client .navbarSearch-client-info {
    display:                            flex;
    flex-direction:                     column;
    gap:                                5px;
    align-items:                        flex-end;
    opacity:                            50%;
}

.homeNavbar .navbarSearch .navbar-clients-section .navbarSearch-client .navbarSearch-client-title {
    font-weight:                        700;
}
